import { RouteConfig } from 'vue-router'
import { checkAuthAndRedirect } from "../api/auth-redirect";
import store from '../store/index';

const routes : Array<RouteConfig> = [
  {
    path: '/application/:id/:subview?',
    name: 'Application',
    component: () => import(/* webpackChunkName: "application" */'@/views/Application/Application.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    children: [
      {
        name: 'Application Qualifying Questions',
        path: 'qualifying-questions',
        component: () => import(/* webpackChunkName: "application-group-details" */'@/views/Application/QualifyingQuestions.vue'),
      },
      {
        name: 'Application Group Details',
        path: 'group-details',
        component: () => import(/* webpackChunkName: "application-group-details" */'@/views/Application/GroupDetails.vue'),
      },
      {
        name: 'Application Policyholder',
        path: 'policyholder',
        component: () => import(/* webpackChunkName: "application-policyholder" */'@/views/Application/Policyholder.vue'),
      },
      {
        name: 'Application Policyholder Family',
        path: 'policyholder-family',
        component: () => import(/* webpackChunkName: "application-policyholder-family" */'@/views/Application/PolicyholderFamily.vue'),
      },
      {
        name: 'Application Employees Corporate',
        path: 'employees-corporate',
        component: () => import(/* webpackChunkName: "application-employees-corporate" */'@/views/Application/EmployeesCorporate.vue'),
        children: [
          {
            path: 'import-employees',
            name: "CorporateImportEmployees",
            components: {
              corporateimport: () => import('@/views/EmployeesImport/CorporateImportMembers.vue')
            }
          },
          {
            path: 'prepare-group-data',
            name: "CorporatePrepareGroupData",
            components: {
              corporateimportprepare: () => import('@/views/EmployeesImport/CorporatePrepareGroupDataFromExcel.vue')
            },
            props: true
          },
          {
            path: 'import-matching',
            name: "CorporateImportMatching",
            components: {
              corporateimportmatching: () => import('@/views/EmployeesImport/CorporateImportMatching.vue')
            }
          },
          {
            path: 'import-validation',
            name: "CorporateImportValidation",
            components: {
              corporateimportvalidation: () => import('@/views/EmployeesImport/CorporateImportValidation.vue')
            }
          }
        ]
      },
      {
        name: 'Application Review',
        path: 'review',
        component: () => import(/* webpackChunkName: "application-cover" */'@/views/Application/Review.vue'),
      },
      {
        name: 'Application Review Corporate',
        path: 'review-corporate',
        component: () => import(/* webpackChunkName: "application-cover-corporate" */'@/views/Application/ReviewCorporate.vue'),
      },
      {
        name: 'Application Review Multifamily',
        path: 'review-multifamily',
        component: () => import(/* webpackChunkName: "application-cover-multifamily" */'@/views/Application/ReviewMultifamily.vue'),
      },
      {
        name: 'Application Review Family-Office',
        path: 'review-familyoffice',
        component: () => import(/* webpackChunkName: "application-cover-multifamily" */'@/views/Application/ReviewMultifamily.vue'),
      },
      {
        name: 'Application Declaration',
        path: 'declaration',
        component: () => import(/* webpackChunkName: "application-declaration" */'@/views/Application/Declaration.vue'),
      },
      {
        name: 'Application Payment',
        path: 'payment',
        component: () => import(/* webpackChunkName: "application-payment" */'@/views/Application/Payment.vue'),
      },
      {
        name: 'Application Submit',
        path: 'submit',
        component: () => import(/* webpackChunkName: "application-submit" */'@/views/Application/Submit.vue'),
      },
      {
        name: 'Application Households',
        path: 'households',
        component: () => import(/* webpackChunkName: "application-households" */'@/views/Application/Households.vue'),
      },
      {
        name: 'Application Members',
        path: 'members',
        component: () => import(/* webpackChunkName: "application-members" */'@/views/Application/Members.vue'),
      },
      {
        name: 'Application Underwriting',
        path: 'underwriting',
        component: () => import(/* webpackChunkName: "application-underwriting" */'@/views/Application/Underwriting.vue'),
      },
      {
        name: 'Application Medical Declaration',
        path: 'medical-declaration/:joiningMethod',
        component: () => import(/* webpackChunkName: "application-medical-declaration" */'@/views/Application/MedicalDeclaration.vue'),
      },
      {
        name: 'Application Underwriting Declaration',
        path: 'underwriting-declaration',
        component: () => import(/* webpackChunkName: "application-medical-declaration" */'@/views/Application/UwDeclaration.vue'),
      }
    ]
  }
]

export default routes
