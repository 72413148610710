<template>
  <div v-show="show" class="loader">
    <img :src="loaderIcon" />
    <span class="ml-2">Loading...</span>
  </div>
</template>

<script>
import loaderIcon from '@/assets/loader.svg'
export default {
  name: "UILoader",
  data () {
    return {
      loaderIcon
    }
  },
  computed: {
    show() {
      return !!this.$store.state.pendingRequest
    }
  },
}
</script>
<style lang="scss">
@import '@/theme/variable.scss';

  .loader {
    font-size: 18px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f6f6da;
  }
</style>
