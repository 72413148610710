export default {
  state: {
    toasts: []
  },
  mutations: {
    addToast(state, toast) {
      state.toasts.push(toast)
    },
    removeToast(state, toast) {
      const idx = state.toasts.indexOf(toast);
      if (idx != -1) {
        state.toasts.splice(idx, 1);
      }
    }
  },
  actions: {
    showToastMessage({ commit }, message) {
      const toast = { message }
      commit('addToast', toast);
      setTimeout(() => commit('removeToast', toast), 5000);
    }
  }
  
}