import 'whatwg-fetch'
import { parseJwt, isTokenExpired, logout, refreshTokenExpirationOffsetMinutes, getRefreshTokenExpirationDate } from '../utils/auth';
import { default as _api } from '@/api'
import store from '../store/index';
import _ from 'lodash';

let api;
let apiUrl = '';
let accessToken;
let refreshToken;
let isTokenRefreshing = false;
const logoutTimeout = 20 * 60000; // 20 min
const logoutWarningTimeout = 19 * 60000; // 19 min

const defaultHeaders = {
  'Content-Type': 'application/json',
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function getConfiguration() {
  let configuration = store.getters.config;

  while (!configuration) {
    await sleep(200);
    configuration = store.getters.config;
  }

  return configuration;
}


export const logoutAndClear = async (redirect = true) => {
  const configuration = await getConfiguration();

  if(configuration.env == "DEV")
  {
    redirect = false;
  }

  logout(configuration.publicWebsites.main, redirect);
  accessToken = null;
  refreshToken = null;
}

const displayLogoutWarning = () => {
  store.commit("SET_SHOW_LOGOUT_WARNING", true);
};

export const postponeLogout = _.debounce(logoutAndClear, logoutTimeout);
export const logoutWarning = _.debounce(displayLogoutWarning, logoutWarningTimeout);

(() => {
  document.addEventListener('keydown', async (event) => {
    if (!store.state.showLogoutWarning) {
      logoutWarning();
      postponeLogout();
    }
  });

  document.addEventListener('click', async (event) => {
    if (!store.state.showLogoutWarning) {
      logoutWarning();
      postponeLogout();
    }
  });
})()


const setRefreshTokenTimeout = () => {
  const refreshTokenTime = getRefreshTokenExpirationDate();
  const current = new Date().getTime();
  if (refreshTokenTime && refreshTokenTime.getTime() > current) {
    setTimeout(getNewRefreshToken, refreshTokenTime.getTime() - current)
  } else {
    setTimeout(getNewRefreshToken, refreshTokenExpirationOffsetMinutes * 60 * 1000);
  }
}


const getNewRefreshToken = () => {
  refreshToken = localStorage.getItem("REFRESH_TOKEN");
  if (!refreshToken || !accessToken) {
    store.commit("SET_IS_AUTHENTICATED", false);
    logoutAndClear();
    console.error('refreshToken or accessToken missing, logging out');
    return;
  }

  const url = `${apiUrl}api/authorization/getNewRefreshToken`;
  return checkAndRefreshToken()
    .then(() => 
      fetch(url,{
        method: "POST",
        headers: {
          ...defaultHeaders,
          refreshToken,
          accessToken
        }
      }))
    .then(checkStatus)
    .then(async (response) => {
      const token = await response.text();
      const date = new Date();
      date.setMinutes(date.getMinutes() + refreshTokenExpirationOffsetMinutes);
      localStorage.setItem("REFRESH_TOKEN", token);
      localStorage.setItem('REFRESH_TOKEN_EXPIRATION', date.getTime().toString());
      refreshToken = token;
      setRefreshTokenTimeout();
    })
    .catch(async (error) => {
      await store.commit("SET_IS_AUTHENTICATED", false);

      await logoutAndClear();
      console.error('get new refresh token failed', error);
    });
};

export const refreshAccessToken = async () => {
  const url = `${apiUrl}api/authorization/getAccessToken`;
  return fetch(url, {
    headers: {
      ...defaultHeaders,
      refreshToken
    },
    method: 'POST'
  })
    .then(checkStatus)
    .then(async (response) => {
      const token = await response.text();

      localStorage.setItem("ACCESS_TOKEN", token);
      accessToken = token;
      await store.commit("SET_IS_AUTHENTICATED", true);
    })
    .catch(async (error) => {
      await logoutAndClear(false);
      store.commit("SET_IS_AUTHENTICATED", false);
    });
};

const checkAndRefreshToken = async () => {
  if (refreshToken) {
    let expired = false;
    accessToken = localStorage.getItem("ACCESS_TOKEN");

    if (accessToken) {
      const decodedToken = parseJwt(accessToken);
      expired = isTokenExpired(decodedToken);
    }

    if (!isTokenRefreshing) {
      if (!accessToken || expired) {
        isTokenRefreshing = true;
        await refreshAccessToken();
        isTokenRefreshing = false;
      }
    }
    else {
      await wait();
    }
  }
};

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  else if (response.status === 401) {
    const error = new Error(response.statusText);
    error.message = "Unauthorized";
    throw error;
  } else {
    const error = new Error(response.statusText);
    error.message = response;
    throw error;
  }
};

const getHeaders = (headers, body?, url?) => {
  let requestHeaders = {
    ...headers
  };

  if (!(body && body instanceof FormData)) {
    requestHeaders = {
      ...requestHeaders,
      'Content-Type': 'application/json'
    };
  }

  if (accessToken) {
    requestHeaders = {
      ...requestHeaders,
      'Authorization': `Bearer ${accessToken}`
    };
  }

  if (url === `${apiUrl}api/authorization/getAccessToken`) {
    requestHeaders = {
      ...requestHeaders,
      refreshToken: localStorage.getItem("REFRESH_TOKEN")
    };
  }

  return requestHeaders;
};

const wait = async () => {
  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  while (isTokenRefreshing) {
    await sleep(500);
  }
};

const beforeRequest = async (url) => {
  if (!api || !apiUrl) {
    api = await _api;
    apiUrl = api.apiSpec.API;
  }

  accessToken = localStorage.getItem("ACCESS_TOKEN");
  refreshToken = localStorage.getItem('REFRESH_TOKEN');

  if (!isTokenRefreshing) {
    if (url !== `${apiUrl}api/authorization/getAccessToken`) {
      await checkAndRefreshToken();
    }
    else {
      isTokenRefreshing = true;
    }
    if(accessToken) {
      logoutWarning();
      postponeLogout();
    }
  }
  else {
    await wait();
  }
};

const post = async (
  url,
  body?: Blob | BufferSource | FormData | URLSearchParams | ReadableStream<Uint8Array> | string,
  headers?: object | null
) => {
  await beforeRequest(url);

  const request = fetch(url, {
    headers: {
      ...getHeaders(headers, body, url)
    },
    method: 'POST',
    body: body || JSON.stringify({})
  });

  return request
    .then(checkStatus)
    .then(async response => {
      if (url === `${apiUrl}api/authorization/getAccessToken`) {
        const clonedResponse = response.clone();
        localStorage.setItem("ACCESS_TOKEN", await clonedResponse.text());
        await store.commit("SET_IS_AUTHENTICATED", true);
        postponeLogout();
        logoutWarning();
      }

      return response;
    })
    .catch(async (error) => {
      if (url === `${apiUrl}api/authorization/getAccessToken`) {
        await store.commit("SET_IS_AUTHENTICATED", false);
        await logoutAndClear();
      }
      else {
        console.error('request failed', error);
      }
      throw error;
    })
    .finally(() => {
      if (url === `${apiUrl}api/authorization/getAccessToken`) {
        isTokenRefreshing = false;
      }
    });
};

const get = async (
  url,
  headers?: object | null
) => {
  await beforeRequest(url);

  const request = fetch(url, {
    headers: {
      ...getHeaders(headers)
    },
    method: 'GET'
  });

  return request
    .then(checkStatus)
    .catch((error) => {
      console.error('request failed', error);
      throw error;
    });
};

const patch = async (
  url,
  body?: Blob | BufferSource | FormData | URLSearchParams | ReadableStream<Uint8Array> | string,
  headers?: object | null
) => {
  await beforeRequest(url);

  const request = fetch(url, {
    headers: {
      ...getHeaders(headers),
      'Content-Type': 'application/json-patch+json'
    },
    method: 'PATCH',
    body: body || JSON.stringify({})
  });

  return request
    .then(checkStatus)
    .catch((error) => {
      console.error('request failed', error);
    });
};

const put = async (
  url,
  body?: Blob | BufferSource | FormData | URLSearchParams | ReadableStream<Uint8Array> | string,
  headers?: object | null
) => {
  await beforeRequest(url);

  const request = fetch(url, {
    headers: {
      ...getHeaders(headers, body)
    },
    method: 'PUT',
    body: body || JSON.stringify({})
  });

  return request
    .then(checkStatus)
    .catch((error) => {
      console.error('request failed', error);
    });
};

const del = async (
  url,
  body?: Blob | BufferSource | FormData | URLSearchParams | ReadableStream<Uint8Array> | string,
  headers?: object | null
) => {
  await beforeRequest(url);

  const request = fetch(url, {
    headers: {
      ...getHeaders(headers, body)
    },
    method: 'DELETE',
    body: body || JSON.stringify({})
  });

  return request
    .then(checkStatus)
    .catch((error) => {
      console.error('request failed', error);
    });
};

export const isAuthenticated = async () => {
  if (!api || !apiUrl) {
    api = await _api;
    apiUrl = api.apiSpec.API;
  }

  refreshToken = localStorage.getItem('REFRESH_TOKEN');
  accessToken = localStorage.getItem('ACCESS_TOKEN');

  if(refreshToken && accessToken) {
    return await post(`${apiUrl}api/authorization/getAccessToken`, JSON.stringify(refreshToken))
      .then(r => true)
      .catch(e => false);
  }

  return false;
}

const apiCall = {
  post,
  get,
  patch,
  put,
  delete: del
};

setRefreshTokenTimeout();

export default apiCall;
