<template>
  <div class="button-dropdown">
    <UIButton
      @click="toggle()"
      text="New"
      :type="['green', 'narrow']"
      :icon="icons.addIcon" />

    <div v-show="showDropdown" class="button-dropdown__actions">
      <div
        v-for="action in actions"
        @click="onClick(action.type)"
        :key="action.type"
        class="button-dropdown__action">
        <img :src="action.icon" />
        <div class="button-dropdown__title">{{ action.title }}</div>
      </div>
    </div>

  </div>
</template>

<script>
import icons from "@/utils/icons";
import UIButton from '../../../../commonReausables/UIComponents/UIButton'

export default {
  name: "DropdownButton",
  components: { UIButton },
  data () {
    return {
      icons,
      showDropdown: false
    }
  },
  props: {
    actions: {
      type: Array,
      required: true
    }
  },
  methods: {
    toggle() {
      this.showDropdown = !this.showDropdown;
    },
    close (e) {
      if (!this.$el.contains(e.target)) {
        this.showDropdown = false;
      }
    },
    onClick(actionType) {
      this.$emit('on-click', actionType);
      this.showDropdown = false;
    }
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click',this.close);
  }
}
</script>

<style lang="scss">
@import '../../../../commonReausables/theme/variable.scss';

.button-dropdown {
  position: relative;

  &__actions {
    box-shadow: 0px 0px 5px 0px rgba(110, 110, 110, 0.3);
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: $color-navbar;
    min-width: 160px;
    border-radius: 2px;
    margin-top: 6px;
  }

  &__action {
    display: flex;
    padding: 15px 20px;
    cursor: pointer;

    &:hover {
      background-color: $color-grey-2;
    }
  }

  &__title {
    font-size: 14px;
    color: $color-black;
    margin-left: 12px;
    font-weight: 500;
  }
}

</style>
