<template>
  <div class="header d-flex align-items-center">
    <router-link to="/"
      ><img class="header__logo" alt="WPA" :src="icons.wpaLogo"
    /></router-link>
    <a
      @click.prevent="() => redirectToSecureAreaHomepage()"
      v-if="showiZoneButton"
      class="btn-izone color-primary-blue"
      >Return to iZone</a
    >
    <Searchbar />
    <DropdownButton
      v-if="!$isBroker"
      @on-click="onAction($event)"
      :actions="dropdownButtonActions"
    />
    <div class="toasts" v-if="toasts.length">
      <div class="toast-msg" v-for="(toast, i) in toasts" :key="i">
        {{ toast.message }}
      </div>
    </div>
  </div>
</template>

<script>
import Searchbar from "@/components/HeaderComponents/Searchbar";
import DropdownButton from "@/components/HeaderComponents/DropdownButton";
import icons from "../utils/icons";
import { EventBus } from "@/utils/eventBus";
import loggedUserMethods from "@/mixins/loggedUserMethods";

export default {
  name: "Header",
  mixins: [loggedUserMethods],
  components: {
    DropdownButton,
    Searchbar,
  },
  data() {
    return { icons };
  },
  computed: {
    dropdownButtonActions() {
      if (this.$isBroker)
        return [
          {
            title: "Lead",
            type: "lead",
            icon: icons.smallFunnel,
          },
        ];
      return [
        {
          title: "Lead",
          type: "lead",
          icon: icons.smallFunnel,
        },
        {
          title: "Task",
          type: "task",
          icon: icons.smallTodo,
        },
        {
          title: "Note",
          type: "note",
          icon: icons.smallNote,
        },
      ];
    },
    toasts() {
      return this.$store.state.Toast.toasts;
    },
    customerId() {
      return this.$store.state.Customer.currentCustomer?.customerId;
    },
    leadId() {
      return this.$store.state.Lead.currentLead?.leadId;
    },
    taskModalProps() {
      try {
        const id = parseInt(this.$route.params.id); // customer or lead id
        if (id === this.customerId) {
          return {
            customerId: this.customerId,
            leadId: null,
          };
        } else if (id === this.leadId) {
          return {
            leadId: this.leadId,
            customerId: null,
          };
        }
        return null;
      } catch (error) {
        return null;
      }
    },
    noteModalProps() {
      try {
        const id = parseInt(this.$route.params.id); // customer or lead id
        const isNotesView = this.$route.path?.split("/").pop() === "notes";

        if (id === this.customerId) {
          return {
            customerId: this.customerId,
            leadId: null,
            reloadNoteData: isNotesView,
          };
        } else if (id === this.leadId) {
          return {
            leadId: this.leadId,
            customerId: null,
            reloadNoteData: isNotesView,
          };
        }
        return null;
      } catch (error) {
        return null;
      }
    },
    showiZoneButton() {
      if (this.$isBroker) {
        return this.$route.name === "Leads";
      }
    },
  },
  methods: {
    newNote() {
      EventBus.$emit("modal:open", {
        componentName: "NOTE_CREATOR",
        modalTitle: "New note",
        props: this.noteModalProps,
      });
    },
    newTask() {
      EventBus.$emit("modal:open", {
        componentName: "TASK_CREATOR",
        modalTitle: "New task",
        props: this.taskModalProps,
      });
    },
    newLead() {
      this.$router.push({ name: "LeadsNew" });
    },
    onAction(type) {
      switch (type) {
        case "lead":
          this.newLead();
          break;
        case "note":
          this.newNote();
          break;
        case "task":
          this.newTask();
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../commonReausables/theme/variable";

.header {
  padding: 6px 20px;
  position: fixed;
  z-index: 5;
  top: 0;
  background-color: $color-white-absolute;
  width: 100%;
  border-bottom: 1px solid #d2d3d6;
  &__logo {
    height: 44px;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    border: 1px solid transparent;
    &:hover {
      border-color: $color-white-absolute;
    }
  }
}
.toasts {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 600px;
  .toast-msg {
    background-color: $color-green-1;
    margin: 10px;
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 8px;
    color: white;
    font-weight: 600;
  }
}
.btn-izone {
  margin: 0 40px 0 60px;
  cursor: pointer;
}
</style>
