export const state = {
  notes: [ ]
}
export const getters = {
  notes: state => state.notes,
}
export const mutations = {
  addCommentToNote (state, {noteId, comment}) {
    state.notes.find(note => {
      return note.noteId === noteId
    }).comments.push(comment)
  },
  createNote (state, payload) {
    state.notes.unshift({
      ...payload
    })
  },
  removeNote (state, noteId) {
    state.notes = state.notes.filter(note => note.noteId !== noteId)
  },
  updateNote (state, payload) {
    const noteToEdit = state.notes.find(note => note.noteId === payload.id);

    if (noteToEdit) {
      noteToEdit.content = payload.content;
    }
  },
  setNotes(state, notes) {
    state.notes = notes;
  }
}
export const actions = {}

export const Notes = {
  state,
  getters,
  actions,
  mutations
}
