import dashboardApiPromise from '@/api/dashboard-api'

export const state = {}
export const getters = {}
export const mutations = {}
export const actions = {
  fetchGlobalSearchResults ({ commit, dispatch }, params) {
    return dashboardApiPromise.then(api => {
      return api.apis.GlobalSearch.fetchGlobalSearchResults({
        searchText: params.searchText,
        pageNum: params.pageNum || 1,
        pageSize: params.pageSize || 7
      })
    })
  },
  fetchGlobalSearchResultsDetails ({ commit, dispatch }, params) {
    return dashboardApiPromise.then(api => {
      return api.apis.GlobalSearch.fetchGlobalSearchWithDetails({
        searchText: params.searchText,
        pageNum: params.pageNum || 1,
        pageSize: params.pageSize || 25
      })
    })
  },
}

export const GlobalSearch = {
  state,
  getters,
  mutations,
  actions
}
