import { default as _api } from '@/api'
import isBroker from "@/utils/isBroker"

export const checkAuthAndRedirect = async (returnUrl, store) => {
  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  const refreshToken = localStorage.getItem("REFRESH_TOKEN");

  if (refreshToken) {
    if (!accessToken) {
      // refresh access token and and push to return url
      await api
        .accessToken(refreshToken)
        .then(async (res) => {
          store.commit("SET_IS_AUTHENTICATED", true);
          localStorage.setItem("ACCESS_TOKEN", res);
        })
        .catch((err) => {
          store.commit("SET_IS_AUTHENTICATED", false);
          redirectToLogin(returnUrl, store);
        });

      }
      return returnUrl;
  }
  else {
    // redirect to login
    await redirectToLogin(returnUrl, store);
    return null;
  }
}

export const buildReturnUrl = (config, returnUrl) => {

  const redirectUrl = config.redirectEndpoint;
  const loginUrl = config.authUrl;
  const channel = isBroker ? config.authChannelBroker : config.authChannel;
  const env = config.authEnv;
  let encodedReturnUrl;
  let url;

  if (returnUrl) {
    encodedReturnUrl = encodeURIComponent(`/callback?return_url=${returnUrl}`);
  }
  else {
    encodedReturnUrl = encodeURIComponent(`/callback`);
  }

  if (env) {
    url = `${loginUrl}${redirectUrl}?return_url=${encodedReturnUrl}&channel=${channel}&env=${env}`;
  }
  else {
    url = `${loginUrl}${redirectUrl}?return_url=${encodedReturnUrl}&channel=${channel}`;
  }

  return url;
}

const redirectToLogin = async (returnUrl, store) => {
  let configuration = store.getters.config;

    while (!configuration) {
      await sleep(200);
      configuration = store.getters.config;
    }
   
    localStorage.setItem("TMP_STORE", JSON.stringify(store.state));

    let url = buildReturnUrl(configuration, returnUrl);
    document.location.href = url;
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
