import moment from "moment";

const timeCounter = {
  data () {
    return {
      hours: [
        '06:30',
        '07:00',
        '07:30',
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        '20:30',
        '21:00',
        '21:30',
        '22:00',
        '22:30',
        '23:00',
        '23:30',
        '00:00',
      ]
    }
  },
  methods: {
    showDdMmYy (dateItem) {
      return moment(dateItem).format('DD/MM/YYYY')
    },
    showFull (dateItem) {
      return moment(dateItem).format('DD/MM/YYYY, HH:mm')
    },
    showDate (dateItem) {
      return moment(dateItem).format('D MMM YYYY')
    },
    showHour (dateItem) {
      return moment(dateItem).format('HH:mm')
    },
    showMonth (dateItem) {
      return moment(dateItem).format('MMMM YYYY')
    },
    showDayOfMonth(dateItem) {
      return moment(dateItem).format('dddd Do')
    },
    showStandard (dateItem) {
      return moment(dateItem).format()
    },
    showDateInFormat (dateItem, format) {
      return moment(dateItem).format(format);
    },
  }
}

export default timeCounter
