export default [
  {
    label: 'Husband',
    value: 0
  },
  {
    label: 'Wife',
    value: 1
  },
  {
    label: 'Partner',
    value: 2
  },
  {
    label: 'Son',
    value: 3
  },
  {
    label: 'Daughter',
    value: 4
  },
  {
    label: 'Child dependant',
    value: 5
  },
  {
    label: 'Adult dependant',
    value: 6
  }
]
export const adultRelationships = [
  {
    label: 'Husband',
    value: 0
  },
  {
    label: 'Wife',
    value: 1
  },
  {
    label: 'Partner',
    value: 2
  },
  {
    label: 'Adult dependant',
    value: 6
  }
];

export const childRelationships = [
  {
    label: 'Son',
    value: 3
  },
  {
    label: 'Daughter',
    value: 4
  },
  {
    label: 'Child dependant',
    value: 5
  }
];


export const isAdultRelationship = (value) => {
  return value == relationship.HUSBAND || value == relationship.WIFE || value == relationship.PARTNER || value == relationship.ADULT_DEPENDANT;
}

export const isChildRelationship = (value) => {
  return value == relationship.SON || value == relationship.DAUGHTER || value == relationship.CHILD_DEPENDANT;
}

export const isSubscriberRelationship = (value) => {
  return value == relationship.SUBSCRIBER;
}
export const getDefaultName = (relationship, age) => {
  if (isSubscriberRelationship(relationship)) return "Subscriber";
  if (isAdultRelationship(relationship)) return "Adult dependant";
  if (isChildRelationship(relationship)) return "Child dependant";
  return age >= 18 ? "Adult dependant" : "Child dependant";
}

export const relationship = {
  HUSBAND: 0,
  WIFE: 1,
  PARTNER: 2,
  SON: 3,
  DAUGHTER: 4,
  CHILD_DEPENDANT: 5,
  ADULT_DEPENDANT: 6,
  SUBSCRIBER: 7,
  GROUP: 8
}

const defaultTitlesMap = {
  0: 'Mr',
  1: 'Mrs',
  3: 'Master',
  4: 'Miss'
}

export const autoPopulatePolicies = [
  {
    relationships: [relationship.HUSBAND, relationship.WIFE, relationship.SON, relationship.DAUGHTER],
    surname: null,
    getTitle: (rel) => defaultTitlesMap[rel]
  },
  {
    relationships: [relationship.ADULT_DEPENDANT, relationship.PARTNER],
    surname: 'Adult Dependant',
    getTitle: () => null
  },
  {
    relationships: [relationship.CHILD_DEPENDANT],
    surname: 'Child Dependant',
    getTitle: () => null
  },
]

export const getAutoPopulatePolicy = (rel) => {
  return autoPopulatePolicies.find(x => x.relationships.some(r => r === rel));
}

export const importRelationships = [
  {value: "HU", id: 0},
  {value: "WI", id: 1},
  // id 2 is partner, but there's no partner relationship in import
  {value: "SO", id: 3},
  {value: "DA", id: 4},
  {value: "CD", id: 5},
  {value: "AD", id: 6},
  {value: "SU", id: 7}
];

export const getRelationshipIdByAbbreviation = (relAbbreviation) => {
  const relationship = importRelationships.find(x => x.value.toLocaleLowerCase() === relAbbreviation.toLocaleLowerCase());
  if (relationship) {
    return relationship.id;
  }

  return null;
}
