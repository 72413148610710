<template>
  <div class="modal__wrapper" :class="{ show: show }">
    <div class="modal__header">
      <div class="modal__header-wrapper d-flex align-items-center justify-content-between w-100">
        <div class="modal__title">{{ payload.modalTitle }}</div>
        <div class="modal__close" @click="close">
          <span>close</span>
          <img class="modal__icon" :src="icons.close" alt="close">
        </div>
      </div>
    </div>
    <div class="modal__body">
      <component :is="resolveComponent" v-bind="payload.props"></component>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/utils/eventBus";
import modalRegistry from '@/utils/modalRegistry';
import icons from "@/utils/icons";

export default {
  name: "UIModal",
  data () {
    return {
      icons,
      modalRegistry,
      payload: {},
      show: false
    }
  },
  computed: {
    resolveComponent () {
      return modalRegistry[this.payload.componentName]
    }
  },
  methods: {
    close () {
      EventBus.$emit('modal:close')
    }
  },
  created () {
    EventBus.$on('modal:open', (payload) => {
      this.show = true
      this.payload = payload
    })
    EventBus.$on('modal:close', () => {
      this.show = false
      this.payload = {}
      this.$store.commit('clearModalFromTask')
    })
  }
}
</script>
