import dashboardApiPromise from '@/api/dashboard-api'

export const state = {
  activities: [],
  literature: [],
  renewalFilters: {
    pageNumber: 1,
    pageSize: 10,
    startDate: null
  },
}

export const getters = {
  activities: state => state.activities,
  literature: state => state.literature,
  renewalFilters: state => state.renewalFilters
}

export const mutations = {
  SET_RENEWAL_FILTERS(state, filters) {
    state.renewalFilters = filters;
  },
}
export const actions = {
  setRenewalFilters({ commit }, filters) {
    commit("SET_RENEWAL_FILTERS", filters)
  },
  fetchActivities ({ commit, dispatch }, params) {
    return dashboardApiPromise.then(api => {
      return api.apis.Activities.fetch({
        MyContactsOnly: params.MyContactsOnly,
        pageNum: params.pageNum || 1,
        pageSize: params.pageSize || 7
      })
    })
  },
  fetchLeadsList ({ commit, dispatch }, payload) {
    return dashboardApiPromise.then(api => {
      return api.apis.Leads.fetchLeadsList({}, payload)
    })
  },
  getQuotesForSingleLead ({ commit, dispatch }, payload) {
    return dashboardApiPromise.then(api => {
      return api.apis.Leads.getSingleLeadQuotes({ leadId: payload }, {} )
    })
  },
}

export const Activity = {
  state,
  mutations,
  getters,
  actions
}
