<template>
  <div class="note__creator py-4">
    <div class="form-group" style="position:relative;" v-if="!leadId && !customerId">
      <label for="noteCustomer">Link a Lead/Customer</label>
      <SearchSelect @change="linkChange" />
    </div>
    <div class="form-group">
      <label for="noteTitle">Note title</label>
      <input type="text" class="form-control"
             id="noteTitle"
             v-model="note.title"
             placeholder="Give your note a title">
    </div>
    <div class="form-group">
      <label for="noteComment">Add comment</label>
      <textarea class="form-control"
                id="noteComment"
                v-model="note.comments[0].content"
                rows="3"
                placeholder="Please type your comments"></textarea>
    </div>
    <div class="d-flex justify-content-between mt-5">
      <UIButton :text="pendingRequest ? '' : 'Save'"
                :disabled="pendingRequest"
                @click="createNote()"
                :icon="pendingRequest ? icons.loader : null"
                :type="['primary', 'wide']" />
      <UIButton text="Cancel" @click="closeModal" :type="['secondary', 'wide']" />
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/utils/eventBus';
import api from "@/api/dashboard-api"
import icons from '@/utils/icons';
import idGenerator from "../../../utils/randomId";
import UIButton from '../../../../../commonReausables/UIComponents/UIButton';
import _ from "lodash";
import SearchSelect from "@/components/SearchSelect"

export default {
name: 'NoteCreator',
  components: { UIButton, SearchSelect },
  mixins: [idGenerator],
  props: {
    leadId: Number,
    customerId: Number,
    reloadNoteData: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      icons,
      leads: [],
      loader: false,
      note: {
        comments: [
          { content: '', dateTime: '', userId: '' },
        ],
        customer: {},
        modifiedDateTime: '',
        noteId: null,
        title: '',
      },
      pendingRequest: false
    }
  },
  watch: {
    $route(to, from) {
      if (from.params.id != to.params.id) { // close modal if route has changed
        this.closeModal();
      }
    }
  },
  created () {
    if (this.leadId) {
      const customer = {
        linkEntity: "lead_customer",
        linkId: this.leadId,
        linkName: ""
      };
      this.note.customer = customer;
    }
    else if (this.customerId) {
      const customer = {
        linkEntity: "customer",
        linkId: this.customerId,
        linkName: ""
      };
      this.note.customer = customer;
    }
  },
  methods: {
    closeModal () {
      EventBus.$emit('modal:close')
    },
    async createNote () {
      if (!this.pendingRequest) {
        this.pendingRequest = true;

        this.note.modifiedDateTime = Date.now()
        this.note.comments[0].dateTime = Date.now()
        let noteId = null;
        if(this.leadId || this.note.customer.linkEntity === 'lead_customer')
        {
          const leadId = this.note.customer.linkId;
          noteId = await (await api).apis.Notes.createLeadNote(leadId, { title: this.note.title, comment: this.note.comments[0].content });
        }
        else if(this.customerId || this.note.customer.linkEntity === 'customer')
        {
          const customerId = this.note.customer.linkId;
          noteId = await (await api).apis.Notes.createCustomerNote(customerId, { title: this.note.title, comment: this.note.comments[0].content });
        }
        if (noteId && this.reloadNoteData) {
          const { comments, ...note } = await (await api).apis.Notes.getNote(noteId);
          this.$store.commit('createNote', note)
        }
        
        this.pendingRequest = false;
        EventBus.$emit('modal:close')
      }
    },
    linkChange(value) {
      this.note.customer.linkId = value ? (value.leadId ? value.leadId : value.customerId) : null;
      this.note.customer.linkEntity = value ? (value.leadId ? "lead_customer" : "customer") : null;
    }
  },
}
</script>

<style lang="scss">
  .loader-icon {
    position: absolute;
    right: 10px;
    top: 40px;
    z-index: 99;
  }
</style>
