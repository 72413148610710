<template>
  <label class="searchbar d-flex p-relative">
    <span class="searchbar__icon">
      <img :src="icons.zoom" alt="zoom" />
    </span>
    <input
      class="searchbar__input"
      type="text"
      @input="updateResults(query)"
      v-model="query"
      @focus="(showResults = true), (active = true)"
      @blur="clearResults(1000), (active = false)"
      placeholder="Search by ID, Name, Company, Postcode..."
    />
    <img v-if="requestPending" :src="icons.loader" />
    <div class="searchbar__results-table" v-show="results.length > 0 && showResults">
      <div class="result d-flex justify-content-between p-2" @click="redirectToResultPage(result)" v-for="(result, index) in results" :key="index"
        :class="{
          'align-items-center': $isBroker,
          'align-items-end': $isHp
          }"
      >
        <div class="d-flex flex-column">
          <strong>{{ result.name }}</strong>
          <span class="font-size-14">{{ result.email }}</span>
        </div>
        <div class="d-flex flex-column w-25">
          <span class="font-size-12" v-if="!$isBroker">ID: {{ result.customerId ? result.customerId : result.leadId }}</span>
          <span class="font-size-12" v-if="!$isBroker">{{ result.customerId ? "Customer" : "Lead" }}</span>
          <span class="font-size-12" v-if="$isBroker && (result.customerId || result.leadId)">{{ result.postcode }}</span>
        </div>
      </div>
      <div class="result p-2" @click="redirectToExtendedSearchPage">show more...</div>
    </div>
    <div class="searchbar__results-table p-2" v-show="results.length === 0 && showError && active">No matching records</div>
  </label>
</template>

<script>
import icons from "@/utils/icons";
import _ from "lodash";
export default {
  name: "Searchbar",
  data() {
    return {
      icons,
      query: "",
      requestPending: false,
      results: [],
      showError: false,
      showResults: false,
      active: false,
    };
  },
  methods: {
    // searchbar functions //
    getResults: _.debounce(async function (value) {
      if (value.length >= 3) {
        this.requestPending = true;
        this.results = [];
        await this.setResults();
        this.requestPending = false;
      } else {
        this.results = [];
      }
    }, 800),
    async loadResults() {
      const params = {
        searchText: this.query,
      };
      const response = await this.$store.dispatch("fetchGlobalSearchResults", params);
      return response.body;
    },
    async setResults() {
      const results = await this.loadResults();
      if (this.active) {
        this.results = results;
        this.showError = this.results.length === 0;
      }
    },
    async updateResults(value) {
      this.showError = false;
      await this.getResults(value);
      this.showResults = true;
    },

    clearResults(timeout) {
      setTimeout(() => {
        this.query = "";
        this.showResults = false;
        this.results = [];
        this.showError = false;
      }, timeout);
    },
    redirectToExtendedSearchPage() {
      this.$router.push({ name: "ExtendedSearch", query: { query: this.query } });
    },
    redirectToResultPage(result) {
      let id, routeName;
      if (this.$isBroker) {
        id = result.leadId;
        routeName = "Lead1";
      } else {
        let isCustomer = result.customerId;
        id = isCustomer ? result.customerId : result.leadId;
        routeName = isCustomer ? "CustomerDetails" : "LeadDetails";
      }
      this.results = [];
      this.$router.push({ name: routeName, params: { id: id } });
    },
  },
};
</script>

<style lang="scss">
@import "@/theme/variable.scss";

.searchbar {
  width: 460px;
  margin: 0 20px;

  &__icon {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    &:hover {
      cursor: text;
    }
  }

  &__input {
    border: none;
    flex: 1;
    background-color: $color-navbar;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #9699a8;
      font-size: 14px;
    }
  }

  &__results-table {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    background-color: $color-white-absolute;
    -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.31);
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.31);

    .result {
      &:hover {
        cursor: pointer;
        background-color: $color-navbar;
      }
    }
  }
}
</style>
