import Vue from 'vue';
import dashboardApiPromise from '@/api/dashboard-api'
import _ from 'lodash';

export const state = {
  currentTask: null,
  currentLeadTasks: [],
  currentCustomerTasks: [],
  isLoading: false,
  taskToEdit: null,
  tasksPageNumber: 1,
  tasksPageSize: 10,
  tasksCount: 0,
  tasks: [],
}
export const getters = {
  currentTask: state => state.currentTask,
  taskToEdit: state => state.taskToEdit,
  tasks: state => state.tasks,
  currentLeadTasks: state => state.currentLeadTasks,
  currentCustomerTasks: state => state.currentCustomerTasks
}
export const mutations = {
  clearModalFromTask (state) {
    state.currentTask = null
    state.taskToEdit = null
  },
  deleteTask (state, taskId) {
    state.tasks = state.tasks.filter(task => {
      return task.taskId !== taskId
    });
    state.currentLeadTasks = state.currentLeadTasks.filter(task => {
      return task.taskId !== taskId
    });
    state.currentCustomerTasks = state.currentCustomerTasks.filter(task => {
      return task.taskId !== taskId
    });
  },
  fetchTasksList (state, body) {
    state.tasks = body.items;
    state.tasksCount = body.totalItems;
    state.tasksPageNumber = body.pageNumber;
    state.tasksPageSize = body.pageSize;
  },
  fetchTasksListForLead (state, tasks) {
    state.currentLeadTasks = tasks;
  },
  fetchTasksListForCustomer (state, tasks) {
    state.currentCustomerTasks = tasks;
  },
  setCurrentTask (state, task) {
    state.currentTask = task
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setTaskToEdit (state, task) {
    state.taskToEdit = task
  },
  updateTask (state, task) {
    state.tasks.forEach((item) => {
      if (item.taskId === task.taskId) {
        Object.assign(item, task);
      }
    });
    state.currentLeadTasks.forEach((item) => {
      if (item.taskId === task.taskId) {
        Object.assign(item, task);
      }
    });
    state.currentCustomerTasks.forEach((item) => {
      if (item.taskId === task.taskId) {
        Object.assign(item, task);
      }
    });
  },
  updateTaskList (state, task) {
    const isPresentInTasks = state.tasks.some(item => item.taskId === task.taskId)
    if (!isPresentInTasks) {
      state.tasks.push(task)
    } else {
      state.tasks.forEach((item, i) => {
        if (item.taskId === task.taskId) {
          Vue.set(state.tasks, i, task)
        }
      })
    }
  },
}
export const actions = {
  async createTask ({ dispatch }, payload) {
    await dashboardApiPromise.then(async api => {
      await api.apis.Tasks.createTask(payload).then(async r => {
        await dispatch('fetchTasks', {pageSize: 10, pageNumber: 1});
        await dispatch('fetchTasksForLead');
      })
    })
  },
  removeTask ({ commit, dispatch }, payload) {
    dashboardApiPromise.then(api => {
      api.apis.Tasks.deleteTask({ taskId: payload })
        .then(r => {
          commit('deleteTask', payload);
          dispatch('fetchTasks')
        })
    })
  },
  async updateTask ({ commit }, payload) {
    await dashboardApiPromise.then(async api => {
      await api.apis.Tasks.updateTask({}, {
        ...payload
      }).then(() => {
        commit('updateTask', payload);
      })
    })
  },
  fetchLeadsToChoose ({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dashboardApiPromise.then(api => {
        api.apis.Leads.getLeadsToChoose({}, {
          requestBody: payload
        }).then(r => {
          resolve(r)
        })
      })
    })
  },
  fetchTasksForLead({ commit, rootState }) {
    const leadId = rootState.Lead.currentLead?.leadId;
    if (leadId) {
      dashboardApiPromise.then(api => {
        api.apis.Tasks.getTasksForLeadCustomer(leadId, {}).then(async r => {
          commit('fetchTasksListForLead', r.body);
        })
      })
    }

  },
  fetchTasksForCustomer({ commit }, payload) {
    const customerId = payload.customerId;
    if (customerId) {
      dashboardApiPromise.then(api => {
        api.apis.Tasks.getTasksForCustomer(customerId, {}).then(async r => {
          commit('fetchTasksListForCustomer', _.isEmpty(r.body) ? [] : r.body);
        })
      })
    }

  },
  fetchTasks ({ commit }, { pageNumber = 1, pageSize = 10 }) {
    commit('setIsLoading', true);
    dashboardApiPromise.then(api => {
      // fixed to pageNumber: 7 because of not getting any not actioned tasks in first 600
      api.apis.Tasks.getTasks({ pageSize, pageNumber, filter: 'Nonactioned' }, {})
        .then(
          r => {
            commit('fetchTasksList', r.body);
            commit('setIsLoading', false);
          },
          () => commit('setIsLoading', false));
    })
  }
}
export const Tasks = {
  state,
  getters,
  actions,
  mutations
}
