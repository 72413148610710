<template>
  <div class="py-4">
    <div class="font-size-18">Assign <b>{{ selectedContacts.length > 1 ? selectedContacts.length + ' contacts' : selectedContacts[0].name }}</b> to...</div>
    <div class="my-3">
      <ContactsList @change="chooseBroker($event)" />
    </div>
    <span class="error" v-if="showError">You have to select broker</span>
    <div class="d-flex my-3 justify-content-end">
      <UIButton class="mr-3" text="Cancel" @click="closeModal" :type="['transparent', 'narrow']" />
      <UIButton text="Assign contact" @click="assignContact" :type="['primary', 'narrow']" />
    </div>
  </div>
</template>

<script>
import ContactsList from "@/components/DashboardComponents/AssigningContacts/ContactsList";
import UIButton from "../../../../../commonReausables/UIComponents/UIButton";
import { EventBus } from "@/utils/eventBus";

import api from '@/api'
import leadsApi from "@/api/leads";
import { objToJsonPatchReplace } from "@/utils/apiHelper";

export default {
  name: "AssignContactModal",
  components: { ContactsList, UIButton },
  props: {
    selectedContacts: Array
  },
  methods: {
    async assignContact() {
      this.showError = true
      if (this.chosenBroker) {
        this.showError = false
        const data = { intermediaryContactId: this.chosenBroker.intermediaryContactId };
        const promises = [];
        this.$store.commit('SET_PENDING_REQUEST', true)
        try {
          const apiUrl = (await api).apiSpec.API;
          leadsApi.setPrefix(apiUrl);
          this.selectedContacts.forEach(contact => {
            promises.push(leadsApi.patch(contact.leadId, objToJsonPatchReplace(data)));
          })
          await Promise.all(promises)
            .finally(() => {
              EventBus.$emit('contacts:reload')
              this.closeModal()
            })
        } finally {
          this.$store.commit('SET_PENDING_REQUEST', false)
        }
      }
    },
    chooseBroker(ev) {
      this.chosenBroker = ev
    },
    closeModal () {
      EventBus.$emit('modal:close')
    },
  },
  data() {
    return {
      chosenBroker: null,
      showError: false
    }
  }
}
</script>
