import AssignToContactModal from "../components/DashboardComponents/AssigningContacts/AssignContactModal";
import NoteCreator from "../components/DashboardComponents/Notes/NoteCreator";
import TaskCreator from "../components/DashboardComponents/Tasks/TaskCreator";
import TaskSummary from "../components/DashboardComponents/Tasks/TaskSummary";

const modalRegistry = {
  ASSIGN_TO_CONTACT: AssignToContactModal,
  NOTE_CREATOR: NoteCreator,
  TASK_CREATOR: TaskCreator,
  TASK_SUMMARY: TaskSummary,
}

export default modalRegistry;
