import apiCall from "./fetch-middleware"
import store from "@/store";

const mainOptions = {
  api: '',
}

const buildDefaultOptions = (store?) => {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
  }
}

function updateLastSave(){
  store.commit('SET_LAST_SAVE_TIME', new Date());
}

export const buildApi = () => {

  return {
  setPrefix(url) {
    mainOptions.api = url
  },

  /**
   * Creating the lead (or any other model).
   * Note that failover-stub API will be used only if GET leads has failed.
   */
    async create(payload) {
      const resp = apiCall.post(`${mainOptions.api}api/lead`, JSON.stringify(payload))
      updateLastSave()
      return await (await resp).json()
    },

    async getId(id : Number) {
      const resp = apiCall.get(`${mainOptions.api}api/lead/${id}`)
      return await (await resp).json()
    },

    async getFilter(payload) {
      const resp = apiCall.post(`${mainOptions.api}api/lead/summary/filter`, JSON.stringify(payload));
      return await (await resp).json()
    },

    async checkDuplicate(payload) {
      const resp = apiCall.post(`${mainOptions.api}api/lead/broker/checkDuplicate`, JSON.stringify(payload));
      return await (await resp).json()
    },

    async patch(id: Number, payload) {
      const resp = apiCall.patch(`${mainOptions.api}api/lead/${id}`, JSON.stringify(payload));
      updateLastSave()
      return await resp;
    },

    async addMember(id: Number, data) {
      const resp = apiCall.post(`${mainOptions.api}api/lead/retail/${id}/member`, JSON.stringify(data));
      updateLastSave()
      return await (await resp).json()
    },

    async addCommercialMember(id: Number, data){
      const resp = apiCall.post(`${mainOptions.api}api/lead/commercial/${id}/member`, JSON.stringify(data));
      updateLastSave()
      return await (await resp).json()
    },

    async loadMembers(id: Number) {
      const resp = apiCall.get(`${mainOptions.api}api/lead/${id}/member`);
      return await (await resp).json()
    },

    async deleteMember(leadId: Number, memberId: Number) {
      updateLastSave()
      await apiCall.delete(`${mainOptions.api}api/lead/${leadId}/member/${memberId}`);
    },

    async updateMember(leadId: Number, memberId: Number, payload) {
      updateLastSave()
      await apiCall.put(`${mainOptions.api}api/lead/retail/${leadId}/member/${memberId}`, JSON.stringify(payload));
    },

    async updateCommercialMember(leadId: Number, memberId: Number, payload) {
      updateLastSave()
      await apiCall.put(`${mainOptions.api}api/lead/commercial/${leadId}/member/${memberId}`, JSON.stringify(payload));
    },

    async getLeadGroupContacts(leadId: Number) {
      return await (await apiCall.get(`${mainOptions.api}api/lead/commercial/${leadId}/groupcontact`)).json();
    },
    async createLeadGroupContact(leadId: Number, payload) {
      const resp = await apiCall.post(`${mainOptions.api}api/lead/commercial/${leadId}/groupcontact`, JSON.stringify(payload));
      updateLastSave()
      return await resp.json();
    },
    async updateLeadGroupContact(leadId: Number, contactId: Number, payload) {
      updateLastSave()
      await apiCall.put(`${mainOptions.api}api/lead/commercial/${leadId}/groupcontact/${contactId}`, JSON.stringify(payload));
    },
    async deleteLeadGroupContact(leadId: Number, contactId: Number) {
      updateLastSave()
      await apiCall.delete(`${mainOptions.api}api/lead/commercial/${leadId}/groupcontact/${contactId}`);
    },
    editGroupContact(groupContact, leadId){
      return apiCall.put(`${mainOptions.api}api/lead/commercial/${leadId}/groupcontact/${groupContact.leadGroupContactId}`, JSON.stringify(groupContact))
    },
    async getApplications(leadId: Number) {
      return await (await apiCall.get(`${mainOptions.api}api/application/leadsummary/${leadId}`)).json();
    },
    async getQuotes(leadId: Number) {
      return await (await apiCall.get(`${mainOptions.api}api/quote/leadsummary/${leadId}`)).json();
    },
    async getStatuses() {
      return await (await apiCall.get(`${mainOptions.api}api/lead/status`)).json();
    },
    async getTurndownReasons() {
      return await (await apiCall.get(`${mainOptions.api}api/search/turndown-reasons`)).json();
    },
    async getLeadDocuments(leadId: Number, filters: Object) {
      return await (await apiCall.post(`${mainOptions.api}api/lead/${leadId}/documents`, JSON.stringify(filters))).json();
    },
    async downloadDocument(data) {
      return await apiCall.post(`${mainOptions.api}api/lead/document/download`, JSON.stringify(data));
    },
    async downloadCommunication(documentId) {
      return await apiCall.get(`${mainOptions.api}api/lead/communication/download/${documentId || ""}`);
    },
    async generateDocument(data) {
      const resp = apiCall.post(`${mainOptions.api}api/lead/document/generate`, JSON.stringify(data));
      return await (await resp).json();
    },
    async sendDocumentEmail(data) {
      return await apiCall.post(`${mainOptions.api}api/lead/document/email`, JSON.stringify(data));
    },
    async updateSjpId(leadId, partnerId) {
      const payload = {
        intermediaryPartnerId: partnerId
      }
      return await apiCall.put(`${mainOptions.api}api/lead/${leadId}/intermediaryPartner`, JSON.stringify(payload));
    },
    async deleteLead(leadId: number) {
      return await (await apiCall.delete(`${mainOptions.api}api/lead/${leadId}`)).json();
    }
  }
}

export default buildApi()
