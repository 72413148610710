import Vue from "vue";
import Vuex from "vuex";

import Application from "./Application";
import Lead from "./Lead";
import Customer from "./Customer";
import Product from "./Product";
import Toast from "./Toast";
import User from "./User";
import { Activity, state } from "./Dashboard/activity";
import { GlobalSearch } from "./Dashboard/globalsearch";
import { Notes } from "./Dashboard/notes";
import { Quote } from "./Dashboard/quote";
import { Tasks } from "./Dashboard/tasks";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    config: null,
    isAuthenticated: false,
    pendingRequest: false,
    confirmationDialog: {
      showDialog: false,
      callback: () => {},
      message: "",
    },
    informationDialog: {
      show: false,
      title: "",
      message: "",
    },
    showLogoutWarning: false,
    fpnDialog: {
      show: false,
      fpnContent: null,
      isFPNConfirmed: false,
    },
  },
  getters: {
    pendingRequest: (state) => state.pendingRequest,
    config: (state) => state.config,
    showLogoutWarning: (state) => state.showLogoutWarning,
    isAuthenticated: (state) => state.isAuthenticated,
    confirmationDialog: (state) => state.confirmationDialog,
    informationDialog: (state) => state.informationDialog,
    fpnDialog: (state) => state.fpnDialog,
  },
  mutations: {
    SET_CONFIG(state, config) {
      state.config = config;
    },
    SET_PENDING_REQUEST(state, boolean) {
      state.pendingRequest = boolean;
    },
    SET_SHOW_LOGOUT_WARNING(state, showLogoutWarning) {
      state.showLogoutWarning = showLogoutWarning;
    },
    SET_IS_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_CONFIRMATION_DIALOG(state, payload) {
      state.confirmationDialog = {
        ...state.confirmationDialog,
        ...payload,
      };
    },
    CLOSE_CONFIRMATION_DIALOG(state) {
      state.confirmationDialog = {
        showDialog: false,
        callback: () => {},
        message: "",
      };
    },
    SET_INFORMATION_DIALOG(state, payload) {
      state.informationDialog = {
        ...state.informationDialog,
        ...payload,
      };
    },
    CLOSE_INFORMATION_DIALOG(state) {
      state.informationDialog = {
        show: false,
        title: "",
        message: "",
      };
    },
    initialiseStore(state) {
      // Check if the ID exists
      if (localStorage.getItem("TMP_STORE")) {
        // Replace the state object with the stored item
        Object.assign(state, JSON.parse(localStorage.getItem("TMP_STORE") || ""));

        localStorage.removeItem("TMP_STORE");
      }
    },
    SET_FPN_DIALOG(state, fpn) {
      state.fpnDialog = {
        ...state.fpnDialog,
        ...fpn,
      };
    },
  },
  actions: {
    setShowLogoutWarning({ commit }, showLogoutWarning) {
      commit("SET_SHOW_LOGOUT_WARNING", showLogoutWarning);
    },
    setIsAuthenticated({ commit }, isAuthenticated) {
      commit("SET_IS_AUTHENTICATED", isAuthenticated);
    },
  },
  modules: {
    Activity,
    Application,
    GlobalSearch,
    Lead,
    Customer,
    Notes,
    Product,
    Quote,
    Tasks,
    Toast,
    User,
  },
});
