import { RouteConfig } from "vue-router";
import { checkAuthAndRedirect } from "../api/auth-redirect";
import store from "../store/index";

import isBroker from "@/utils/isBroker";
const brokerDetailsChildren = [
  {
    path: "quotes",
    name: "Lead1",
    component: () => import(/* webpackChunkName: "quotes" */ "@/views/Lead/Quotes.vue"),
  },
  {
    path: "applications",
    name: "Lead2",
    component: () => import(/* webpackChunkName: "applications" */ "@/views/Lead/Applications.vue"),
  },
];
const hpDetailsChildren = [
  {
    path: "quotes",
    name: "Lead1",
    component: () => import(/* webpackChunkName: "quotes" */ "@/views/Lead/Quotes.vue"),
  },
  {
    path: "applications",
    name: "Lead2",
    component: () => import(/* webpackChunkName: "applications" */ "@/views/Lead/Applications.vue"),
  },
  {
    path: "demands",
    name: "Lead3",
    component: () => import(/* webpackChunkName: "demands" */ "@/views/Lead/LeadsDemands.vue"),
  },
  {
    path: "notes",
    name: "Lead4",
    component: () => import(/* webpackChunkName: "leads" */ "@/views/Lead/LeadNotes.vue"),
  },
  {
    path: "documents",
    name: "Lead5",
    component: () => import(/* webpackChunkName: "documents" */ "@/views/Lead/LeadDocuments.vue"),
  },
  {
    path: "literature",
    name: "Lead6",
    component: () => import(/* webpackChunkName: "LiteratureLead" */ "@/views/Lead/LiteratureLead.vue"),
  },
  {
    path: "tasks",
    name: "Lead7",
    component: () => import(/* webpackChunkName: "leads" */ "@/views/Lead/LeadTasks.vue"),
  },
  {
    path: "email",
    name: "Lead8",
    component: () => import(/* webpackChunkName: "leads" */ "@/views/Lead/SendEmail.vue"),
  },
];

const routes: Array<RouteConfig> = [
  {
    path: isBroker ? "/clients" : "/leads",
    component: () => import("@/views/Leads.vue"),
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    children: [
      {
        path: "",
        name: "Leads",
        component: () => import(/* webpackChunkName: "leadsummaries" */ "@/views/Lead/LeadSummaries.vue"),
      },
      {
        path: "new",
        name: "LeadsNew",
        component: () => import(/* webpackChunkName: "leads" */ "@/views/Lead/Create.vue"),
      },
      {
        path: ":id/:subview?",
        alias: [":id"],
        name: "LeadDetails",
        component: () => import(/* webpackChunkName: "leads" */ "@/views/Lead/Details.vue"),
        children: isBroker ? brokerDetailsChildren : hpDetailsChildren,
      },
    ],
  },
];

export default routes;
