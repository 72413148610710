import Vue from "vue";
import { uniqWith, isEqual } from "lodash";
import moment from "moment";
import { updateLastSave } from '@/utils/helpers';

export default {
  state: {
    contacts: [],
    currentApplication: null,
    qualifyingQuestions: [],
    qualifyingAnswers: [],
    declarationQuestions: [],
    requiredFieldErrors: {},
    medicalDeclarationCompleted: false,
    hideStepBar: false,
    conditions: [],
    directDebitDirty: false,
    cardAccepted: false,
    supplyEmployeesOption: null,
    underwritingDeclarationConfirmed: false,
    videoModal: false,
    editPolicyholder: false,
    authorityToAuthoriseDebit: null,
    isAnsweringSecondaryQuestions: false,
    continueOrSendInvite: null,
    files: {
      currentFiles: [],
      client: null,
      fileUploadError: false,
    }
  },
  mutations: {
    setFile(state, payload){
      state.files.currentFiles = payload.currentFiles;
      state.files.client = payload.client;
    },
    setFileUploadError(state, val){
      state.files.fileUploadError = val;
    },
    setCurrentApplication(state, application) {
      if (application != undefined) {
        if (application.households != undefined) {
          application.households.forEach((x) => {
            if (x.applicationMembers != undefined) {
              x.applicationMembers.sort((a, b) => {
                return a.personId - b.personId;
              });
            }
          });
        }
        if (
          state.currentApplication != undefined &&
          application.startDate != state.currentApplication.startDate
          ) {
          application.households.forEach((hous) => {
            hous.applicationMembers.forEach((member) => {
              if (
                member.dateOfBirth != undefined &&
                application.startDate != undefined
                ) {
                  let currentTime = Date.parse(application.startDate);
                  let birthDateTime = Date.parse(member.dateOfBirth);
                  let difference = currentTime - birthDateTime;
                  member.age = moment.duration(difference).years();
                }
              });
            });
          }
        updateLastSave();
      }
      state.currentApplication = application;
    },
    setQualifyingQuestions(state, questions) {
      state.qualifyingQuestions = questions;
      state.qualifyingAnswers = questions.map((q) => q.hasAgreement);
    },
    setQualifyingAnswer(state, { index, answer }) {
      updateLastSave();
      Vue.set(state.qualifyingAnswers, index, answer);
    },
    updateApplication(state, payload) {
      Object.assign(state.currentApplication, payload);
    },
    updateApplicationMember(state, { householdIdx, memberIdx, payload }) {
      updateLastSave();
      const member =
        state.currentApplication.households[householdIdx]?.applicationMembers[
          memberIdx
        ] || {};
      if (
        payload.dateOfBirth != undefined &&
        state.currentApplication.startDate != undefined &&
        member.dateOfBirth != payload.dateOfBirth
      ) {
        let currentTime = Date.parse(state.currentApplication.startDate);
        let birthDateTime = Date.parse(payload.dateOfBirth);
        let difference = currentTime - birthDateTime;
        member.age = moment.duration(difference).years();
        if (payload.age != undefined) {
          payload.age = member.age;
        }
      }
      const newMember = Object.assign({}, member, payload);
      Vue.set(
        state.currentApplication.households[householdIdx].applicationMembers,
        memberIdx,
        newMember
      );
    },
    updateApplicationMemberById(state, { householdId, memberId, payload }) {
      const household = state.currentApplication.households.find(
        (h) => h.householdId == householdId
      );
      const memberIdx = household.applicationMembers.findIndex(
        (am) => am.applicationMemeberId == memberId
      );
      if (
        payload.dateOfBirth != undefined &&
        state.currentApplication.startDate != undefined &&
        household.applicationMembers[memberIdx].dateOfBirth !=
          payload.dateOfBirth
      ) {
        let currentTime = Date.parse(state.currentApplication.startDate);
        let birthDateTime = Date.parse(payload.dateOfBirth);
        let difference = currentTime - birthDateTime;
        household.applicationMembers[memberIdx].age = moment
          .duration(difference)
          .years();
        if (payload.age != undefined) {
          payload.age = household.applicationMembers[memberIdx].age;
        }
      }
      const newMember = Object.assign(
        {},
        household.applicationMembers[memberIdx],
        payload
      );
      Vue.set(household.applicationMembers, memberIdx, newMember);
    },
    updateHousehold(state, { householdIdx, payload }) {
      const household = state.currentApplication.households[householdIdx] || {};
      const newHousehold = Object.assign({}, household, payload);
      Vue.set(state.currentApplication.households, householdIdx, newHousehold);
    },
    updateHouseholdById(state, { householdId, payload }) {
      const householdIdx = state.currentApplication.households.findIndex(
        (h) => h.householdId == householdId
      );
      const newHousehold = Object.assign(
        {},
        state.currentApplication.households[householdIdx],
        payload
      );
      Vue.set(state.currentApplication.households, householdIdx, newHousehold);
    },
    setMedicalDeclarationCompleted(state, value) {
      state.medicalDeclarationCompleted = value;
    },
    determineIfAnsweringSecondaryQuestions(state, value) {
      state.isAnsweringSecondaryQuestions = value;
    },
    setApplicationHideStepBar(state, value) {
      state.hideStepBar = value;
    },
    setDeclarationQuestions(state, value) {
      state.declarationQuestions = value;
    },
    setDeclarationQuestion(state, { index, question }) {
      updateLastSave();
      Vue.set(state.declarationQuestions, index, question);
    },
    setApplicationPaymentMethod(state, data) {
      if (state.currentApplication !== null) {
        state.currentApplication.paymentMethod = data;
      }
    },
    setDirectDebitDirty(state, data) {
      state.directDebitDirty = data;
    },
    setCardAcceptance(state, data) {
      state.cardAccepted = data;
    },
    setPolicyHolder(state, data) {
      if (state.currentApplication && state.currentApplication.policyHolder) {
        state.currentApplication.policyHolder = {
          ...state.currentApplication.policyHolder,
          ...data,
        };
      }
    },
    setApplicationPaymentInterval(state, data) {
      if (state.currentApplication !== null) {
        state.currentApplication.paymentInterval = data;
      }
    },
    setApplicationMember(state, data) {
      if (state.currentApplication) {
        if (data.householdId) {
          const householdIndex = state.currentApplication.households.findIndex(
            (h) => h.id === data.householdId
          );
          const memberIndex = state.currentApplication.households[
            householdIndex
          ].applicationMembers.findIndex((m) => m.id === data.memberId);
          Vue.set(
            state.currentApplication.households[householdIndex]
              .applicationMembers,
            memberIndex,
            {
              ...state.currentApplication.households[householdIndex]
                .applicationMembers[memberIndex],
              ...data.payload,
            }
          );
        } else {
          const memberIndex =
            state.currentApplication.households[0].applicationMembers.findIndex(
              (m) => m.id === data.memberId
            );
          Vue.set(
            state.currentApplication.households[0].applicationMembers,
            memberIndex,
            {
              ...state.currentApplication.households[0].applicationMembers[
                memberIndex
              ],
              ...data.payload,
            }
          );
        }
      }
    },
    setApplicationDirectDebit(state, data) {
      if (state.currentApplication !== null) {
        updateLastSave();
        Vue.set(state.currentApplication, "directDebitPaymentAccepted", data);
      }
    },
    setApplicationContacts(state, contacts) {
      state.contacts = contacts;
    },
    updateApplicationContact(state, contact) {
      const idx = state.contacts.findIndex(
        (c) => c.buyworkGroupContactId == contact.buyworkGroupContactId
      );
      Vue.set(state.contacts, idx, contact);
    },
    addApplicationContact(state, contact) {
      state.contacts.push(contact);
    },
    deleteApplicationContact(state, buyworkGroupContactId) {
      const idx = state.contacts.findIndex(
        (c) => c.buyworkGroupContactId == buyworkGroupContactId
      );
      state.contacts.splice(idx, 1);
    },
    setConditions(state, conditions) {
      state.conditions = conditions;
    },
    setSupplyEmployeesOption(state, option) {
      state.supplyEmployeesOption = option;
    },
    setUnderwritingDeclarationConfirmed(state, confirmed) {
      updateLastSave();
      state.underwritingDeclarationConfirmed = confirmed;
    },
    toggleVideoModal(state) {
      state.videoModal = !state.videoModal;
    },
    removeGroupMembers(state) {
      state.currentApplication.households = [];
    },
    setApplicationRequireRepricing(state, data) {
      state.currentApplication.requireRepricing = data;
    },
    setEditPolicyholder(state, edit) {
      state.editPolicyholder = edit;
    },
    setAuthorityToAuthoriseDebit(state, value) {
      state.authorityToAuthoriseDebit = value;
    },
    setRequiredFieldsErrors(state, errors) {
      state.requiredFieldErrors = errors;
    },
    setContinueOrSendInvite(state, value) {
      updateLastSave();
      state.continueOrSendInvite = value;
    },
  },
  getters: {
    files(state){
      return state.files;
    },
    availableGps(state) {
      if (!state.currentApplication) return [];
      const gps = state.currentApplication.households
        .flatMap((h) =>
          h.applicationMembers.map((am) => ({
            gpPracticeId: am.gpPracticeId,
            gpPracticeName: am.gpPracticeName,
            gpType: am.gpType,
            gpNotFound: am.gpNotFound,
          }))
        )
        .filter((gp) => gp.gpPracticeId || gp.gpNotFound);
      return uniqWith(
        gps,
        (gp, otherGp) =>
          gp.gpPracticeId == otherGp.gpPracticeId &&
          gp.gpType == otherGp.gpType &&
          gp.gpNotFound == otherGp.gpNotFound
      );
    },
    primaryApplicationContact(state) {
      return state.contacts.find((c) => c.isPrimaryContact);
    },
    requiredFieldErrors(state) {
      return state.requiredFieldErrors;
    },
    qualifyingQuestionsAnswered(state) {
      return state.qualifyingAnswers.every((a) => a);
    },
    isAnsweringSecondaryQuestions(state) {
      return state.isAnsweringSecondaryQuestions;
    },
    underwritingSelected(state) {
      return state.currentApplication.households
        .flatMap((h) => h.applicationMembers)
        .every((am) => am.joiningMethod);
    },
    declarationsFilled(state) {
      if (state.declarationQuestions.length == 0) return false;
      for (const question of state.declarationQuestions) {
        if (question.hasAgreement == null) {
          return false;
        }
        const isConfirmed = question.hasAgreement == question.yesIsAffirmative;
        if (question.isMandatory && !isConfirmed) {
          return false;
        }
        if (!isConfirmed && question.requireDetails && !question.details) {
          return false;
        }
      }
      return true;
    },
    sjpStatusRequired(state) {
      if (state.currentApplication)
      {
        const productId = state.currentApplication.productId;
        return productId == 951 || (productId == 1072 && state.currentApplication.sjpPartner == true);
      }
      return false;
    },
    currentApplication(state) {
      return state.currentApplication;
    },
    continueOrSendInviteGetter(state) {
      return state.continueOrSendInvite;
    },
  },
};
