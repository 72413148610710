import { childRelationships, adultRelationships } from '@/utils/consts/relationshipTypes';
import { isSubscriberRelationship } from '@/utils/relationships.js';

const familyTypes = {
  SINGLE: "Single",
  ONE_PARENT: "One Parent",
  JOINT: "Joint",
  FAMILY: "Family"
}
export default familyTypes;

export const getFamilyTypeFromQuoteHousehold = (household) => {
  const adults = household.familyMembers.filter(m => adultRelationships.includes(m.relationship)).length + 1; // + 1 subscriber / employee
  const children = household.familyMembers.filter(m => childRelationships.includes(m.relationship)).length;

  const subscriber = household.subscriber ? household.subscriber : household.familyMembers.find(member => isSubscriberRelationship(member.relationship));
  const familyType = subscriber.options.find(option => option.optionId == 11)?.value;

  return {familyType, adults, children};
}

export function getFamilyType(members, childMembers){
  let adults = 1;
  let children = 0;

  if(members) {
    adults += Object.keys(members).filter(k => /^\d+$/.test(k)).length;
  }

  if(childMembers) {
    children += Object.keys(childMembers).filter(k => /^\d+$/.test(k)).length;
  }

  let familyType = "";

  if(adults == 1) {
    if(children == 0) {
      familyType = familyTypes.SINGLE;
    }
    else {
      familyType = familyTypes.ONE_PARENT;
    }
  } else{
    if(children == 0) {
      familyType = familyTypes.JOINT;
    }
    else {
      familyType = familyTypes.FAMILY;
    }
  }

  return {familyType, adults, children};
}

export function getHouseholdSummary(members, childMembers) {
  const familyCalculated = getFamilyType(members, childMembers);
  const adultsForm = familyCalculated.adults > 1 ? 'adults' : 'adult'
  const childrenForm = familyCalculated.children > 1 ? ' children' : ' child'
  return `${familyCalculated.adults} ${adultsForm}${familyCalculated.children > 0 ? ', ' + familyCalculated.children + childrenForm : ''} (${familyCalculated.familyType})`;
}
