
export default {
  state: {
    id: null,
    name: null,
    productType: null,
    minLives: null,
    maxLives: null,
    maxNumberOfHouseholds: null,
    minAge: null,
    maxAge: null,
    maxChildAge: null,
    maxChildAgeNotAttendEducation: null,
    isMultiFamily: null,
    isTopUp: null,
    isDental: null,
    isSwitch: null
  },
  mutations: {
    setProductConfig(state, data) {
      Object.assign(state, data);
    },
  },
  getters: {
    productConfig(state) {
      return state;
    }
  }
}
