<template>
  <div class="task__creator py-4">
    <div class="form-group">
      <label for="taskTitle">Title</label>
      <input type="text" class="form-control"
             id="taskTitle"
             v-model="task.title"
             placeholder="Please type your title">
    </div>
    <div class="form-group">
      <label for="taskComments">Comments</label>
      <textarea class="form-control"
                id="taskComments"
                v-model="task.comments"
                rows="3"
                placeholder="Please type your comments"></textarea>
    </div>
    <div class="d-flex">
      <div class="form-group pr-3 w-50">
        <label for="taskDate">Due date</label>
          <datepicker id="taskDate" v-model="task.dueDateTime" inputClass="form-control" placeholder="DD-MM-YYYY" valueType="format" format="DD-MM-YYYY" />
      </div>
      <div class="form-group pl-3 w-50">
        <label for="taskTime">Due time</label>
        <datepicker
          v-model="task.time"
          inputClass="form-control"
          :time-picker-options="{
            start: '00:00',
            step: '00:30',
            end: '23:30',
          }"
          format="HH:mm"
          valueType="format"
          type="time"
          placeholder="HH:MM"
        >
          <template slot="icon-calendar">
            <img class="clock" :src="icons.clock">
          </template>
        </datepicker>
      </div>
    </div>
    <div class="form-group" style="position: relative" v-if="!leadId && !customerId">
      <label for="taskCustomer">Link a Lead/Customer</label>
      <SearchSelect @change="linkChange" :selectedValue="editTaskCustomer" />
    </div>
    <div class="d-flex justify-content-between mt-5">
      <UIButton :text="pendingRequest ? '' : 'Save'"
                :disabled="saveDisabled"
                @click="updateTask()"
                :icon="pendingRequest ? icons.loader : null"
                :type="['primary', 'wide']" />
      <UIButton text="Cancel" @click="closeModal" :type="['secondary', 'wide']" />
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapGetters } from 'vuex';
import _ from 'lodash'

import { EventBus } from "@/utils/eventBus";
import icons from "@/utils/icons";
import timeCounter from "../../../../../commonReausables/utils/timeCounter";
import idGenerator from "../../../utils/randomId";

import UIButton from "../../../../../commonReausables/UIComponents/UIButton";
import SearchSelect from "@/components/SearchSelect"
import moment from 'moment';

export default {
name: "TaskCreator",
  components: { Datepicker, UIButton, SearchSelect },
  mixins: [ idGenerator, timeCounter ],
  props: {
    leadId: Number,
    customerId: Number
  },
  data () {
    return {
      icons,
      leads: [],
      loader: false,
      task: {
        actioned: false,
        comments: '',
        customer: {},
        dueDateTime: null,
        taskId: null,
        time: null,
        title: '',
      },
      pendingRequest: false
    }
  },
  watch: {
    $route(to, from) {
      if (from.params.id != to.params.id) { // close modal if route has changed
        this.closeModal();
      }
    }
  },
  created () {
    if (this.leadId) {
      const customer = {
        linkEntity: "lead_customer",
        linkId: this.leadId,
        linkName: ""
      };
      this.task.customer = customer;
    }
    if (this.customerId) {
      const customer = {
        linkEntity: "customer",
        linkId: this.customerId,
        linkName: ""
      };
      this.task.customer = customer;
    }
    if (this.taskToEdit) {
      const customer = {
        linkEntity: this.taskToEdit.linkEntity,
        linkId: this.taskToEdit.linkId,
        linkName: this.taskToEdit.linkName,
      };
      const dueDateTime = moment(this.taskToEdit.dueDate).format('DD-MM-YYYY');

      // cuts four digits time value from date
      const time = this.taskToEdit.dueDate.substring(this.taskToEdit.dueDate.indexOf('T') + 1, this.taskToEdit.dueDate.length - 3)

      this.task = Object.assign({}, this.taskToEdit, { customer: customer, dueDateTime: dueDateTime, time: time })
    }
  },
  methods: {
    closeModal () {
      EventBus.$emit('modal:close')
    },
    getDateTime(date, time) {
      const dateWithoutTimeZone = `${date}${time ? ('T' + time + ':00') : ''}`;

      return moment(dateWithoutTimeZone, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    },
    async updateTask () {
      if (!this.pendingRequest) {
        this.pendingRequest = true;

        try {
          const dueDate = this.getDateTime(this.task.dueDateTime, this.task.time)
          const payload = {
            "actioned": this.task.actioned,
            "taskId": this.taskToEdit ? this.taskToEdit.taskId : null,
            "comments": this.task.comments,
            "dueDate": dueDate,
            "linkEntity": this.task.customer.linkEntity,
            "linkId": this.task.customer.linkId,
            "title": this.task.title,
            "isFollowUp": this.taskToEdit ? this.taskToEdit.isFollowUp : false,
          }

          this.taskToEdit ? await this.$store.dispatch('updateTask', payload) : await this.$store.dispatch('createTask', payload)
          this.pendingRequest = false;
          await this.$bvModal.msgBoxOk(
            'Task has been successfully saved', {
              title: "Task",
            });
          this.closeModal();
        } finally {
          this.pendingRequest = false;
        }
      }
    },
    linkChange(value) {
      this.task.customer.linkId = value ? (value.leadId ? value.leadId : value.customerId) : null;
      this.task.customer.linkEntity = value ? (value.leadId ? "lead_customer" : "customer") : null;
    }
  },
  computed: {
    ...mapGetters ([
      'taskToEdit'
    ]),
    saveDisabled() {
      return this.pendingRequest || !this.task.title || !this.task.comments || !this.task.dueDateTime;
    },
    editTaskCustomer() {
      if (this.taskToEdit) {
        return {
          name: this.taskToEdit.linkName,
          leadId: this.taskToEdit.linkEntity === "lead_customer" ? this.taskToEdit.linkId : null,
          customerId: this.taskToEdit.linkEntity === "customer" ? this.taskToEdit.linkId : null
        }
      }

      return null;
    }
  },
}
</script>

<style lang="scss">
  .loader-icon {
    position: absolute;
    right: 10px;
    top: 40px;
    z-index: 99;
  }
</style>
