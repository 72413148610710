import api from "@/api";
import { childRelationships, adultRelationships } from "@/utils/consts/relationshipTypes";
import moment from "moment";
import productTypes from "@/utils/consts/productTypes";
import Vue from "vue";
import isBroker from "@/utils/isBroker";

import { getFamilyType, getFamilyTypeFromQuoteHousehold } from "@/utils/consts/familyType";
import { mean } from "lodash";
import { v4 as guid } from "uuid";
import { updateLastSave } from "@/utils/helpers";

const today = moment().format("YYYY-MM-DD").toString();
const corporateDefaultStartDate = moment().add(1, "month").date(1).format("YYYY-MM-DD").toString(); // the first day of next month
const ehAgeDifference = 3 / 100;
const ehCountDifference = 15 / 100;
const getEmptyLead = () => ({
  policyHolder: {
    title: null,
    firstName: null,
    lastName: null,
    isInsured: null,
    sjpPartnerInTheHousehold: null,
    sexAtBirth: null,
    dateOfBirth: null,
    age: null,
    heightCm: null,
    weightKg: null,
    smokerVaper: null,
    telephoneNumbers: [],
    preferredTelephoneNumber: null,
    email: null,
    selfEmployed: null,
    professional: null,
    occupationId: null,
    occupationName: null,
    isStJamesPlace: null,
    stJamesPlacePracticeName: null,
    gpTypeId: null,
    gpPracticeName: null,
    gpPracticeId: null,
    isCostcoMember: null,
    membershipType: null,
  },
  address: {
    postcode: null,
    addressLine1: null,
    addressLine2: null,
    addressLine3: null,
    addressLine4: null,
  },
  tradingAddress: {
    postcode: null,
    addressLine1: null,
    addressLine2: null,
    addressLine3: null,
    addressLine4: null,
  },
  companyDetails: {},
  contactDetails: [],
  currentInsurance: {},
  leadId: 0,
});

interface QuoteState {
  areRequiredFieldsFilled: Boolean;
  allProducts: Product[];
  selectedProduct: Product | null;
  customerData: Object;
  quoteDetails: Object | null;
  totalPrice: Array<string> | null;
  quoteErrors: Object | null;
  leadCustomer: Object | null;
  leadUpdateData: Object;
  quoteConfig: Object;
  quickQuotePayload: Object | null;
  quoteData: QuoteObject | null;
  excelEmployeesFile: Object | null;
  excelParsingDetails: Object | null;
  excelEmployees: Array<Object> | null;
  importSucceeded: Boolean;
  importedEmployees: Object | null;
  isImportApplication: Boolean;
  groupContactPending: Boolean;
  leadMembers: Array<object>;
  defaultOptions: any;
  dirty: Boolean;
  repricingRequired: Boolean;
  loadedSteps: Array<Number>;
  masterQuote: Object;
  renewalQuote: Object | null;
}

interface Product {
  productId: number;
  name: string;
  groupName: string;
  type: number;
  summary: string;
}

interface QuoteObject {
  productName: string;
  availableBenefits: Object[];
  coverDescriptions: CoverDescription[];
  documentsToDownload: [] | null;
  selectedCoverLevelId: number;
  commonWithAllMembersSummary: [] | null;
  discountDetails: string | null;
  discountFootnote: string | null;
  householdPrices: HouseholdPrices[];
  householdOptions: HouseholdOption[];
  isSharedResponsibilityOverExcess: boolean;
}

interface CoverDescription {
  coverName: string;
  id: number;
  price: number;
  priceFull: number;
  shortDescription: string;
  unit: string;
}

interface HouseholdPrices {
  discountDetails: string | null;
  memberPrices: MemberPrices[];
}

interface MemberPrices {
  coverPrices: Partial<CoverPrice>[];
  memberName: string | null;
}

interface CoverPrice {
  memberName: string | null;
  coverLevel: string;
  priceNetPremium: number | null;
  priceGrossPremium: number | null;
  priceGrossYearly: number | null;
  priceGrossMonthly: number | null;
  priceGrossYearlyFull: number | null;
  priceGrossMonthlyFull: number | null;
  productId: number;
}

interface HouseholdOption {
  memberOptions: MemberOption[];
}

interface MemberOption {
  memberName: string | null;
  coverOptions: CoverOption[];
}

interface CoverOption {
  productId: number;
  coverLevel: string;
  optionId: number;
  value: string;
  priceNet: number;
  priceGross: number;
}

interface QuoteDetails {
  id: number;
  productId: number;
  startDateOfPolicy: string;
  expirationDate: string;
  homePostcode: string;
  households: Array<Object>;
  externalLinkId: number;
  affinityId: number;
  sponsoredId: number;
  costcoMembershipTypeId: number;
  promotionId: number;
  occupationId: number;
  occupation: string;
  isProfessional: false;
  selfEmployed: false;
  priceGrossMonthly: number;
  proceGrossYearly: number;
  priceNetPremium: number;
  priceGrossPremium: number;
  options: Array<object>;
}

export const state: QuoteState = {
  areRequiredFieldsFilled: true, // for highlighting required fields in quote and application processes
  allProducts: [],
  selectedProduct: null,
  customerData: {},
  isImportApplication: false,
  totalPrice: null,
  quoteErrors: [],
  masterQuote: {
    subscribersCount: null,
    subscribersMeanAge: null,
    quoteId: null,
  },
  leadCustomer: getEmptyLead(),
  leadUpdateData: {},
  quickQuotePayload: null,
  quoteConfig: {
    lastSave: new Date(),
    employeesAlreadyImportedToView: false,
    quoteHeaderVisible: true,
    policyHolderAddedAsSubscriber: false,
    1036: {
      //Enterprise Flexible Benefits
      productName: "Enterprise Flexible Benefits",
      productType: productTypes.COMMERCIAL,
      minLives: 2,
      maxLives: isBroker ? 14 : 200,
      warningNumberLives: 14,
      allowImportEmployees: true,
      numberOfLivesLabel: "Number of employees",
      numberOfLivesDesc: "Continue editing on the Employees stage",
      quoteInformations: {
        numberOfLives: true,
      },
      companyDetails: {
        name: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        tradingName: { visible: true, required: false, visibleForBroker: false },
        companyNumber: { visible: true, required: false, visibleForBroker: false },
        sicCode: { visible: true, required: false, visibleForBroker: false },
        sicSection: { visible: true, required: false, visibleForBroker: false },
        isStJamesPlace: { visible: true, required: false, visibleForBroker: false },
        stJamesPlacePracticeName: { visible: true, required: false, visibleForBroker: false },
        stJamesPlacePracticeId: { visible: true, required: true, visibleForBroker: false },
      },
      currentInsurance: {
        currentPremium: { visible: true, required: false },
        paymentInterval: { visible: true, required: false },
        planName: { visible: true, required: false },
        renewalDate: { visible: true, required: false },
        providerName: { visible: true, required: false },
      },
      importEmployees: {
        mandatoryFields: [
          { title: "Age", field: "age", isRequired: true, type: "number" },
          {
            title: "Group Postcode",
            isRequired: true,
            field: "group_postcode",
          },
          {
            title: "Family type",
            field: "familyType",
            isRequired: true,
            type: "familyType",
          },
          {
            title: "Relationship",
            field: "relationship",
            isRequired: true,
            type: "relationship",
          },
        ],
        optionalFields: [
          {
            title: "First Name",
            field: "firstName",
            isRequired: true,
            type: "string",
          },
          {
            title: "Lastname",
            field: "lastName",
            isRequired: true,
            type: "string",
          },
          {
            title: "Cover options",
            field: "coverOptions",
            isRequired: true,
            type: "string",
          },
          { title: "Date of birth", field: "dateOfBirth", type: "date" },
          { title: "Customer ID", field: "customerId" },
          { title: "Title", field: "title" },
          { title: "Middle Name", field: "middleName" },
          { title: "Underwriting", field: "underwriting" },
          { title: "Outpatient treatment", field: "outpatient_tratment" },
          {
            title: "Psychiatric - Out-Patient/Therapy",
            field: "psychiatric_outpatient",
            type: "number",
          },
          { title: "New Joiner Adjustment", field: "new_joiner_adjustment" },
          { title: "SR limit", field: "sr_limit", type: "number" },
          { title: "SR %", field: "sr_percent", type: "number" },
          { title: "Excess", field: "excess" },
          { title: "Higher Cost Centres", field: "higher_cost_centres" },
          { title: "Six Week Wait", field: "six_week_wait" },
          { title: "Cash Extras", field: "cash_extras" },
          { title: "Dental Plan", field: "dental_plan" },
          { title: "Oral surgery", field: "oral_surgery" },
          { title: "Chronic Care", field: "chronic_care" },
          { title: "Winter sports", field: "winter_sports" },
          { title: "Renewal year", field: "renewal_year", type: "number" },
        ],
        extractOnlyEmployees: false,
      },
      importEmployeesApplication: {
        mandatoryFields: [
          {
            title: "First Name",
            field: "firstName",
            type: "string",
            isRequired: true,
          },
          {
            title: "Last Name",
            field: "lastName",
            type: "string",
            isRequired: true,
          },
          {
            title: "Relationship",
            field: "relationship",
            type: "string",
            isRequired: true,
          },
          { title: "Email", field: "email", type: "string", isRequired: true },
        ],
      },
      importEmployeesFields: {
        // not online invite import
        requiredFields: [
          {
            title: "First Name",
            field: "firstName",
            type: "string",
            isRequired: true,
          },
          {
            title: "Last Name",
            field: "lastName",
            type: "string",
            isRequired: true,
          },
          { title: "Title", field: "title", type: "string", isRequired: true },
          {
            title: "Date of birth",
            field: "dateOfBirth",
            type: "date",
            isRequired: true,
          },
          {
            title: "Relationship",
            field: "relationship",
            type: "string",
            isRequired: true,
          },
          {
            title: "Gender",
            field: "gender",
            type: "string",
            isRequired: true,
          },
          {
            title: "Address Line 1",
            field: "address1",
            type: "string",
            isRequired: true,
          },
          {
            title: "Address Line 2",
            field: "address2",
            type: "string",
            isRequired: true,
          },
          {
            title: "Postcode",
            field: "postcode",
            type: "string",
            isRequired: true,
          },
          {
            title: "Underwriting Term",
            field: "underwritingTerm",
            type: "string",
            isRequired: true,
          },
          {
            title: "Outpatient",
            field: "outpatient",
            type: "string",
            isRequired: true,
          },
          {
            title: "Therapy",
            field: "therapy",
            type: "string",
            isRequired: true,
          },
          {
            title: "Worldwide",
            field: "worldwide",
            type: "string",
            isRequired: true,
          },
          {
            title: "Shared Responsibility Limit",
            field: "singleResponsibilityLimit",
            type: "number",
            isRequired: true,
          },
          {
            title: "Cash Extras",
            field: "cashExtras",
            type: "string",
            isRequired: true,
          },
          {
            title: "Dental Plan",
            field: "dentalPlan",
            type: "string",
            isRequired: true,
          },
          {
            title: "GP Fees",
            field: "gpFees",
            type: "string",
            isRequired: true,
          },
          {
            title: "Structured Counselling",
            field: "structuredCounselling",
            type: "string",
            isRequired: true,
          },
        ],
        optionalFields: [
          { title: "Always Invite", field: "alwaysInvite", isRequired: false },
          { title: "Customer ID", field: "customerId", isRequired: false },
          {
            title: "Employee No",
            field: "employeeNo",
            type: "string",
            isRequired: false,
          },
          {
            title: "Previous Insurer Join Date",
            field: "previousInsurerJoinDate",
            type: "date",
            isRequired: false,
          },
          {
            title: "Previous Insurer UW Date",
            field: "previousInsurerUwDate",
            type: "date",
            isRequired: false,
          },
          {
            title: "Original Underwriting Date",
            field: "originalUnderwritingDate",
            type: "date",
            isRequired: false,
          },
          {
            title: "Personal Exclusions",
            field: "personalExclusions",
            type: "string",
            isRequired: false,
          },
          {
            title: "Admin Exclusions",
            field: "adminExclusions",
            type: "string",
            isRequired: false,
          },
          {
            title: "Exclusion period",
            field: "exclusionPeriod",
            isRequired: false,
          },
          {
            title: "Symptom Free Period",
            field: "symptomFreePeriod",
            type: "string",
            isRequired: false,
          },
          {
            title: "Moratorium Period",
            field: "moratoriumPeriod",
            type: "string",
            isRequired: false,
          },
          {
            title: "Middle Name",
            field: "middleName",
            type: "string",
            isRequired: false,
          },
          {
            title: "Join Date",
            field: "joinDate",
            type: "date",
            isRequired: false,
          },
          {
            title: "Leave Date",
            field: "leaveDate",
            type: "date",
            isRequired: false,
          },
          {
            title: "Address Line 3",
            field: "address3",
            type: "string",
            isRequired: false,
          },
          {
            title: "Address Line 4",
            field: "address4",
            type: "string",
            isRequired: false,
          },
          {
            title: "Email Personal",
            field: "emailPersonal",
            type: "string",
            isRequired: false,
          },
          {
            title: "Email Work",
            field: "emailWork",
            type: "string",
            isRequired: false,
          },
          {
            title: "Telephone Home",
            field: "telephoneHome",
            type: "string",
            isRequired: false,
          },
          {
            title: "Telephone Mobile",
            field: "telephoneMobile",
            type: "string",
            isRequired: false,
          },
          {
            title: "Telephone Work",
            field: "telephoneWork",
            type: "string",
            isRequired: false,
          },
          {
            title: "Essential",
            field: "essential",
            type: "string",
            isRequired: false,
          },
          {
            title: "Company Islander",
            field: "companyIslander",
            type: "string",
            isRequired: false,
          },
          {
            title: "Shared Responsibility %",
            field: "singleResponsibilityValue",
            type: "number",
            isRequired: false,
          },
          {
            title: "Qualifying Period",
            field: "qualifyingPeriod",
            type: "string",
            isRequired: false,
          },
          {
            title: "A&E Abroad",
            field: "aeAbroad",
            type: "string",
            isRequired: false,
          },
          {
            title: "Cancer drugs",
            field: "cancerDrugs",
            type: "string",
            isRequired: false,
          },
          {
            title: "Smoker",
            field: "smoker",
            type: "string",
            isRequired: false,
          },
        ],
      },
      defaultStartDate: corporateDefaultStartDate,
      quoteOptionsOrder: [
        "Underwriting Terms",
        "Shared Responsibility",
        "Optional Extras - to enhance the Essential Cover",
        "Cash Extras",
        "Cash Extras - Cash benefits up to the maximum annual benefit limits shown",
        "Dental Care - Dental treatment either on the NHS or with a private dentist.",
        "In-patient and Day-patient Treatment",
        "Out-patient Treatment",
        "Cancer Care",
        "NHS Hospital Cash Benefit (non-cancer)",
        "NHS Hospital Cash Benefit (cancer)",
        "Remote Benefits",
        "Further Benefits",
      ],
    },
    860: {
      //NHS Top-Up Corp
      productName: "NHS Top-Up Corporate",
      productType: productTypes.COMMERCIAL,
      minLives: 3,
      maxLives: isBroker ? 49 : 200,
      warningNumberLives: 49,
      numberOfLivesLabel: "Number of employees",
      numberOfLivesDesc: "Continue editing on the Employees stage",
      quoteInformations: {
        numberOfLives: true,
      },
      companyDetails: {
        name: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        tradingName: { visible: false, required: false, visibleForBroker: false },
        companyNumber: { visible: true, required: false, visibleForBroker: false },
        sicCode: { visible: true, required: false, visibleForBroker: false },
        sicSection: { visible: true, required: false, visibleForBroker: false },
        isStJamesPlace: { visible: true, required: false, visibleForBroker: false },
        stJamesPlacePracticeName: { visible: true, required: false, visibleForBroker: false },
        stJamesPlacePracticeId: { visible: true, required: false, visibleForBroker: false },
      },
      currentInsurance: {
        currentPremium: { visible: true, required: false },
        paymentInterval: { visible: true, required: false },
        planName: { visible: true, required: false },
        renewalDate: { visible: true, required: false },
        providerName: { visible: true, required: false },
      },
      importEmployeesApplication: {
        mandatoryFields: [
          {
            title: "First Name",
            field: "firstName",
            type: "string",
            isRequired: true,
          },
          {
            title: "Last Name",
            field: "lastName",
            type: "string",
            isRequired: true,
          },
          {
            title: "Relationship",
            field: "relationship",
            type: "string",
            isRequired: true,
          },
          { title: "Email", field: "email", type: "string", isRequired: true },
        ],
      },
      importEmployeesFields: {
        // not online invite import
        requiredFields: [
          {
            title: "First Name",
            field: "firstName",
            type: "string",
            isRequired: true,
          },
          {
            title: "Last Name",
            field: "lastName",
            type: "string",
            isRequired: true,
          },
          { title: "Title", field: "title", type: "string", isRequired: true },
          {
            title: "Date of birth",
            field: "dateOfBirth",
            type: "date",
            isRequired: true,
          },
          {
            title: "Relationship",
            field: "relationship",
            type: "string",
            isRequired: true,
          },
          {
            title: "Gender",
            field: "gender",
            type: "string",
            isRequired: true,
          },
          {
            title: "Address Line 1",
            field: "address1",
            type: "string",
            isRequired: true,
          },
          {
            title: "Address Line 2",
            field: "address2",
            type: "string",
            isRequired: true,
          },
          {
            title: "Postcode",
            field: "postcode",
            type: "string",
            isRequired: true,
          },
          {
            title: "Level of Cover",
            field: "levelOfCover",
            type: "string",
            isRequired: true,
          },
          {
            title: "Payment Percentage",
            field: "paymentPercentage",
            type: "number",
            isRequired: true,
          },
          {
            title: "Scans & Screens",
            field: "scansAndScreens",
            type: "string",
            isRequired: true,
          },
          {
            title: "Cancer drugs",
            field: "cancerDrugs",
            type: "string",
            isRequired: true,
          },
          {
            title: "Worldwide",
            field: "worldwide",
            type: "string",
            isRequired: true,
          },
          {
            title: "Cosmetic Surgery",
            field: "cosmeticSurgery",
            type: "string",
            isRequired: true,
          },
          {
            title: "Face to Face Counselling",
            field: "faceToFaceCounselling",
            type: "string",
            isRequired: true,
          },
          {
            title: "Allergy testing",
            field: "allergyTesting",
            type: "string",
            isRequired: true,
          },
          {
            title: "Dental",
            field: "dental",
            type: "string",
            isRequired: true,
          },
          {
            title: "Personal Accident",
            field: "personalAccident",
            type: "string",
            isRequired: true,
          },
        ],
        optionalFields: [
          { title: "Always Invite", field: "alwaysInvite", isRequired: false },
          { title: "Customer ID", field: "customerId", isRequired: false },
          {
            title: "Employee No",
            field: "employeeNo",
            type: "string",
            isRequired: false,
          },
          {
            title: "Middle Name",
            field: "middleName",
            type: "string",
            isRequired: false,
          },
          {
            title: "Join Date",
            field: "joinDate",
            type: "date",
            isRequired: false,
          },
          {
            title: "Leave Date",
            field: "leaveDate",
            type: "date",
            isRequired: false,
          },
          {
            title: "Address Line 3",
            field: "address3",
            type: "string",
            isRequired: false,
          },
          {
            title: "Address Line 4",
            field: "address4",
            type: "string",
            isRequired: false,
          },
          {
            title: "Email Personal",
            field: "emailPersonal",
            type: "string",
            isRequired: false,
          },
          {
            title: "Email Work",
            field: "emailWork",
            type: "string",
            isRequired: false,
          },
          {
            title: "Telephone Home",
            field: "telephoneHome",
            type: "string",
            isRequired: false,
          },
          {
            title: "Telephone Mobile",
            field: "telephoneMobile",
            type: "string",
            isRequired: false,
          },
          {
            title: "Telephone Work",
            field: "telephoneWork",
            type: "string",
            isRequired: false,
          },
          {
            title: "Underwriting Term",
            field: "underwritingTerm",
            type: "string",
            isRequired: false,
          },
          {
            title: "Smoker",
            field: "smoker",
            type: "string",
            isRequired: false,
          },
        ],
      },
      defaultStartDate: corporateDefaultStartDate,
      quoteOptionsOrder: ["Reimbursement Level", "Optional Extras - to enhance the benefits", "Cash Benefits", "Additional Benefits"],
    },
    859: {
      //NHS Top-Up
      productName: "NHS Top-Up",
      productType: productTypes.RETAIL,
      minLives: 1,
      maxLives: 7,
      allowImportEmployees: false,
      quoteInformations: {
        numberOfLives: false,
      },
      policyholderDetails: {
        firstName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        lastName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        title: { visible: true, required: false },
        sexAtBirth: { visible: true, required: false, visibleQuick: false },
        dateOfBirth: { visible: true, required: false },
        age: { visible: true, required: true },
        smokerVaper: { visible: true, required: true },
        email: { visible: false, required: false },
        isInsured: { visible: false, required: false },
        sjpPartnerInTheHousehold: { visible: false, required: false },
        telephoneNumbers: { visible: false, required: false },
        isStJamesPlace: { visible: false, required: false },
        stJamesPlacePracticeName: { visible: false, required: false },
        stJamesPlacePracticeId: { visible: false, required: false },
        isCostcoMember: { visible: !isBroker, required: !isBroker },
        membershipType: { visible: true, required: true },
        costcoMembershipNumber: { visible: true, required: false },
      },
      currentInsurance: {
        currentPremium: { visible: true, required: false },
        paymentInterval: { visible: true, required: false },
        planName: { visible: true, required: false },
        renewalDate: { visible: true, required: false },
        providerName: { visible: true, required: false },
      },
      employment: {
        visible: false,
        required: false,
      },
      defaultStartDate: today,
    },
    682: {
      //Providental
      productName: "Providental",
      productType: productTypes.RETAIL,
      minLives: 1,
      maxLives: 7,
      quoteInformations: {
        numberOfLives: false,
      },
      policyholderDetails: {
        firstName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        lastName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        title: { visible: true, required: false },
        sexAtBirth: { visible: true, required: false, visibleQuick: false },
        dateOfBirth: { visible: true, required: false },
        age: { visible: true, required: true },
        smokerVaper: { visible: false, required: false },
        email: { visible: false, required: false },
        isInsured: { visible: false, required: false },
        sjpPartnerInTheHousehold: { visible: false, required: false },
        telephoneNumbers: { visible: false, required: false },
        isStJamesPlace: { visible: false, required: false },
        stJamesPlacePracticeName: { visible: false, required: false },
        stJamesPlacePracticeId: { visible: false, required: false },
        isCostcoMember: { visible: !isBroker, required: !isBroker },
        membershipType: { visible: true, required: true },
        costcoMembershipNumber: { visible: true, required: false },
      },
      currentInsurance: {
        currentPremium: { visible: true, required: false },
        paymentInterval: { visible: true, required: false },
        planName: { visible: true, required: false },
        renewalDate: { visible: true, required: false },
        providerName: { visible: true, required: false },
      },
      employment: {
        visible: false,
        required: false,
      },
      defaultStartDate: today,
    },
    848: {
      //Flexible Health Essentials
      productName: "Flexible Health",
      productType: productTypes.RETAIL,
      minLives: 1,
      maxLives: 7,
      quoteInformations: {
        numberOfLives: false,
      },
      defaultLevelOfCoverExplanation:
        "If your quote is for more than one member, the level of cover/extras selected at this stage will default for all members. Should you wish to quote for different levels of cover, you may change this on the following page",
      policyholderDetails: {
        firstName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        lastName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        title: { visible: true, required: false },
        sexAtBirth: { visible: true, required: false, visibleQuick: false },
        dateOfBirth: { visible: true, required: false },
        age: { visible: true, required: true },
        smokerVaper: { visible: true, required: true },
        email: { visible: false, required: false },
        isInsured: { visible: false, required: false },
        sjpPartnerInTheHousehold: { visible: false, required: false },
        telephoneNumbers: { visible: false, required: false },
        isStJamesPlace: { visible: false, required: false },
        stJamesPlacePracticeName: { visible: false, required: false },
        stJamesPlacePracticeId: { visible: false, required: false },
        isCostcoMember: { visible: !isBroker, required: !isBroker },
        membershipType: { visible: true, required: true },
        costcoMembershipNumber: { visible: true, required: false },
      },
      currentInsurance: {
        currentPremium: { visible: true, required: false },
        paymentInterval: { visible: true, required: false },
        planName: { visible: true, required: false },
        renewalDate: { visible: true, required: false },
        providerName: { visible: true, required: false },
      },
      employment: {
        visible: true,
        required: true,
      },
      defaultStartDate: today,
    },
    847: {
      //Flexible Health Premier
      productName: "Flexible Health",
      productType: productTypes.RETAIL,
      minLives: 1,
      maxLives: 7,
      quoteInformations: {
        numberOfLives: false,
      },
      defaultLevelOfCoverExplanation:
        "If your quote is for more than one member, the level of cover/extras selected at this stage will default for all members. Should you wish to quote for different levels of cover, you may change this on the following page",
      policyholderDetails: {
        firstName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        lastName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        title: { visible: true, required: false },
        sexAtBirth: { visible: true, required: false, visibleQuick: false },
        dateOfBirth: { visible: true, required: false },
        age: { visible: true, required: true },
        smokerVaper: { visible: true, required: true },
        email: { visible: false, required: false },
        isInsured: { visible: false, required: false },
        sjpPartnerInTheHousehold: { visible: false, required: false },
        telephoneNumbers: { visible: false, required: false },
        isStJamesPlace: { visible: false, required: false },
        stJamesPlacePracticeName: { visible: false, required: false },
        stJamesPlacePracticeId: { visible: false, required: false },
        isCostcoMember: { visible: !isBroker, required: !isBroker },
        membershipType: { visible: true, required: true },
        costcoMembershipNumber: { visible: true, required: false },
      },
      currentInsurance: {
        currentPremium: { visible: true, required: false },
        paymentInterval: { visible: true, required: false },
        planName: { visible: true, required: false },
        renewalDate: { visible: true, required: false },
        providerName: { visible: true, required: false },
      },
      employment: {
        visible: true,
        required: true,
      },
      defaultStartDate: today,
    },
    844: {
      //Flexible Health Elite
      productName: "Flexible Health",
      productType: productTypes.RETAIL,
      minLives: 1,
      maxLives: 7,
      quoteInformations: {
        numberOfLives: false,
      },
      defaultLevelOfCoverExplanation:
        "If your quote is for more than one member, the level of cover/extras selected at this stage will default for all members. Should you wish to quote for different levels of cover, you may change this on the following page",
      policyholderDetails: {
        firstName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        lastName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        title: { visible: true, required: false },
        sexAtBirth: { visible: true, required: false, visibleQuick: false },
        dateOfBirth: { visible: true, required: false },
        age: { visible: true, required: true },
        smokerVaper: { visible: true, required: true },
        email: { visible: false, required: false },
        isInsured: { visible: false, required: false },
        sjpPartnerInTheHousehold: { visible: false, required: false },
        telephoneNumbers: { visible: false, required: false },
        isStJamesPlace: { visible: false, required: false },
        stJamesPlacePracticeName: { visible: false, required: false },
        stJamesPlacePracticeId: { visible: false, required: false },
        isCostcoMember: { visible: !isBroker, required: !isBroker },
        membershipType: { visible: true, required: true },
        costcoMembershipNumber: { visible: true, required: false },
      },
      currentInsurance: {
        currentPremium: { visible: true, required: false },
        paymentInterval: { visible: true, required: false },
        planName: { visible: true, required: false },
        renewalDate: { visible: true, required: false },
        providerName: { visible: true, required: false },
      },
      employment: {
        visible: true,
        required: true,
      },
      defaultStartDate: today,
    },
    957: {
      //Multi-Family
      productName: "Multi-Family",
      productType: productTypes.RETAIL,
      numberOfLivesLabel: "Number of households",
      numberOfLivesDesc: "Lives are edited on the Households stage",
      minLives: 2,
      maxLives: 7,
      maxHouseholds: 5,
      quoteInformations: {
        numberOfLives: true,
      },
      defaultLevelOfCoverExplanation:
        "If your quote is for more than one member, the level of cover/extras selected at this stage will default for all members. Should you wish to quote for different levels of cover, you may change this on the following page",
      policyholderDetails: {
        firstName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        lastName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        title: { visible: true, required: false },
        sexAtBirth: { visible: true, required: false, visibleQuick: false },
        dateOfBirth: { visible: true, required: false },
        age: { visible: true, required: true },
        telephoneNumbers: { visible: true, required: false, visibleQuick: false },
        email: { visible: true, required: false, visibleQuick: false },
        smokerVaper: { visible: false, required: false },
        isInsured: { visible: true, required: true },
        sjpPartnerInTheHousehold: { visible: false, required: false },
        isStJamesPlace: { visible: false, required: false },
        stJamesPlacePracticeName: { visible: false, required: false },
        stJamesPlacePracticeId: { visible: false, required: false },
        isCostcoMember: { visible: false, required: false },
      },
      currentInsurance: {
        currentPremium: { visible: true, required: false },
        paymentInterval: { visible: true, required: false },
        planName: { visible: true, required: false },
        renewalDate: { visible: true, required: false },
        providerName: { visible: true, required: false },
      },
      employment: {
        visible: true,
        visibleQuick: false,
        required: false,
      },
      defaultStartDate: today,
    },
    951: {
      //Family-Office
      productName: "Family-Office",
      productType: productTypes.RETAIL,
      numberOfLivesLabel: "Number of households",
      numberOfLivesDesc: "Lives are edited on the Households stage",
      minLives: 1,
      maxLives: 7,
      maxHouseholds: 5,
      quoteInformations: {
        numberOfLives: true,
      },
      defaultLevelOfCoverExplanation:
        "If your quote is for more than one member, the level of cover/extras selected at this stage will default for all members. Should you wish to quote for different levels of cover, you may change this on the following page",
      policyholderDetails: {
        firstName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        lastName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        title: { visible: true, required: false },
        sexAtBirth: { visible: true, required: false },
        dateOfBirth: { visible: true, required: false },
        age: { visible: true, required: true },
        telephoneNumbers: { visible: true, required: false },
        email: { visible: true, required: false },
        smokerVaper: { visible: false, required: false },
        isInsured: { visible: true, required: true },
        sjpPartnerInTheHousehold: { visible: false, required: false },
        isStJamesPlace: { visible: true, required: true },
        stJamesPlacePracticeName: { visible: true, required: false },
        stJamesPlacePracticeId: { visible: true, required: true },
        isCostcoMember: { visible: false, required: false },
      },
      currentInsurance: {
        currentPremium: { visible: true, required: false },
        paymentInterval: { visible: true, required: false },
        planName: { visible: true, required: false },
        renewalDate: { visible: true, required: false },
        providerName: { visible: true, required: false },
      },
      employment: {
        visible: true,
        required: false,
      },
      defaultStartDate: today,
    },
    1009: {
      //Precision Corporate Healthcare
      productName: "Precision Corporate Healthcare",
      productType: productTypes.COMMERCIAL,
    },
    1072: {
      //Complete Health
      productName: "Complete Health",
      productType: productTypes.RETAIL,
      minLives: 1,
      maxLives: 7,
      quoteInformations: {
        numberOfLives: false,
      },
      defaultLevelOfCoverExplanation:
        "The premium varies depending on the information that you have provided (such as your ages and postcode). You are in control: You can customise the options for each family member on the next step.",
      policyholderDetails: {
        firstName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        lastName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        title: { visible: true, required: false },
        sexAtBirth: { visible: true, required: false, visibleQuick: false },
        dateOfBirth: { visible: true, required: false },
        age: { visible: true, required: true },
        smokerVaper: { visible: true, required: true },
        email: { visible: false, required: false },
        isInsured: { visible: false, required: false },
        sjpPartnerInTheHousehold: { visible: false, required: false },
        telephoneNumbers: { visible: false, required: false },
        isStJamesPlace: { visible: false, required: false },
        stJamesPlacePracticeName: { visible: false, required: false },
        stJamesPlacePracticeId: { visible: false, required: false },
        isCostcoMember: { visible: false, required: false },
      },
      currentInsurance: {
        currentPremium: { visible: true, required: false },
        paymentInterval: { visible: true, required: false },
        planName: { visible: true, required: false },
        renewalDate: { visible: true, required: false },
        providerName: { visible: true, required: false },
      },
      employment: {
        visible: false,
        required: false,
      },
      defaultStartDate: today,
    },
    "1072_Individual": {
      //Complete Health
      productName: "Complete Health",
      productType: productTypes.RETAIL,
      minLives: 1,
      maxLives: 7,
      quoteInformations: {
        numberOfLives: false,
      },
      defaultLevelOfCoverExplanation:
        "The premium varies depending on the information that you have provided (such as your ages and postcode). You are in control: You can customise the options for each family member on the next step.",
      policyholderDetails: {
        firstName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        lastName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        title: { visible: true, required: false },
        sexAtBirth: { visible: true, required: false, visibleQuick: false },
        dateOfBirth: { visible: true, required: false },
        age: { visible: true, required: true },
        smokerVaper: { visible: true, required: true },
        email: { visible: false, required: false },
        isInsured: { visible: false, required: false },
        sjpPartnerInTheHousehold: { visible: false, required: false },
        telephoneNumbers: { visible: false, required: false },
        isStJamesPlace: { visible: true, required: true },
        stJamesPlacePracticeName: { visible: false, required: false },
        stJamesPlacePracticeId: { visible: false, required: false },
        isCostcoMember: { visible: false, required: false },
      },
      currentInsurance: {
        currentPremium: { visible: true, required: false },
        paymentInterval: { visible: true, required: false },
        planName: { visible: true, required: false },
        renewalDate: { visible: true, required: false },
        providerName: { visible: true, required: false },
      },
      employment: {
        visible: false,
        required: false,
      },
      defaultStartDate: today,
    },
    "1072_FamilyGroup": {
      //Complete Health
      productName: "WPA Family Office",
      productType: productTypes.RETAIL,
      numberOfLivesLabel: "Number of households",
      numberOfLivesDesc: "Lives are edited on the Households stage",
      minLives: 1,
      maxLives: 7,
      maxHouseholds: 5,
      quoteInformations: {
        numberOfLives: true,
      },
      defaultLevelOfCoverExplanation:
        "The premium varies depending on the information that you have provided (such as your ages and postcode). You are in control: You can customise the options for each family member on the next step.",
      policyholderDetails: {
        firstName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        lastName: { visible: true, required: true, requiredQuick: false, requiredForBroker: true },
        title: { visible: true, required: false },
        sexAtBirth: { visible: true, required: false, visibleQuick: false },
        dateOfBirth: { visible: true, required: false },
        age: { visible: true, required: true },
        smokerVaper: { visible: true, required: true },
        email: { visible: false, required: false },
        isInsured: { visible: true, required: true },
        sjpPartnerInTheHousehold: { visible: true, required: true },
        telephoneNumbers: { visible: false, required: false },
        isStJamesPlace: { visible: true, required: false },
        stJamesPlacePracticeName: { visible: true, required: false },
        stJamesPlacePracticeId: { visible: true, required: true },
        isCostcoMember: { visible: false, required: false },
      },
      currentInsurance: {
        currentPremium: { visible: true, required: false },
        paymentInterval: { visible: true, required: false },
        planName: { visible: true, required: false },
        renewalDate: { visible: true, required: false },
        providerName: { visible: true, required: false },
      },
      employment: {
        visible: false,
        required: false,
      },
      defaultStartDate: today,
    },
  },
  quoteDetails: {
    quoteId: 0,
    quoteTitle: null,
    startDateOfPolicy: null,
    numberOfLives: null,
    isInEdit: false,
    isInEditQuote: false,
    previousEmployeeNumber:null,
    households: [],
    canEditNumberOfLives: true,
    isSharedResponsibilityOverExcess: true,
    isVisibleActionGroup: true,
    isPlanBenefitGroupVisible: true,
  },
  quoteData: null,
  excelEmployeesFile: null,
  excelParsingDetails: null,
  excelEmployees: null,
  importSucceeded: false,
  importedEmployees: null,
  groupContactPending: false,
  leadMembers: [],
  renewalQuote: null,
  defaultOptions: {
    productId: null,
    productCoverLevel: null,
    options: null,
    productCoverLevelEmployee: null,
    productCoverLevelAdult: null,
    productCoverLevelChild: null,
  },
  dirty: true,
  repricingRequired: true,
  loadedSteps: [],
};

export const getters = {
  areRequiredFieldsFilled: (state) => state.areRequiredFieldsFilled,
  quoteAllProducts: (state) => state.allProducts.filter((p) => !p.hidden),
  selectedProductId: (state) => (!!state.selectedProduct ? state.selectedProduct.productId : null),
  customerData: (state) => state.customerData,
  quoteDetails: (state) => state.quoteDetails,
  quoteData: (state) => state.quoteData,
  households: (state) => state.quoteDetails.households,
  householdPrices: (state) => (state.quoteData ? state.quoteData.householdPrices : null),
  householdOptions: (state) => (state.quoteData ? state.quoteData.householdOptions : null),
  quoteConfig: (state) => state.quoteConfig,
  excelEmployeesFile: (state) => state.excelEmployeesFile,
  excelParsingDetails: (state) => state.excelParsingDetails,
  excelEmployees: (state) => state.excelEmployees,
  importSucceeded: (state) => state.importSucceeded,
  excelWpaRequiredFields: (state) => {
    const product = state.quoteConfig[state.selectedProduct.productId];
    if (state.isImportApplication) return product.importEmployeesApplication?.mandatoryFields;
    else return product.importEmployees?.mandatoryFields;
  },
  excelProductOptionalFields: (state) => {
    const product = state.quoteConfig[state.selectedProduct.productId];
    if (state.isImportApplication) return product.importEmployeesApplication?.optionalFields;
    else return product.importEmployees?.optionalFields;
  },
  importedEmployees: (state) => state.importedEmployees,
  importEmployeesFields: (state) => {
    const product = state.quoteConfig[state.selectedProduct.productId];
    return product.importEmployeesFields;
  },
  employeesAlreadyImportedToView: (state) => state.quoteConfig.employeesAlreadyImportedToView,
  leadCustomer: (state) => state.leadCustomer,
  groupContactPending: (state) => state.groupContactPending,
  leadMembers: (state) => state.leadMembers,
  quoteHeaderVisible: (state) => state.quoteConfig.quoteHeaderVisible,
  allowImportEmployees: (state) => state.quoteConfig[state.selectedProduct.productId].allowImportEmployees,
  policyHolderAddedAsSubscriber: (state) => state.quoteConfig.policyHolderAddedAsSubscriber,
  lastSave: (state) => state.quoteConfig.lastSave,
  isLead: (state) => (state.leadCustomer?.leadId !== null ? true : false),
  privateLivesCount: (state) => {
    let count = 0;
    let selectedProductId = state.selectedProduct?.productId;
    if (selectedProductId == 957 || selectedProductId == 951 || selectedProductId == 1072) {
      if (state.quoteDetails.households && state.quoteDetails.households.length > 0) {
        count += state.quoteDetails.households.length;
        state.quoteDetails.households.forEach((hh) => (count += hh.dependants?.length || 0));
      }
    } else {
      count += 1;
      state.quoteDetails.households.forEach((hh) => (count += hh.members?.length || 0));
    }
    return count;
  },
  corporateLivesCount: (state) => {
    let count = 0;
    count += state.quoteDetails.households.length || 0;
    return count;
  },
  currentQuoteConfig: (state) => (state.selectedProduct ? state.quoteConfig[state.selectedProduct.productId] : null),
  defaultOptions: (state) => state.defaultOptions,
  loadedSteps: (state) => state.loadedSteps,
  isExistingQuote: (state) => !!state.quoteDetails?.quoteId,
  hideQuotePrice: (state, getters) => state.selectedProduct?.productId == 969 && !getters.isExistingQuote,
  saveQuoteErrors: (state, getters) => {
    const errors: Array<string> = [];
    if (state.selectedProduct?.productId == 969) {
      const count = getters.corporateLivesCount;
      const meanAge = mean(state.quoteDetails.households.map((h) => parseInt(h.subscriber?.age)).filter((x) => Number.isInteger(x)));
      if (Math.abs(1 - count / state.masterQuote.subscribersCount) > ehCountDifference && state.masterQuote.subscribersCount) {
        errors.push(`Can't save quote with employee count different by more than ${ehCountDifference * 100} % of master quote`);
      }
      if (Math.abs(1 - meanAge / state.masterQuote.subscribersMeanAge) > ehAgeDifference && state.masterQuote.subscribersCount) {
        errors.push(`Can't save quote with employee avarage age different by more than ${ehAgeDifference * 100} % of master quote`);
      }
    }
    return [...errors, ...state.quoteErrors];
  },
};
export const mutations = {
  SET_QUOTE(state, { quote, leadCustomer, masterQuote }) {
    if (!masterQuote) masterQuote = quote;
    state.quoteDetails.customerId = quote.customerId;
    state.quoteDetails.contractId = quote.contractId;
    state.quoteDetails.quoteId = quote.id;
    state.quoteDetails.quoteTitle = quote.quoteTitle;
    state.quoteDetails.startDateOfPolicy = quote.startDateOfPolicy;
    state.quoteDetails.expirationDate = quote.expirationDate;
    state.quoteDetails.numberOfLives = quote.households.length;
    state.quoteDetails.canEditNumberOfLives = false;
    state.quoteDetails.isInEditQuote = true;
    state.quoteDetails.isProfessional = leadCustomer.policyHolder.professional;
    state.quoteDetails.selfEmployed = leadCustomer.policyHolder.selfEmployed === "Y";
    state.quoteDetails.priceGrossMonthly = quote.priceGrossMonthly;
    state.quoteDetails.proceGrossYearly = quote.proceGrossYearly;
    state.quoteDetails.priceNetPremium = quote.priceNetPremium;
    state.quoteDetails.priceGrossPremium = quote.priceGrossPremium;
    state.quoteDetails.applicationId = quote.applicationId;
    state.quoteDetails.priceGrossMonthlyFull = quote.priceGrossMonthlyFull;
    state.quoteDetails.priceGrossYearlyFull = quote.priceGrossYearlyFull;
    state.quoteDetails.households = quote.households.map((h) => {
      const members = getHouseholdMembers(h, quote.productId, quote.startDateOfPolicy, quote.quoteType);
      const householdCoverLevel = getHouseholdCoverLevel(h, quote.productId);
      return {
        householdId: h.householdId,
        coverLevel: h.subscriber ? h.subscriber.productCoverLevel : h.familyMembers[0].productCoverLevel,
        coverLevelDesc: householdCoverLevel,
        familyType: getFamilyTypeFromQuoteHousehold(h),
        isValid: true,
        name: h.name,
        postCode: h.postcode,
        promotionSubtype: h.promotionSubtype,
        isHouseholdSharedResponsibilityOverExcess: isSharedResponsabilityOverExcess(h.subscriber),
        subscriber: h.subscriber
          ? {
              customerId: h.subscriber.customerId,
              contractId: h.subscriber.contractId,
              age: h.subscriber.dateOfBirth != undefined ? calculateAge(quote.startDateOfPolicy, h.subscriber.dateOfBirth) : h.subscriber.age,
              dateOfBirth: h.subscriber.dateOfBirth,
              title: h.subscriber.title,
              firstName: h.subscriber.memberFirstName,
              surname: h.subscriber.memberSurname,
              relationship: h.subscriber.relationship,
              leadDependantId: h.subscriber.id,
              options: h.subscriber.options,
              productCoverLevel: h.subscriber.productCoverLevel,
            }
          : null,
        tmpGuid: guid(),
        ...members,
        sjpPartnerInTheHousehold: h.sjpPartnerInTheHousehold,
      };
    });
    
    state.quoteConfig.policyHolderAddedAsSubscriber = leadCustomer.policyHolder.isInsured;
    if (masterQuote) {
      state.masterQuote.subscribersCount = masterQuote.households.length;
      state.masterQuote.subscribersMeanAge = mean(masterQuote.households.map((h) => parseInt(h.subscriber?.age)).filter((x) => Number.isInteger(x)));
      state.masterQuote.quoteId = masterQuote.id;
    }
    state.dirty = false;
    state.repricingRequired = false;
    state.quoteDetails.isSharedResponsibilityOverExcess = state.quoteDetails.households.every(hh => hh.isHouseholdSharedResponsibilityOverExcess == false) ? false : true;
    if (quote.productId == 1072 && quote.isStJamesPlace == true && quote.isFamilyGroup == false && state.quoteDetails.households.length == 1) {
      // we need this set only for the WPA FO individual flow (one family with PH included)
      state.quoteDetails.sjpPartnerInTheHousehold = state.quoteDetails.households[0].sjpPartnerInTheHousehold;
    }
  },
  SET_RENEWAL_QUOTE(state, renewalQuote) {
    state.quoteDetails.customerId = renewalQuote.customerId;
    state.quoteDetails.contractId = renewalQuote.contractId;
    state.quoteDetails.quoteId = renewalQuote.id;
    state.quoteDetails.quoteTitle = renewalQuote.quoteTitle;
    state.quoteDetails.startDateOfPolicy = renewalQuote.startDateOfPolicy;
    state.quoteDetails.expirationDate = renewalQuote.expirationDate;
    state.quoteDetails.numberOfLives = renewalQuote.households.length;
    state.quoteDetails.canEditNumberOfLives = false;
    state.quoteDetails.isProfessional = renewalQuote.isProfessional;
    state.quoteDetails.selfEmployed = renewalQuote.isSelfEmployed;
    state.quoteDetails.priceGrossMonthly = renewalQuote.priceGrossMonthly;
    state.quoteDetails.proceGrossYearly = renewalQuote.proceGrossYearly;
    state.quoteDetails.priceNetPremium = renewalQuote.priceNetPremium;
    state.quoteDetails.priceGrossPremium = renewalQuote.priceGrossPremium;
    state.quoteDetails.applicationId = renewalQuote.applicationId;
    state.quoteDetails.priceGrossMonthlyFull = renewalQuote.priceGrossMonthlyFull;
    state.quoteDetails.priceGrossYearlyFull = renewalQuote.priceGrossYearlyFull;
    state.quoteDetails.sourceContractId = renewalQuote.renewalContractId;
    state.quoteDetails.options = renewalQuote.options;

    state.quoteDetails.households = renewalQuote.households.map((h) => {
      let tmpGuidGeneral = h.tmpGuid === undefined ? guid() : h.tmpGuid;
      const members = getHouseholdMembers(h, renewalQuote.productId, renewalQuote.startDateOfPolicy, renewalQuote.quoteType);

      return {
        householdId: h.householdId,
        coverLevel: h.subscriber ? h.subscriber.productCoverLevel : h.familyMembers[0].productCoverLevel,
        familyType: getFamilyType(h),
        isValid: true,
        name: h.name,
        postCode: h.postcode,
        promotionSubtype: h.promotionSubtype,
        subscriber: h.subscriber
          ? {
              customerId: h.subscriber.customerId,
              contractId: h.subscriber.contractId,
              age: h.subscriber.age,
              dateOfBirth: h.subscriber.dateOfBirth,
              firstName: h.subscriber.memberFirstName,
              surname: h.subscriber.memberSurname,
              leadDependantId: h.subscriber.id,
            }
          : null,
        tmpGuid: tmpGuidGeneral,
        ...members,
      };
    });

    state.dirty = false;
    state.repricingRequired = false;
    state.renewalQuote = renewalQuote;
  },
  SET_DIRTY(state, value) {
    state.dirty = value;
  },
  SET_COVER_LEVEL_ON_MEMBER(state, payload) {
    if (payload.memberCoverData.member == 0) {
      state.quoteDetails.households[payload.memberCoverData.household].subscriber.productCoverLevel = payload.memberCoverData.coverLevel;
    } else {
      state.quoteDetails.households[payload.memberCoverData.household].dependants[payload.memberCoverData.member - 1].productCoverLevel =
        payload.memberCoverData.coverLevel;
    }
  },
  SET_QUOTE_ID(state, quoteId) {
    
    state.quoteDetails.quoteId = quoteId;
  },
  SET_IS_QUOTE_IN_EDIT(state, isInEdit) {
    state.quoteDetails.isInEdit = isInEdit;
  },

  SET_QUICK_QUOTE_PAYLOAD(state, payload) {
    state.quickQuotePayload = payload;
  },
  SET_EMPTY_LEAD(state) {
    state.selectedProduct = null;
    state.leadCustomer = getEmptyLead();
  },
  SET_HOUSEHOLD_DISCOUNT(state, { householdIdx, discountDetails }) {
    state.quoteData.householdPrices[householdIdx].discountDetails = discountDetails;
  },
  SET_HOUSEHOLD_SUBTYPE(state, { householdIdx, promotionSubtype }) {
    state.quoteDetails.households[householdIdx].promotionSubtype = promotionSubtype;
  },
  SET_REPRICING_REQUIRED(state, value) {
    state.repricingRequired = value;
  },
  SELECT_PRODUCT(state, productId) {
    if (productId == 0) {
      state.selectedProduct = null;
    } else {
      let selectedProduct = state.allProducts.find((product) => product.productId === productId);

      if (!!selectedProduct) {
        state.selectedProduct = selectedProduct;
      }
    }
  },
  SET_PRODUCTS(state, products) {
    state.allProducts = products;

    if (products.find((product) => product.productId === 1072))
    {
        const completeHealthProductNameAlias = products.find((product) => product.productId === 1072).name;
        setCompleteHealthProductName(state, completeHealthProductNameAlias);  
    }
  },
  ////QUOTE
  SET_QUOTE_DETAILS(state, quoteDetails) {
    refreshAge(state, quoteDetails);
    state.quoteDetails = quoteDetails;
  },
  SET_QUOTE_HOUSEHOLDS(state, households) {
    state.quoteDetails.households = households;
  },
  ADD_QUOTE_HOUSEHOLD(state, household) {
    state.quoteDetails.households.push(household);
  },
  SELECT_MIN_QUOTE_LIVES(state) {
    state.quoteDetails.numberOfLives = state.quoteConfig[state.selectedProduct.productId].minLives;
  },
  //EXCEL FILE
  SET_EXCEL_FILE(state, file) {
    state.excelEmployeesFile = file;
  },
  REMOVE_EXCEL_FILE(state) {
    state.excelEmployeesFile = null;
  },
  SET_EXCEL_PARSING_DETAILS(state, parsingDetails) {
    state.excelParsingDetails = parsingDetails;
  },
  REMOVE_EXCEL_PARSING_DETAILS(state) {
    state.excelParsingDetails = null;
  },
  SET_EXCEL_EMPLOYEES(state, excelEmployeesJSON) {
    state.excelEmployees = excelEmployeesJSON;
  },
  REMOVE_EXCEL_EMPLOYEES(state) {
    state.excelEmployees = null;
  },
  SET_IMPORTED_EMPLOYEES(state, importedEmployees) {
    state.importedEmployees = importedEmployees;
  },
  REMOVE_IMPORTED_EMPLOYEES(state) {
    state.importedEmployees = null;
  },
  //IMPORTING EXCEL EMPLOYEES TO EMPLOYEES QUOTE VIEW
  SET_EMPLOYEES_IMPORTED_TO_VIEW(state, imported) {
    state.quoteConfig.employeesAlreadyImportedToView = imported;
  },
  SET_IMPORT_STATUS(state, succeeded) {
    state.importSucceeded = succeeded;
  },
  CLEAR_EXCEL_IMPORT_DATA(state) {
    state.excelEmployees = null;
    state.importedEmployees = null;
    state.excelEmployeesFile = null;
    state.excelParsingDetails = null;
    state.quoteConfig.employeesAlreadyImportedToView = false;
  },
  //FAMILY MEMBERS
  ADD_FAMILY_MEMBER(state, member) {
    state.quoteDetails.households[0].members.push(member);
  },
  ADD_QUICK_QUOTE_UPDATE(state, { id, update }) {
    if (["firstName", "lastName", "name"].includes(id) && state.selectedProduct.productId != 957) return;
    Vue.set(state.leadUpdateData, id, update);
  },
  SAVE_FAMILY_MEMBER(state, { familyMember, index }) {
    state.quoteDetails.households[0].members.splice(index, 1, familyMember);
  },
  DELETE_FAMILY_MEMBERS(state, membersIndexes) {
    const members = state.quoteDetails.households[0].members.filter((x, i) => !membersIndexes.includes(i));
    state.quoteDetails.households[0].members = members;
    if (!state.quoteData) return;
    membersIndexes = membersIndexes.map((x) => ++x);
    state.quoteData.householdOptions[0].memberOptions = state.quoteData.householdOptions[0].memberOptions.filter(
      (x, i) => !membersIndexes.includes(i)
    );
    state.quoteData.householdPrices[0].memberPrices = state.quoteData.householdPrices[0].memberPrices.filter((x, i) => !membersIndexes.includes(i));
  },
  //LEAD CUSTOMER
  SET_LEAD_CUSTOMER(state, leadCustomer) {
    let selfEmployed = leadCustomer.policyHolder.selfEmployed;
    if (selfEmployed !== null) {
      selfEmployed = selfEmployed === "Y";
    }
    leadCustomer.policyHolder.selfEmployed = selfEmployed;
    if (leadCustomer.policyHolder.preferredTelephoneNumber == "Main") {
      leadCustomer.policyHolder.preferredTelephoneNumber = null;
    }
    leadCustomer.policyHolder.telephoneNumbers = leadCustomer.policyHolder?.telephoneNumbers?.filter((x) => x.number);
    leadCustomer.policyHolder.telephoneNumbers?.forEach((x) => (x.isValid = true));
    if (leadCustomer.policyHolder.telephoneNumbers.length < 1) {
      leadCustomer.policyHolder.telephoneNumbers.push({
        number: "",
        phoneType: null,
      });
    }
    state.leadCustomer = leadCustomer;
  },
  SET_POLICYHOLDER_PROP(state, { prop, value }) {
    state.leadCustomer.policyHolder[prop] = value;
  },
  SET_LEADCUSTOMER_ADDRESS(state, address) {
    state.leadCustomer.address = address;
  },
  SET_LEADCUSTOMER_GP(state, gp) {
    state.leadCustomer.policyHolder.gpTypeId = gp.gpType;
    state.leadCustomer.policyHolder.gpPracticeName = gp.gpPracticeName;
    state.leadCustomer.policyHolder.gpPracticeId = gp.gpPracticeId;
    state.leadCustomer.policyHolder.gpNotFound = gp.gpNotFound;
  },
  SET_LEADCUSTOMER_TRADING_ADDRESS(state, tradingAddress) {
    state.leadCustomer.tradingAddress = tradingAddress;
  },
  SET_CURRENT_INSURANCE_PROP(state, { prop, value }) {
    state.leadCustomer.currentInsurance[prop] = value;
  },
  SET_CURRENT_INSURANCE_REQUIREMENT(state, bool) {
    const productId = state.selectedProduct.productId;
    const currentInsuranceKeysArray = Object.keys(state.quoteConfig[productId].currentInsurance);
    currentInsuranceKeysArray.forEach((field) => {
      state.quoteConfig[productId].currentInsurance[field].required = bool;
    });
  },
  SET_COMPANY_DETAILS_PROP(state, { prop, value }) {
    state.leadCustomer.companyDetails[prop] = value;
  },
  //LEAD CUSTOMER GROUP CONTACTS
  SET_LEAD_CUSTOMER_GROUP_CONTACTS(state, groupContacts) {
    state.leadCustomer.contactDetails = groupContacts;
  },
  ADD_LEAD_CUSTOMER_GROUP_CONTACTS(state, groupContact) {
    state.leadCustomer.contactDetails.push(groupContact);
  },
  REMOVE_LEAD_CUSTOMER_GROUP_CONTACTS(state, groupContactId) {
    let index = state.leadCustomer.contactDetails.findIndex((x) => x.leadGroupContactId === groupContactId);
    state.leadCustomer.contactDetails.splice(index, 1);
  },
  SET_LEAD_CUSTOMER_GROUP_CONTACT_PENDING(state, isPending) {
    state.groupContactPending = isPending;
  },
  SET_LEAD_MEMBERS(state, leadMembers) {
    state.leadMembers = leadMembers;
  },
  SET_IS_IMPORT_APPLICATION(state, value) {
    state.isImportApplication = value;
  },
  //QUOTE HEADER VISIBILITY
  SET_QUOTE_HEADER_VISIBILITY(state, visible) {
    state.quoteConfig.quoteHeaderVisible = visible;
  },
  CLEAR_QUOTE_STATE(state) {
    while (state.loadedSteps.length > 0) {
      state.loadedSteps.pop();
    }
    state.quoteDetails.quoteId = 0;
    state.quoteDetails.quoteTitle = null;
    state.quoteDetails.startDateOfPolicy = null;
    state.quoteDetails.numberOfLives = null;
    state.quoteDetails.households = [];
    state.quoteDetails.canEditNumberOfLives = true;
    state.quoteDetails.isSharedResponsibilityOverExcess = false;
    if (state.quoteDetails.sjpPartnerInTheHousehold != undefined) {
      state.quoteDetails.sjpPartnerInTheHousehold = null;
    }
    state.leadUpdateData = {};
    (state.masterQuote = {
      subscribersCount: null,
      subscribersMeanAge: null,
      quoteId: null,
    }),
      (state.defaultOptions = {
        productId: null,
        coverLevel: null,
        options: null,
        productCoverLevelEmployee: null,
        productCoverLevelAdult: null,
        productCoverLevelChild: null,
      });

    state.quoteConfig.employeesAlreadyImportedToView = false;
    state.quoteConfig.policyHolderAddedAsSubscriber = false;
    state.quoteData = null;
    state.quote;
  },
  //ADDING POLICYHOLDER TO QUOTE (FAMILY OFFICE)
  SET_POLICYHOLDER_ADDED_AS_SUBSCRIBER(state, added) {
    state.quoteConfig.policyHolderAddedAsSubscriber = added;
  },
  //LAST SAVE
  SET_LAST_SAVE_TIME(state, lastSave) {
    state.quoteConfig.lastSave = lastSave;
  },
  SET_QUOTE_DATA(state, payload) {
    state.quoteData = payload;
  },
  SET_QUOTE_OPTIONS_FOR_MEMBER(state, { householdId, memberId, productId, productCoverLevel, payload, selectedCoverLevel, selectedProductId }) {
    
    const options = payload.map((o) => ({
      optionId: o.optionId,
      value: o.value,
    }));
    switch (productId) {
      // single household (except for Complete Health with SJP)
      case 848:
      case 844:
      case 847:
      case 859:
      case 682:
      case 1072:
        if (selectedCoverLevel != productCoverLevel || selectedProductId != productId) break;
        if (memberId == 0) {
          if (!state.quoteDetails.households[householdId].subscriber) {
            Vue.set(state.quoteDetails.households[householdId], "subscriber", {
              options,
            }); 
          } else {
            Vue.set(state.quoteDetails.households[householdId].subscriber, "options", options);
          }
        } else {
          if((state.leadCustomer.policyHolder.isInsured == false || state.quoteDetails.households.length > 1)){
            Vue.set(state.quoteDetails.households[householdId].dependants[memberId - 1], "options", options);
          }
          else{
            Vue.set(state.quoteDetails.households[householdId].members[memberId - 1], "options", options);
          }
        }
        break;
      // multi family / family office
      case 957:
      case 951:
        const householdFamily = state.quoteDetails.households[householdId];
        if (householdFamily.coverLevel != productCoverLevel) break;
        if (memberId == 0) {
          Vue.set(householdFamily.subscriber, "options", options);
        } else {
          Vue.set(householdFamily.dependants[memberId - 1], "options", options);
        }
        break;

      // corporate
      default:
        const household = state.quoteDetails.households[householdId];
        const members = [household.subscriber, ...household.members, ...household.childMembers];
        let memberCoverLevel =
          memberId == 0
            ? household.productCoverLevelEmployee
            : memberId <= household.members.length
            ? household.productCoverLevelAdult
            : household.productCoverLevelChild;

        if (productCoverLevel == memberCoverLevel && members.length > memberId) Vue.set(members[memberId], "options", options);
    }

    state.quoteData.householdOptions[householdId].memberOptions[memberId].coverOptions = [
      ...state.quoteData.householdOptions[householdId].memberOptions[memberId].coverOptions.filter(
        (x) => x.productId !== productId || x.coverLevel !== productCoverLevel
      ),
      ...payload,
    ];
  },
  SET_QUOTE_PRICES_FOR_MEMBER(state, { householdId, memberId, productId, productCoverLevel, payload }) {
    let memberPrice = state.quoteData.householdPrices[householdId].memberPrices[memberId].coverPrices.find(
      (c) => c.productId === productId && c.coverLevel === productCoverLevel
    );
    memberPrice.priceGrossMonthly = payload.priceGrossMonthly;
    memberPrice.priceGrossMonthlyFull = payload.priceGrossMonthlyFull;
    memberPrice.priceGrossPremium = payload.priceGrossPremium;
    memberPrice.priceGrossYearly = payload.priceGrossYearly;
    memberPrice.priceGrossYearlyFull = payload.priceGrossYearlyFull;
    memberPrice.priceNetPremium = payload.priceNetPremium;
  },
  SET_QUOTE_COVER_LEVEL_FOR_HOUSEHOLD(state, { householdId, productCoverLevel }) {
    let household = state.quoteDetails.households[householdId];
    household.coverLevel = productCoverLevel;
    household.coverLevelDesc = productCoverLevel;
  },
  SET_QUOTE_COVER_LEVEL_FOR_HOUSEHOLD_CORPORATE(state, { householdId, productCoverLevelEmployee, productCoverLevelAdult, productCoverLevelChild }) {
    let household = state.quoteDetails.households[householdId];

    household.productCoverLevelEmployee = productCoverLevelEmployee;
    household.productCoverLevelAdult = productCoverLevelAdult;
    household.productCoverLevelChild = productCoverLevelChild;
  },
  SETUP_CORPORATE_COVER_LEVEL(state, defaultCoverLevel) {
    
    for (const household of state.quoteDetails.households) {
      const householdCoverLevel = household.coverLevel;
      const getCoverLevel = (obj) => {
        const option = obj?.options?.find((opt) => opt.optionId == 17);
        return option?.value ?? householdCoverLevel ?? defaultCoverLevel;
      };
      household.productCoverLevelEmployee = getCoverLevel(household.subscriber);
      household.productCoverLevelAdult = getCoverLevel(household.members[0]);
      household.productCoverLevelChild = getCoverLevel(household.childMembers[0]);
    }
  },
  SET_QUOTE_DEFAULT_OPTIONS(state, { productId, productCoverLevel, options }) {
    state.defaultOptions.productId = productId;
    state.defaultOptions.productCoverLevel = productCoverLevel;
    if (options) {
      state.defaultOptions.options = options;
    }
  },
  SET_QUOTE_CORPORATE_DEFAULT_OPTIONS(state, { productId, productCoverLevelEmployee, productCoverLevelAdult, productCoverLevelChild, options }) {
    state.defaultOptions.productId = productId;
    state.defaultOptions.productCoverLevelEmployee = productCoverLevelEmployee;
    state.defaultOptions.productCoverLevelAdult = productCoverLevelAdult;
    state.defaultOptions.productCoverLevelChild = productCoverLevelChild;
    state.defaultOptions.employeeOptions = options.employee;
    state.defaultOptions.adultDependantOptions = options.adultDependants;
    state.defaultOptions.childDependantOptions = options.childDependants;
  },
  SET_LOADED_STEP(state, stepId) {
    if (state.loadedSteps.includes(stepId)) return;
    state.loadedSteps.push(stepId);
  },
  SET_TOTAL_PRICE(state, { priceGrossMonthly, priceGrossMonthlyFull, priceGrossPremium, priceGrossYearly, priceGrossYearlyFull, priceNetPremium }) {
    state.totalPrice = {
      priceGrossMonthly,
      priceGrossMonthlyFull,
      priceGrossPremium,
      priceGrossYearly,
      priceGrossYearlyFull,
      priceNetPremium,
    };
  },
  SET_QUOTE_ERRORS(state, quoteErrors) {
    state.quoteErrors = quoteErrors;
  },
  DELETE_QUOTE_DATA_HOUSEHOLDS(state, payload) {
    if (state.quoteData?.householdOptions) {
      state.quoteData.householdOptions = state.quoteData.householdOptions.filter((x, i) => !payload.includes(i));
    }
  },
  CLEAR_QUOTE_DATA_HOUSEHOLD(state, index) {
    if (state.quoteData?.householdOptions?.[index]) {
      state.quoteData.householdOptions[index] = null;
    }
  },
  SET_FIELDS_VALIDATION(state, isValid) {
    state.areRequiredFieldsFilled = isValid;
  },
  SET_QUOTE_TITLE(state, title) {
    state.quoteDetails.quoteTitle = title;
  },
  SET_ACTION_GROUP_VISIBILITY(state, payload) {
    state.quoteDetails.isVisibleActionGroup = payload;
  },
  SET_PLAN_BENEFIT_GROUP_VISIBILITY(state, payload) {
    state.quoteDetails.isPlanBenefitGroupVisible = payload;
  },
  async SET_COMPLETE_HEALTH_VERSION_IN_QUOTE_CONFIG(state, isSJP) {
    if (isSJP) {
      state.quoteConfig[1072] = state.quoteConfig["1072_FamilyGroup"];
    } else {
      state.quoteConfig[1072] = state.quoteConfig["1072_Individual"];
    }

    const completeHealthProductNameAlias = await getProductName(1072, isSJP);
    setCompleteHealthProductName(state, completeHealthProductNameAlias);    
  },
  SET_QUOTE_CONFIG_POLICYHOLDER_DETAILS(state, payload) {    
    state.quoteConfig[state.selectedProduct.productId].policyholderDetails[payload.field].required = payload.value;
    state.quoteConfig[state.selectedProduct.productId].policyholderDetails[payload.field].visible = payload.value;
  }
};
export const actions = {
  addQuickQuoteUpdate({ commit }, { id, update }) {
    commit("ADD_QUICK_QUOTE_UPDATE", { id, update });
  },
  setLoadedQuote({ commit }, payload) {
    commit("SELECT_PRODUCT", payload.quote.productId);
    commit("SET_LEAD_CUSTOMER", payload.leadCustomer);
    commit("SET_QUOTE", payload);
    commit("SET_TOTAL_PRICE", payload.quote);
  },
  setQuickQuotePayload({ commit }, payload) {
    commit("SET_QUICK_QUOTE_PAYLOAD", payload);
  },
  setQuoteTitle({ commit }, title) {
    commit("SET_QUOTE_TITLE", title);
  },
  setLoadedRenewalQuote({ commit }, payload) {
    commit("SELECT_PRODUCT", payload.renewalQuote.productId);
    commit("SET_LEAD_CUSTOMER", payload.leadCustomer);
    commit("SET_RENEWAL_QUOTE", {
      ...payload.renewalQuote,
      renewalContractId: payload.renewalContractId,
      customerId: payload.customerId,
    });
    commit("SET_TOTAL_PRICE", payload.renewalQuote);
  },
  setHouseholdDiscount({ commit }, payload) {
    commit("SET_HOUSEHOLD_DISCOUNT", payload);
  },
  setHouseholdSubtype({ commit }, payload) {
    commit("SET_HOUSEHOLD_SUBTYPE", payload);
  },
  setTotalPrice({ commit }, payload) {
    commit("SET_TOTAL_PRICE", payload);
  },
  setQuoteErrors({ commit }, payload) {
    commit("SET_QUOTE_ERRORS", payload);
  },
  setCoverLevelOnMember({ commit }, payload) {
    commit("SET_COVER_LEVEL_ON_MEMBER", payload);
  },
  setQuoteId({ commit }, quoteId) {
    commit("SET_QUOTE_ID", quoteId);
  },
  setIsQuoteInEdit({ commit }, isInEdit) {
    commit("SET_IS_QUOTE_IN_EDIT", isInEdit);
  },

  async APIgetProducts({ commit }, isSJP) {
    commit("SET_PENDING_REQUEST", true);
    try {
      const response = await (await api).productGETproduct(isSJP);
      commit("SET_PRODUCTS", response);
    } catch (e) {
      alert(e.status);
    }
    commit("SET_PENDING_REQUEST", false);
  },
  async selectProduct({ commit, getters }, productId) {
    commit("SELECT_PRODUCT", productId);
    commit("CLEAR_QUOTE_STATE");
    commit("CLEAR_EXCEL_IMPORT_DATA");
    if (productId !== 0) {
      commit("SELECT_MIN_QUOTE_LIVES");
    }

    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
    commit("SET_QUOTE_DETAILS", {
      ...getters.quoteDetails,
      startDateOfPolicy: getters?.currentQuoteConfig?.defaultStartDate,
    });
  },
  async APIGetQuoteProductConfig({ commit, getters }, producId) {
    commit("SET_PENDING_REQUEST", true);
    try {
      commit("SET_PENDING_REQUEST", false);
    } catch (e) {
      console.log(e.status);
    }
  },
  updateHouseholds({ commit }, households) {
    updateLastSave();
    commit("SET_QUOTE_HOUSEHOLDS", households);
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  addHousehold({ commit }, household) {
    updateLastSave();
    commit("ADD_QUOTE_HOUSEHOLD", household);
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  updateQuoteDetails({ commit }, quoteDetails) {
    commit("SET_QUOTE_DETAILS", quoteDetails);
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  deleteQuoteDataHouseholds({ commit }, toDelete) {
    commit("DELETE_QUOTE_DATA_HOUSEHOLDS", toDelete);
  },
  clearQuoteDataHousehold({ commit }, index) {
    commit("CLEAR_QUOTE_DATA_HOUSEHOLD", index);
  },
  addExcelEmployeesFile({ commit }, file) {
    commit("SET_EXCEL_FILE", file);
  },
  removeExcelEmployeesFile({ commit }) {
    commit("REMOVE_EXCEL_FILE");
  },
  addExcelParsingDetails({ commit }, parsingDetails) {
    commit("SET_EXCEL_PARSING_DETAILS", parsingDetails);
  },
  removeExcelParsingDetails({ commit }) {
    commit("REMOVE_EXCEL_PARSING_DETAILS");
  },
  addExcelEmployees({ commit }, excelEmployees) {
    commit("SET_EXCEL_EMPLOYEES", excelEmployees);
  },
  removeExcelEmployees({ commit }) {
    commit("REMOVE_EXCEL_EMPLOYEES");
  },
  addImportedEmployees({ commit }, importedEmployees) {
    commit("SET_IMPORTED_EMPLOYEES", importedEmployees);
  },
  removeImportedEmployees({ commit }) {
    commit("REMOVE_IMPORTED_EMPLOYEES");
  },
  setImportStatus({ commit }, succeeded) {
    commit("SET_IMPORT_STATUS", succeeded);
  },
  addFamilyMember({ commit }, member) {
    let newMember = member;
    if (!newMember) {
      newMember = {
        firstName: null,
        surname: null,
        age: null,
        smokerVaper: null,
      };
    }
    updateLastSave();
    commit("ADD_FAMILY_MEMBER", { ...newMember, tmpGuid: guid() });
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  saveFamilyMember({ commit }, { familyMember, index }) {
    updateLastSave();
    commit("SAVE_FAMILY_MEMBER", { familyMember, index });
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  deleteFamilyMembers({ commit }, membersIndexes) {
    updateLastSave();
    commit("DELETE_FAMILY_MEMBERS", membersIndexes);
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  setLeadMembers({ commit }, leadMembers) {
    commit("SET_LEAD_MEMBERS", leadMembers);
  },
  addLeadMemberstoQuote({ commit }, leadMembers) {
    leadMembers.forEach((member) => {
      commit("ADD_FAMILY_MEMBER", {
        ...member,
        isValid: true,
        tmpGuid: guid(),
      });
    });
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  //LEAD CUSTOMER
  setLeadCustomerData({ commit }, leadCustomer) {
    commit("SET_LEAD_CUSTOMER", leadCustomer);
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  updatePolicyholderProp({ commit }, { prop, value }) {
    commit("SET_POLICYHOLDER_PROP", { prop, value });
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  updateLeadAddress({ commit }, address) {
    commit("SET_LEADCUSTOMER_ADDRESS", address);
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  updateLeadGp({ commit }, gp) {
    commit("SET_LEADCUSTOMER_GP", gp);
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  updateLeadTradingAddress({ commit }, tradingAddress) {
    commit("SET_LEADCUSTOMER_TRADING_ADDRESS", tradingAddress);
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  updateCurrentInsuranceProp({ commit }, { prop, value }) {
    commit("SET_CURRENT_INSURANCE_PROP", { prop, value });
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  updateCompanyDetailsProp({ commit }, { prop, value }) {
    commit("SET_COMPANY_DETAILS_PROP", { prop, value });
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  //LEAD CUSTOMER GROUP CONTACTS
  setLeadCustomerGroupContacts({ commit }, groupContacts) {
    commit("SET_LEAD_CUSTOMER_GROUP_CONTACTS", groupContacts);
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  addLeadCustomerGroupContact({ commit }, groupContact) {
    commit("ADD_LEAD_CUSTOMER_GROUP_CONTACTS", groupContact);
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  removeLeadCustomerGroupContact({ commit }, groupContactId) {
    commit("REMOVE_LEAD_CUSTOMER_GROUP_CONTACTS", groupContactId);
    commit("SET_DIRTY", true);
    commit("SET_REPRICING_REQUIRED", true);
  },
  setQuoteData({ commit }, payload) {
    commit("SET_QUOTE_DATA", payload);
  },
  setQuoteOptionsForMember({ commit }, payload) {
    commit("SET_QUOTE_OPTIONS_FOR_MEMBER", payload);
    commit("SET_DIRTY", true);
  },
  setQuotePricesForMember({ commit }, payload) {
    commit("SET_QUOTE_PRICES_FOR_MEMBER", payload);
    commit("SET_DIRTY", true);
  },
  setQuoteCoverLevelForHousehold({ commit }, payload) {
    commit("SET_QUOTE_COVER_LEVEL_FOR_HOUSEHOLD", payload);
    commit("SET_DIRTY", true);
  },
  setQuoteCoverLevelForHouseholdCorporate({ commit }, payload) {
    commit("SET_QUOTE_COVER_LEVEL_FOR_HOUSEHOLD_CORPORATE", payload);
    commit("SET_DIRTY", true);
  },
  setupCorporateCoverLevel({ commit }, payload) {
    commit("SETUP_CORPORATE_COVER_LEVEL", payload);
  },
  setQuoteDefaultOptions({ commit }, payload) {
    commit("SET_QUOTE_DEFAULT_OPTIONS", payload);
    commit("SET_DIRTY", true);
  },
  setQuoteCorporateDefaultOptions({ commit }, payload) {
    commit("SET_QUOTE_CORPORATE_DEFAULT_OPTIONS", payload);
    commit("SET_DIRTY", true);
  },
  setLoadedStep({ commit }, payload) {
    commit("SET_LOADED_STEP", payload);
  },
  setActionGroupVisibility({ commit }, payload) {
    commit("SET_ACTION_GROUP_VISIBILITY", payload);
  },
  setPlanBenefitGroupVisibility({ commit }, payload) {
    commit("SET_PLAN_BENEFIT_GROUP_VISIBILITY", payload);
  },
  setCompleteHealthVersionInQuoteConfig({ commit }, payload) {
    commit("SET_COMPLETE_HEALTH_VERSION_IN_QUOTE_CONFIG", payload);
  },
  setQuoteConfigPolicyholderDetails({ commit }, payload) {    
    commit("SET_QUOTE_CONFIG_POLICYHOLDER_DETAILS", payload);
  }
};

const getHouseholdCoverLevel = (household, productId) => {
  switch (productId) {
    // multi family / family office
    case 957:
    case 951:
      var isCoverLevelTheSame = household.familyMembers.every((current) => current.productCoverLevel === household.subscriber.productCoverLevel);
      if (!isCoverLevelTheSame) {
        return "Mixed";
      } else {
        return household.subscriber.productCoverLevel;
      }
    default:
      let covLevel = undefined;
      if (household.familyMembers.length > 0) {
        covLevel = household.familyMembers[0].productCoverLevel;
      }

      if (covLevel === undefined) {
        covLevel = household.subscriber.productCoverLevel;
      }

      return covLevel;
  }
};

const getHouseholdMembers = (household, productId, startDateOfPolicy, quoteType) => {
  switch (productId) {

    // single household
    case 848:
    case 844:
    case 847:
    case 859:
    case 682:
      return getSingleHouseholdMembers(household, startDateOfPolicy);

    // multi family / family office
    case 957:
    case 951:
      return getMultiHouseholdMembers(household, startDateOfPolicy);
      
    case 1072:
        if (quoteType === "indiv")
        {
          return getSingleHouseholdMembers(household, startDateOfPolicy);
        }
        else if (quoteType === "corporate")
        {
          return getMultiHouseholdMembers(household, startDateOfPolicy);
        }

    // corporate
    default:
      return getCorporateHouseholdMembers(household, startDateOfPolicy);
  }
};

const getSingleHouseholdMembers = (household, startDateOfPolicy) => {
  return {
    members: household.familyMembers.map((m) => ({
      customerId: m.customerId,
      contractId: m.contractId,
      age: m.dateOfBirth != undefined ? calculateAge(startDateOfPolicy, m.dateOfBirth) : m.age,
      title: m.title,
      firstName: m.memberFirstName,
      surname: m.memberSurname,
      dateOfBirth: m.dateOfBirth,
      relationship: m.relationship,
      isValid: true,
      leadDependantId: m.id,
      smokerVaper: m.smokerVaper,
      options: m.options,
      productCoverLevel: m.productCoverLevel,
      tmpGuid: m.tmpGuid === undefined ? guid() : m.tmpGuid,
    })),
  };
}; 

const getMultiHouseholdMembers = (household, startDateOfPolicy) => {
  return {
    subscriber: household.subscriber
      ? {
          customerId: household.subscriber.customerId,
          contractId: household.subscriber.contractId,
          age:
            household.subscriber.dateOfBirth != undefined
              ? calculateAge(startDateOfPolicy, household.subscriber.dateOfBirth)
              : household.subscriber.age,
          title: household.subscriber.title,
          firstName: household.subscriber.memberFirstName,
          surname: household.subscriber.memberSurname,
          dateOfBirth: household.subscriber.dateOfBirth,
          relationship: household.subscriber.relationship,
          leadDependantId: household.subscriber.id,
          options: household.subscriber.options,
          productCoverLevel: household.subscriber.productCoverLevel,
          smokerVaper: household.subscriber.smokerVaper,
        }
      : null,
    dependants: household.familyMembers.map((m) => ({
      customerId: m.customerId,
      contractId: m.contractId,
      age: m.dateOfBirth != undefined ? calculateAge(startDateOfPolicy, m.dateOfBirth) : m.age,
      title: m.title,
      firstName: m.memberFirstName,
      surname: m.memberSurname,
      dateOfBirth: m.dateOfBirth,
      relationship: m.relationship,
      isValid: true,
      leadDependantId: m.id,
      smokerVaper: m.smokerVaper,
      options: m.options,
      productCoverLevel: m.productCoverLevel,
      tmpGuid: m.tmpGuid === undefined ? guid() : m.tmpGuid,
    })),
  };
}; 

const getCorporateHouseholdMembers = (household, startDateOfPolicy) => {
  return {
    subscriber: household.subscriber
      ? {
          customerId: household.subscriber.customerId,
          contractId: household.subscriber.contractId,
          age:
            household.subscriber.dateOfBirth != undefined
              ? calculateAge(startDateOfPolicy, household.subscriber.dateOfBirth)
              : household.subscriber.age,
          dateOfBirth: household.subscriber.dateOfBirth,
          title: household.subscriber.title,
          firstName: household.subscriber.memberFirstName,
          lastName: household.subscriber.memberSurname,
          leadDependantId: household.subscriber.id,
          options: household.subscriber.options,
          productCoverLevel: household.subscriber.productCoverLevel,
        }
      : null,
    members: household.familyMembers
      .filter((m) => adultRelationships.includes(m.relationship))
      .map((m) => ({
        customerId: m.customerId,
        contractId: m.contractId,
        age: m.dateOfBirth != undefined ? calculateAge(startDateOfPolicy, m.dateOfBirth) : m.age,
        dateOfBirth: m.dateOfBirth,
        title: m.title,
        firstName: m.memberFirstName,
        surname: m.memberSurname,
        isValid: true,
        leadDependantId: m.id,
        relationship: m.relationship,
        smokerVaper: m.smokerVaper,
        options: m.options,
        productCoverLevel: m.productCoverLevel,
        tmpGuid: m.tmpGuid === undefined ? guid() : m.tmpGuid,
      })),
    childMembers: household.familyMembers
      .filter((m) => childRelationships.includes(m.relationship))
      .map((m) => ({
        customerId: m.customerId,
        contractId: m.contractId,
        age: m.dateOfBirth != undefined ? calculateAge(startDateOfPolicy, m.dateOfBirth) : m.age,
        title: m.title,
        dateOfBirth: m.dateOfBirth,
        firstName: m.memberFirstName,
        surname: m.memberSurname,
        isValid: true,
        leadDependantId: m.id,
        relationship: m.relationship,
        smokerVaper: m.smokerVaper,
        options: m.options,
        productCoverLevel: m.productCoverLevel,
        tmpGuid: m.tmpGuid === undefined ? guid() : m.tmpGuid,
      })),
  };
}; 

function refreshAge(state, quoteDetails) {
  if (quoteDetails.startDateOfPolicy != state.quoteDetails.startDateOfPolicy) {
    quoteDetails.households.forEach((household) => {
      if (household.subscriber && household.subscriber.dateOfBirth && quoteDetails.startDateOfPolicy) {
        household.subscriber.age = calculateAge(quoteDetails.startDateOfPolicy, household.subscriber.dateOfBirth);
      }

      if (household.members) {
        household.members.forEach((member) => {
          if (member.dateOfBirth && quoteDetails.startDateOfPolicy) {
            member.age = calculateAge(quoteDetails.startDateOfPolicy, member.dateOfBirth);
          }
        });
      }

      if (household.childMembers) {
        household.childMembers.forEach((member) => {
          if (member.dateOfBirth && quoteDetails.startDateOfPolicy) {
            member.age = calculateAge(quoteDetails.startDateOfPolicy, member.dateOfBirth);
          }
        });
      }

      if (household.dependants) {
        household.dependants.forEach((member) => {
          if (member.dateOfBirth && quoteDetails.startDateOfPolicy) {
            member.age = calculateAge(quoteDetails.startDateOfPolicy, member.dateOfBirth);
          }
        });
      }
    });
  }
}

function calculateAge(policyStartDate, dateOfBirth) {
  let currentTime = Date.parse(policyStartDate);
  let birthDateTime = Date.parse(dateOfBirth);
  let difference = currentTime - birthDateTime;
  return moment.duration(difference).years();
}

async function getProductName(productId, isSJP) {
  const response = await (await api).productGETproduct(isSJP);
  return response.find((product) => product.productId === productId).name
}

function setCompleteHealthProductName(state, productNameAlias) {
  state.quoteConfig[1072].productName = productNameAlias;
  state.quoteConfig["1072_Individual"].productName = productNameAlias;
  state.quoteConfig["1072_FamilyGroup"].productName = productNameAlias;

  if (state.selectedProduct)
  {
    state.selectedProduct.name = productNameAlias;
  }
}

function isSharedResponsabilityOverExcess(member) {
  let srOption = member.options.find(o => o.optionId == 30);
  return srOption && srOption.value !== "0" ? true : false;
}

export const Quote = {
  state,
  getters,
  actions,
  mutations,
};
