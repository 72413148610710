<template>
  <div class="task__summary">
    <div class="task__summary-title" @click="editTask"
         :class="{disabled: currentTask.isFollowUp}">
      {{ currentTask.title }}
      <img v-if="currentTask.linkId != 0" @click="redirectToLead" class="modal__icon" :src="icons.infoIconBlue">
    </div>
    <div class="task__summary-comments" style="white-space: pre-wrap;">
      {{ currentTask.comments }}
    </div>
    <div class="task__summary-data">
      <div class="task__summary-data--title">Contact method</div>
      <div class="task__summary-data--element">{{ currentTask.linkName }}</div>
    </div>
    <div class="d-flex">
      <div class="task__summary-data">
        <div class="task__summary-data--title">Due date</div>
        <div class="task__summary-data--element">{{ showDdMmYy(currentTask.dueDate) }}</div>
      </div>
      <div class="task__summary-data">
        <div class="task__summary-data--title">Due time</div>
        <div class="task__summary-data--element">{{ showHour(currentTask.dueDate) }}</div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <UIButton
        :disabled="currentTask.actioned"
        text="Complete"
        @click="markAsDone()"
      />
      <div class="modal__close" @click="removeTask()">
        <span class="color-primary-blue">Delete task</span>
        <img class="modal__icon" :src="icons.removeBlue" alt="remove">
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/utils/eventBus";
import timeCounter from "../../../../../commonReausables/utils/timeCounter";
import icons from "@/utils/icons";

import UIButton from "../../../../../commonReausables/UIComponents/UIButton";
import { mapGetters } from 'vuex';

export default {
name: "TaskSummary",
  components: { UIButton },
  mixins: [ timeCounter ],
  data () {
    return {
      icons,
      taskSpecifics: {
        componentName: 'TASK_CREATOR',
        modalTitle: 'Edit task',
        task: Object.assign({}, this.currentTask)
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentTask'
    ])
  },
  methods: {
    editTask () {
      if (!this.currentTask.isFollowUp) {
        EventBus.$emit('modal:open', this.taskSpecifics)
        this.$store.commit('setTaskToEdit', Object.assign({}, this.currentTask))
      }
    },
    markAsDone () {
      const task = Object.assign({}, this.currentTask)
      task.actioned = true
      this.$store.dispatch('updateTask', task)
      EventBus.$emit('modal:close')
    },
    confirmationCallaback() {
      const currentTaskId = this.currentTask.taskId
      this.$store.dispatch('removeTask', currentTaskId)
      EventBus.$emit('modal:close')
    },
    removeTask () {
      const confirmation = {
        showDialog: true,
        callback: this.confirmationCallaback,
        message: 'The task will be deleted permanently. Are you sure?',
      }
      this.$store.commit('SET_CONFIRMATION_DIALOG', confirmation);
    },
    redirectToLead(){
      this.$router.push({ name: 'LeadDetails', params: { id: this.currentTask.linkId } });
    }
  }
}
</script>
