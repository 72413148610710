const relationships = {
  SU: 7,
  AD: 6,
  CD: 5,
  DA: 4,
  SO: 3,
  WI: 1,
  HU: 0
}
export default relationships

export const adultRelationships = [relationships.AD, relationships.WI, relationships.HU];
export const childRelationships = [relationships.CD, relationships.DA, relationships.SO];