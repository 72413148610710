<template>
  <div class="search-select">
    <input class="form-control" type="text" @keyup="(e) => {query = e.target.value; updateResults(e.target.value)}" :value="selectedDisplayValue || query"
           @focus="showResults = true, active = true" @blur="hideResults"
           placeholder="Search by ID, Name, Company, Postcode..." />
    
    <div v-show="results.length > 0 && showResults" id="results-table" @scroll.passive="loadData" class="search-select__results-table" >
      <div class="result d-flex align-items-center p-2"
           v-for="(result, index) in results"
           :key="index"
            @click="() => selectValue(result)">
        <div class="d-flex flex-grow-1">
          <div class="d-flex flex-column">
            <b class="text-wrap">{{ result.name }}</b>
            <span class="font-size-14 text-break">{{ result.email }}</span>
          </div>
        </div>
        <div class="d-flex result-details">
          <div class="d-flex flex-column">
            <span class="font-size-12">ID: {{ result.customerId ? result.customerId : result.leadId }}</span>
            <span class="font-size-12">{{ result.customerId ? 'Customer' : 'Lead' }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="search-select__results-table p-2" v-show="results.length === 0 && showError && active">No matching records</div>
    <div class="select-loader"><img v-if="requestPending" :src="icons.loader" /></div>
  </div>
</template>

<script>
import icons from "@/utils/icons";
import _ from 'lodash'
export default {
  name: "search-select",
  props: {
    selectedValue: undefined,
  },
  data () {
    return {
      icons,
      query: '',
      requestsCount: 0,
      results: [],
      showError: false,
      showResults: false,
      active: false,
      page: 1,
      lastResultLength: null,
      value: null,
      dirty: false
    }
  },
  computed: {
    selectedDisplayValue() {
      return this.selectedValue && !this.dirty ? this.selectedValue.name : this.value?.name;
    },
    requestPending() {
      return this.requestsCount > 0;
    }
  },
  methods: {
    hideResults(){
      setTimeout(() => {
        this.showResults = false;
        this.active=false; 
      }, 100)
    },
    async loadData(event) {
      const list = event.target;
      if(list.scrollTop + list.clientHeight >= list.scrollHeight) {
        if (this.lastResultLength === null && !this.requestPending) {
          this.page += 1;
          this.requestsCount++;
          await this.setResults(true);
        }
      }
    },
    selectValue(value) {
      this.value = value;
      _.remove(this.results, res => res.name !== value.name);
      this.$emit('change', value);
    },
    getResults: _.debounce(async function (value) {
      if (value.length >= 3) {
        this.requestsCount++;
        this.results = [];
        await this.setResults();
      } else {
        this.results = [];
      }
    }, 800),
    async loadResults() {
      const params = {
        searchText: this.query,
        pageNum: this.page
      }
      const response = await this.$store.dispatch('fetchGlobalSearchResults', params)
      return response.body
    },
    async setResults(infiniteScroll) {
      try {
        const results = await this.loadResults();

        if (this.active) {
          if (infiniteScroll) {
            this.results = [...this.results, ...results];
          }
          else {
            this.results = results;
          }
          
          if (infiniteScroll && results.length === 0) {
            this.lastResultLength = 0;
          }
        }
        this.requestsCount--;
      } catch (error) {
        this.requestsCount--;
      }
    },
    async updateResults(value) {
      this.showError = false
      await this.getResults(value)
      this.showResults = true
    },
    clearResults(timeout) {
      setTimeout(() => {
        this.query = ''
        this.showResults = false
        this.results = []
        this.showError = false
      }, timeout)
    },
  },
  watch: {
    showResults(newValue) {
      if (!newValue && !this.active) {
        this.page = 1;
        const listElm = document.querySelector('#results-table');
        if (listElm) {
          listElm.scrollTop = 0;
        }
      }
    },
    query(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.page = 1;
        this.value = null;
        this.$emit('change', null);
        this.dirty = true;
        
        const listElm = document.querySelector('#results-table');
        if (listElm) {
          listElm.scrollTop = 0;
          this.lastResultLength = null;
        }
      }
    },
    requestPending(newValue) {
      if (!newValue) {
        this.showError = this.lastResultLength === 0 ? false : this.results.length === 0;
      }
    }
  },
}
</script>

<style lang="scss">
@import '@/theme/variable.scss';

  .search-select {
    position: relative;
    
    .select-loader {
      position: absolute;
      right: 5px;
      top: calc(50% - 15px);
      height: 0;
    }
    

    &__icon {
      margin-right: 10px;
      display: flex;
      justify-content: center;
      &:hover {
        cursor: text;
      }
    }

    &__results-table {
      position: absolute;
      top: 44px;
      left: 0;
      width: 100%;
      background-color: $color-white-absolute;
      border: 1px solid #3C3C3B;
      overflow-y: auto;
      max-height: 300px;
      color: black;

      .result {
        display: flex;
        width: 100%;
        position: relative;

        &:hover {
          cursor: pointer;
          background-color: $color-navbar;
        }
      }
    }

    .result-details {
      min-width: 110px;
      max-width: 110px;
      padding-left: 10px;
    }
  }
</style>
