import _ from "lodash";

export default {
  state: {
    currentCustomer: null,
  },
  mutations: {
    setCurrentCustomer(state, customer) {
      state.currentCustomer = customer;
    },
  },
  getters: {
  }
}
