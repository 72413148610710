import _ from "lodash";
import isBroker from "@/utils/isBroker";

export default {
  state: {
    currentLead: null,
    currentLeadMembers: null,
    currentLeadQuotes: [],
    statuses: [],
    leadQuoteEmails: [],
    isQuotingEnabledForBrokerLead: null,
    files: {
      currentFiles: [],
      client: null,
      fileUploadError: false,
    }
  },
  mutations: {
    setLeadStatuses(state, statuses) {
      state.statuses = statuses;
    },
    setIsQuotingEnabledForBrokerLead(state, value) {
      state.isQuotingEnabledForBrokerLead = value;
    },
    setCurrentLead(state, lead) {
      state.currentLead = lead;
      state.currentLeadMembers = null;
    },
    setCurrentLeadMembers(state, members) {
      state.currentLeadMembers = members;
    },
    setCurrentLeadQuotes(state, quotes) {
      state.currentLeadQuotes = quotes;
    },
    updateCurrentLead(state, { path, value }) {
      _.set(state.currentLead, path, value);
    },
    addLeadPhoneNumber(state) {
      state.currentLead.phoneNumbers.push({
        number: "",
        type: 0,
      });
    },
    deleteLeadPhoneNumber(state, idx) {
      state.currentLead.phoneNumbers.splice(idx, 1);
    },
    addLeadAddress(state) {
      state.currentLead.addresses.push({
        postcode: null,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        addressLine4: null,
      });
    },
    addLeadMemberPlaceholder(state) {
      state.currentLeadMembers.push({
        leadDependantId: 0,
        firstName: null,
        surname: null,
        age: null,
        dependants: []
      });
    },
    deleteLeadMemberById(state, leadDependantId) {
      state.currentLeadMembers = state.currentLeadMembers.filter(
        (m) => m.leadDependantId != leadDependantId
      );
    },
    deleteLeadMemberByIndex(state, index) {
      state.currentLeadMembers = state.currentLeadMembers.filter(
        (m, mIndex) => mIndex != index
      );
    },
    updateCurrentLeadMember(state, { leadDependantId, ...member }) {
      const obj = state.currentLeadMembers.find(
        (m) => m.leadDependantId == leadDependantId
      );
      Object.assign(obj, member);
    },
    updateLeadMemberByIndex(state, { index, ...member }) {
      const obj = state.currentLeadMembers[index];
      Object.assign(obj, member);
    },
    setLeadQuoteEmails(state, emails) {
      state.leadQuoteEmails = emails;
    },
    setFile(state, payload){
      state.files.currentFiles = payload.currentFiles;
      state.files.client = payload.client;
    },
    setFileUploadError(state, val){
      state.files.fileUploadError = val;
    },
  },
  getters: {
    isCurrentLeadRetail(state) {
      return state.currentLead?.type == 2;
    },
    currentLeadId(state) {
      return state.currentLead?.leadId;
    },
    canCreateQuote(state, getters) {
      const lead = state.currentLead;
      if(isBroker && !state.isQuotingEnabledForBrokerLead)
      {
        return false;
      }

      return (
        (!!lead.companyName || getters.isCurrentLeadRetail) &&
        (lead.leadCustomerSourceId || lead.sourceId || isBroker)
      );
    },
    currentLeadQuotes(state, getters) {
      return state.currentLeadQuotes;
    },
    leadQuoteEmails(state) {
      return state.leadQuoteEmails;
    },
  },
};
