import { RouteConfig } from 'vue-router'
import { checkAuthAndRedirect } from "../api/auth-redirect";
import store from '../store/index';
import isBroker from "@/utils/isBroker"

const routes : Array<RouteConfig> = [
  {
    path: '/renewals',
    component: () => import(/* webpackChunkName: "renewals" */'@/views/Renewals.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    children: [
      {
        path: '',
        name: 'Renewals',
        component: () => import(/* webpackChunkName: "renewalslist" */ '@/views/Renewals/RenewalsList.vue'),
        props: route => ({
          preserveFilters: route.query.preserveFilters,
          customerId: route.query.customerId
        }),
      },
      {
        path: 'covers-individual',
        name: "RenewalCoversIndividual",
        component: () => import('@/views/Renewals/Individual/Covers.vue'),
        props: true
      },
      {
        path: 'covers-corporate',
        name: "RenewalCoversCorporate",
        component: () => import('@/views/Renewals/Corporate/Covers.vue'),
        props: true
      },
      {
        path: 'covers-family',
        name: "RenewalCoversFamily",
        component: () => import('@/views/Renewals/Family/Covers.vue'),
        props: true
      },
      {
        path: ':quoteId/confirmation/',
        name: "RenewalConfirmation",
        component: () => import('@/views/Renewals/Confirmation.vue'),
        props: true
      },
    ]
  }
]

export default routes
