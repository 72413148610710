export function objToJsonPatchReplace(obj) {
  const ret = [];
  for (const key of Object.keys(obj)) {
    ret.push({
      path: "/" + key,
      op: "replace",
      value: obj[key]
    })
  }
  return ret;
}