import { default as _api } from '@/api'
import apiCall from '@/api/fetch-middleware'

function swaggerOptsToFetchOpts(opts) {
  return {
    headers: {
      ['Content-Type']: opts.requestContentType || 'application/json',
    },
    method: opts.requestBody ? 'POST' : 'GET',
    ...opts.requestBody ? {
      body: JSON.stringify(opts.requestBody),
    } : {}
  }
}

export const passThroughOrMapToQueryParams = (params) => {
  return Object.keys(params).filter(p => {
    return params[p] !== undefined && params[p] !== null
  }).map(p => `${p}=${params[p]}`).join('&')
}

export function fetchJson(req, opts = {}) {
  opts = swaggerOptsToFetchOpts(opts)
  return apiCall.get(req, opts).then(r => {return r.ok ? r.json() : null}).then(r => ({body: r}))
}

const inlineApi = ({ apiSpec }) => ({
  apis: {
    Activities: {
      fetch(params = { MyContactsOnly: false, pageNum: 1, pageSize: 7 }) {
        return fetchJson(`${apiSpec.API}api/activity?${passThroughOrMapToQueryParams(params)}`)
      }
    },
    GlobalSearch: {
      fetchGlobalSearchResults (params = { searchText: '', pageNum: 1, pageSize: 7 }) {
        return fetchJson(`${apiSpec.API}api/search/global?${passThroughOrMapToQueryParams(params)}`)
      },
      fetchGlobalSearchWithDetails (params = { searchText: '', pageNum: 1, pageSize: 25 }) {
        return fetchJson(`${apiSpec.API}api/search/global-details?${passThroughOrMapToQueryParams(params)}`)
      },
    },
    Leads: {
      fetchBrokersList() {
        return apiCall.post(`${apiSpec.API}api/lead/brokers`)
          .then(r => {return r.ok ? r.json() : null});
      },
      fetchLeadsList (params, opts) {
        return apiCall.post(`${apiSpec.API}api/lead/summary/filter`, JSON.stringify(opts)).then(r => r.json())
      },
      getLeadsToChoose (params, opts) {
        return apiCall.post(`${apiSpec.API}api/lead/link/filter`, JSON.stringify(opts.requestBody)).then(r => r.json())
      },

      getSingleLeadQuotes ({leadId}, opts) {
        return fetchJson(`${apiSpec.API}api/quote/leadsummary/${leadId}`, opts)
      }
    },
    Notes: {
      createLeadNote(leadId, payload) {
        return apiCall.post(`${apiSpec.API}api/note/leadcustomer/${leadId}`, JSON.stringify(payload))
          .then(r => {return r.ok ? r.json() : null});
      },
      createCustomerNote(customerId, payload) {
        return apiCall.post(`${apiSpec.API}api/note/customer/${customerId}`, JSON.stringify(payload))
          .then(r => {return r.ok ? r.json() : null});
      },
      getLeadNotes(leadId) {
        return apiCall.get(`${apiSpec.API}api/note/leadcustomer/${leadId}`)
          .then(r => {return r.ok ? r.json() : null});
      },
      getCustomerNotes(customerId) {
        return apiCall.get(`${apiSpec.API}api/note/customer/${customerId}`)
          .then(r => {return r.ok ? r.json() : null});
      },
      getNote(noteId) {
        return apiCall.get(`${apiSpec.API}api/note/${noteId}`)
          .then(r => {return r.ok ? r.json() : null});
      },
      addComment(noteId, comment) {
        return apiCall.post(`${apiSpec.API}api/note/${noteId}`, JSON.stringify(comment));
      }
    },
    Tasks: {
      // SINGLE TASK
      createTask (opts) {
        return apiCall.post(`${apiSpec.API}api/task`, JSON.stringify(opts))
      },
      deleteTask ({taskId}) {
        return apiCall.delete(`${apiSpec.API}api/task/${taskId}`, JSON.stringify({}))
      },
      updateTask (params, opts) {
        return apiCall.put(`${apiSpec.API}api/task`, JSON.stringify(opts))
        .then(r => {return r.ok ? r.json() : null}).then(r => ({body: r}))
      },

      // MULTIPLE TASKS
      getTasks (params, opts) {
        return fetchJson(`${apiSpec.API}api/task/hp?${passThroughOrMapToQueryParams(params)}`, opts)
      },
      getTasksForCustomer (customerId, opts) {
        return apiCall.get(`${apiSpec.API}api/task/customer/${customerId}`)
      },
      getTasksForLeadCustomer (leadCustomerId, opts) {
        return fetchJson(`${apiSpec.API}api/task/leadcustomer/${leadCustomerId}`, opts)
      },
    }
  }
})

const API = (async function () {
  const api = await _api
  return inlineApi(api)
})()

export default API
