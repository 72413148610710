<template>
  <button :disabled="disabled" class="ui__button" :class="type"
          @click="$emit('click')">
    {{ text }}
    <img class="ui__button-icon" v-if="icon" :src="icon">
    <slot />
  </button>
</template>

<script>
export default {
  name: "UIButton",
  props: {
    icon: {
      type: String,
    },
    text: {
      type: String,
      required: false
    },
    type: {
      type: Array,
      default: () => ['primary'],
      validator: (val) => ['primary', 'secondary', 'border', 'green', 'pink', 'transparent', 'narrow', 'wide', 'reverse', 'small', 'form', 'form--selected'].some(r => val.indexOf(r) >= 0)
    },
    disabled: {
      default: () => false
    }
  }
}
</script>

<style lang="scss">
@import '@/theme/variable.scss';
  .ui__button {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 15px;
    font-weight: 500;
    font-size: 14px;
    color: $color-white-absolute;
    border: none !important;
    background: transparent;
    &:disabled {
    background-color: $color-grey-2 !important;
    box-shadow: 0 0 0 1px $color-grey-4 !important;
    color: $color-grey-4 !important;
    cursor: not-allowed;
    }
    &:focus {
      outline: none;
    }

    &-icon {
      $size: 14px;
      height: $size;
      width: $size;
      margin-left: 14px;
    }

    &.reverse {
      flex-direction: row-reverse;

      img {
        margin: 0 14px 0 0;
      }
    }

    &.small{
      padding: 6px;
    }

    &.form{
      color: #636570;
      background-color: #E8E8EA;

      &--selected{
        color: $color-white-absolute;
        background-color: $color-blue-1;;
      }
    }

    &.green {
      background-color: $color-green-1;
      &:hover:enabled, &:active:enabled {
        opacity: .9;
      }
    }
    &.narrow {
      padding: 7.5px 18px;
    }
    &.wide {
      padding: 13px 42px;
    }
    &.pink {
      background-color: $color-pink-1;
      &:hover:enabled, &:active:enabled {
        background-color: $color-pink-2;
      }
    }
    &.primary {
      background-color: $color-blue-1;
      &:hover:enabled, &:active:enabled {
        background-color: $color-blue-2;
      }
    }
    &.border {
      color: $color-black;
      font-weight: 400;
    }
    &.secondary {
      color: $color-blue-2;
      box-shadow: 0 0 0 2px $color-blue-2;
      &:hover:enabled, &:active:enabled {
        background-color: $color-grey-2;
      }
    }
    &.transparent {
      color: $color-black;
      border: 1px solid $color-black;
      font-weight: normal;
      &:hover:enabled, &:active:enabled {
        background-color: $color-grey-2;
      }
    }

    &.row-reverse {
      flex-direction: row-reverse;

      img {
        margin-right: 14px;
      }
    }
  }
</style>
