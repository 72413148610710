<template>
  <div id="app">
    <template v-if="isAuthenticated">
      <UIModal />
      <div v-if="$isBroker">
        <NavigationBroker />
      </div>
      <div v-else>
        <Navigation />
      </div>
      <Header />
      <div class="main-content">
        <router-view :key="$route.fullPath" />
      </div>
      <div class="logout-warning">
        <b-modal id="modal-logout-warning" v-model="displayLogoutWarning" @ok="handleOk" ok-only no-close-on-backdrop hide-header>
          <p>You have been inactive for 19 minutes and will be logged off in {{ timeLeft }} seconds. Please press OK if you wish to continue.</p>
        </b-modal>
      </div>
      <div class="confirmation-warning">
        <b-modal
          title="Warning"
          id="modal-confirmation-warning"
          v-model="confirmationDialog.showDialog"
          @cancel="closeConfirmationDialog"
          @close="closeConfirmationDialog"
          @ok="handleConfirmationOk"
          no-close-on-backdrop
          hide-backdrop
        >
          <p>
            {{ confirmationDialog.message }}
          </p>
          <template slot="modal-footer" class="font-weight-bold">
            <UIButton text="Cancel" @click="closeConfirmationDialog" :type="['secondary', 'small']" />
            <UIButton text="Ok" :type="['primary', 'narrow']" @click="handleConfirmationOk" />
          </template>
        </b-modal>
      </div>
      <div>
        <b-modal
          :title="informationDialog.title"
          id="information-modal"
          v-model="informationDialog.show"
          @close="closeInformationDialog"
          @ok="closeInformationDialog"
          no-close-on-backdrop
          ok-only
          hide-backdrop
        >
          <p>
            {{ informationDialog.message }}
          </p>
        </b-modal>
      </div>
      <div class="fpn-approval">
        <b-modal id="modal-fpn" size="lg" scrollable v-model="displayFPN" no-close-on-backdrop hide-header hide-footer>
          <div v-html="$store.state.fpnDialog.fpnContent">
            {{ $store.state.fpnDialog.fpnContent }}
          </div>
          <div>
            <button v-if="$isBroker" class="fpn-approve-btn" @click="handleAcceptFPN">
              I HAVE SHARED THE ABOVE WITH THE APPLICANTS AND THEY ACCEPT
            </button>
            <button v-else class="fpn-approve-btn" @click="handleAcceptFPN">I ACCEPT</button>
          </div>
        </b-modal>
      </div>
    </template>
    <template v-else>
      <router-view name="callback" />
    </template>
    <UILoader />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Vue2TouchEvents from "vue2-touch-events";

Vue.use(Vue2TouchEvents);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import Header from "@/components/Header.vue";
import Navigation from "@/components/Navigation.vue";
import NavigationBroker from "@/components/NavigationBroker.vue";
import UIModal from "@/components/UIComponents/UIModal.vue";
import UILoader from "@/components/UIComponents/UILoader.vue";
import UIButton from "@/components/UIComponents/UIButton.vue";
// import MockImage from '@/components/MockImage.vue'
import { logoutAndClear } from "./api/fetch-middleware";
import apiCall from "./api/fetch-middleware";
import api from "./api";
import { mapGetters } from "vuex";

interface IApp {
  countDownTimer(): Promise<void>;
  closeConfirmationDialog(): void;
}

// Vue.component('mock', MockImage)

export default (Vue as VueConstructor<Vue & IApp>).extend({
  name: "App",
  components: {
    Header,
    Navigation,
    NavigationBroker,
    UIModal,
    UILoader,
    UIButton,
  },
  provide: {
    api() {
      return api;
    },
  },
  data: () => ({
    timeLeft: 59, // seconds
    extendedSession: false,
  }),
  watch: {
    displayLogoutWarning(newValue, oldValue) {
      if (newValue) {
        this.countDownTimer();
      } else {
        this.timeLeft = 59;
      }
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "pendingRequest", "confirmationDialog", "informationDialog", "fpnDialog"]),
    displayLogoutWarning() {
      return this.$store.state.showLogoutWarning;
    },
    displayFPN() {
      return (this as any).fpnDialog.show;
    },
  },
  methods: {
    async handleOk() {
      const apiObj = await api;
      const apiUrl = apiObj.apiSpec.API;
      await apiCall.post(`${apiUrl}api/authorization/getAccessToken`);
      this.$store.commit("SET_SHOW_LOGOUT_WARNING", false);
      this.extendedSession = true;
    },
    async countDownTimer() {
      if (this.timeLeft > 0 && !this.extendedSession) {
        setTimeout(() => {
          this.timeLeft -= 1;
          this.countDownTimer();
        }, 1000);
      } else if (this.timeLeft === 0 && !this.extendedSession) {
        // logout
        this.$store.commit("SET_SHOW_LOGOUT_WARNING", false);
        await logoutAndClear();
      } else {
        // session extended set to default
        this.extendedSession = false;
      }
    },
    closeConfirmationDialog() {
      this.$store.commit("CLOSE_CONFIRMATION_DIALOG");
    },
    handleConfirmationOk() {
      (this as any).confirmationDialog?.callback();
      this.closeConfirmationDialog();
    },
    closeInformationDialog() {
      this.$store.commit("CLOSE_INFORMATION_DIALOG");
    },
    async handleAcceptFPN() {
      const fpnDialog = {
        fpnContent: null,
        show: false,
        isFPNConfirmed: true,
      };
      this.$store.commit("SET_FPN_DIALOG", fpnDialog);
    },
  },
  async created() {
    interface Window {
      dataLayer: any;
    }
  },
});
</script>

<style lang="scss">
@import "@/theme/main";

#app {
  font-family: $main-font, Avenir, Helvetica, Arial, sans-serif;
  background-color: $color-navbar;
  color: $color-black;
  padding-left: 56px;
  position: relative;
  height: 100%;
}
.main-content {
  padding-top: 56px;
  background-color: $color-white-absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
  }
}

.fpn-approve-btn {
  width: 100%;
  min-width: 118px;
  min-height: 44px;
  border: 0 solid #0057b7;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  font: 14px "Inter";
  font-weight: 500;
  text-align: center;
  letter-spacing: 0px;
  &:focus {
    outline-color: #0057b7;
    outline: #000 auto 1px;
  }
  background: #0057b7 0% 0% no-repeat padding-box;
  border-color: #0057b7;
  color: #fff;
}
</style>
