const redirectWithForm = (url, accessToken, params) => {
  var form = document.createElement("form");
  form.method = "POST";
  form.action = url;
  var formField = document.createElement("input");
  formField.style.display = "none";
  formField.value = accessToken;
  formField.name = "access_token";
  form.appendChild(formField);
  if (params) {
    Object.entries(params).forEach(([key, value], _) => {
      let input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", value);
      form.appendChild(input);
    });
  }
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

const loggedUserMethods = {
  methods: {
    redirectToSecureArea(params) {
      const config = this.$store.state.config;
      const token = localStorage.getItem("ACCESS_TOKEN");
      if (token) {
        const secureAreaGatewayUrl = `${config.secureAreaUrl}${config.secureAreaGatewayEndpoint}`;
        redirectWithForm(secureAreaGatewayUrl, token, params);
      }
    },
    redirectToSecureAreaHomepage() {
      this.redirectToSecureArea(null);
    },
  },
};

export default loggedUserMethods;
