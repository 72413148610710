import addBlue from "../assets/blue-plus.svg";
import addIcon from "../assets/add-icon.svg";
import addIconBlue from "../assets/add-icon-blue.svg";
import alarm from "../assets/alarm.svg";
import arrowDown from "../assets/down-arrow.svg";
import arrowDownWhite from "../assets/down-arrow-white.svg";
import arrowLeft from "../assets/left-arrow-with-blue-bg.svg";
import arrowRight from "../assets/navicons/arrow-right.svg";
import blackUser from "../assets/black-user.svg";
import bluePlus from "../assets/blue-plus.svg";
import calendar from "../assets/calendar.svg";
import cardMastercard from "../assets/Mastercard-logo.svg";
import cardVisa from "../assets/Visa-logo.svg";
import check from "../assets/check.svg";
import checkGray from "../assets/check-gray.svg";
import checkWhite from "../assets/white-check.svg";
import chevronDown from "../assets/chevron-down.svg";
import chevronUp from "../assets/chevron-up.svg";
import circleSimpleLeft from "../assets/circle-simple-left.svg";
import clock from "../assets/clock.svg";
import close from "../assets/close.svg";
import downArrowBlue from "../assets/down-arrow-blue.svg";
import download from "../assets/download.svg";
import dropdownArrowBlue from "../assets/dropdown-arrow-blue.svg";
import emptyStar from "../assets/star.svg";
import errorRed from "../assets/error_red_icon.svg";
import exclamationMarkBlue from "../assets/exclamation-mark-blue.svg";
import exclamationMark from "../assets/exclamation-mark.png";
import eyeCloseIcon from "../assets/eye-close-icon.svg";
import eyeOpenIcon from "../assets/eye-open-icon.svg";
import familyIconBlack from "../assets/family-icon-black.svg";
import familyIconBlue from "../assets/family-icon-blue.svg";
import filterUnvisible from "../assets/filter-tool-grey.svg";
import filterVisible from "../assets/filter-tool-blue.svg";
import funnel from "../assets/navicons/funnel.svg";
import geoFilled from "../assets/geo-filled.svg";
import greenTick from "../assets/green-tick.svg";
import greyTick from "../assets/gray-tick.svg";
import handglass from "../assets/hand-glass.svg";
import home from "../assets/navicons/home.svg";
import houseFill from "../assets/house-fill.svg";
import houseGrey from "../assets/house_grey_icon.svg";
import infoIconBlue from "../assets/info-icon-blue.svg";
import letter from "../assets/letter.svg";
import letterIcon from "../assets/letter-icon.svg";
import loader from "../assets/loader.svg";
import loaderMd from "../assets/loader-md.svg";
import loop from "../assets/navicons/loop.svg";
import multiple from "../assets/navicons/multiple.svg";
import noteIconBlue from "../assets/note-icon-blue.svg";
import notIncluded from "@/assets/not-included-icon.svg";
import paper from "../assets/navicons/paper.svg";
import pdf from "@/assets/pdf-icon.svg";
import people from "../assets/people.svg";
import personFilled from "../assets/person-filled.svg";
import personGray from "../assets/person-gray.svg";
import personIconBlue from "../assets/person-icon-blue.svg";
import profile from "../assets/person.svg";
import radioCircleChecked from "../assets/radio-circle-checked.svg";
import radioCircleEmpty from "../assets/radio-circle-empty.svg";
import reload from "../assets/navicons/reload.svg";
import remove from "../assets/remove.svg";
import removeBlue from "../assets/remove-blue.svg";
import rightArrowBlue from "../assets/right-arrow-blue.svg";
import search from "../assets/search.svg";
import smallCalculator from "../assets/m-calculator.svg";
import smallDownArrow from "../assets/small-down-arrow.svg";
import smallFunnel from "../assets/m-funnel.svg";
import smallLetter from "../assets/m-letter.svg";
import smallNote from "../assets/m-note.svg";
import smallNoteBlue from "../assets/m-note-blue.svg";
import smallRightArrowBlue from "../assets/small-right-arrow-blue.svg";
import smallTodo from "../assets/m-todo.svg";
import smallTodoBlue from "../assets/m-todo-blue.svg";
import spreadsheetFolderIconBlue from "../assets/spreadsheet-folder-icon-blue.svg";
import taskIconBlue from "../assets/task-icon-blue.svg";
import threeDots from "../assets/three-dots.svg";
import todo from "../assets/todo.svg";
import todoWhite from "../assets/todo-white.svg";
import trash from "../assets/trash.svg";
import uncheck from "../assets/uncheck.svg";
import warningSign from "../assets/warning-sign.svg";
import whiteNote from "../assets/white-note.svg";
import wpaLogo from "../assets/wpa-logo.svg";
import zoom from "../assets/zoom.svg";

const icons = {
  addBlue,
  addIcon,
  addIconBlue,
  alarm,
  arrowDown,
  arrowDownWhite,
  arrowLeft,
  arrowRight,
  blackUser,
  bluePlus,
  calendar,
  cardMastercard,
  cardVisa,
  check,
  checkGray,
  checkWhite,
  chevronDown,
  chevronUp,
  circleSimpleLeft,
  clock: clock,
  close: close,
  downArrowBlue,
  download,
  dropdownArrowBlue,
  emptyStar,
  errorRed,
  exclamationMarkBlue,
  exclamationMark,
  eyeCloseIcon,
  eyeOpenIcon,
  familyIconBlack,
  familyIconBlue,
  filterUnvisible,
  filterVisible,
  funnel,
  geoFilled,
  greenTick,
  greyTick,
  handglass,
  home,
  houseFill,
  houseGrey,
  infoIconBlue,
  letter,
  letterIcon,
  loader,
  loaderMd,
  loop,
  multiple,
  noteIconBlue,
  notIncluded,
  paper,
  pdf,
  people,
  personFilled,
  personGray,
  personIconBlue,
  profile,
  radioCircleChecked,
  radioCircleEmpty,
  reload,
  remove,
  removeBlue,
  rightArrowBlue,
  search,
  smallCalculator,
  smallDownArrow,
  smallFunnel,
  smallLetter,
  smallNote,
  smallNoteBlue,
  smallRightArrowBlue,
  smallTodo,
  smallTodoBlue,
  spreadsheetFolderIconBlue,
  taskIconBlue,
  threeDots,
  todo,
  todoWhite,
  trash,
  uncheck,
  warningSign,
  whiteNote,
  wpaLogo,
  zoom,
};

export default icons;
