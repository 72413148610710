<template>
  <div>
    <label v-if="label">{{label}}</label>
    <div class="form-check broker-check" v-for="(contact, i) in contacts" :key="i">
      <input class="form-check-input broker-check-input" type="radio" name="flexRadioDefault"
             @change="updateContact(contact)"
             :value="contact"
             v-model="localValue"
             :id="contact.intermediaryContactId">
      <label class="form-check-label broker-check-label" :for="contact.intermediaryContactId">
        <img :src="$icons.personGray" class="mr-2"  alt="broker">
        {{ contact.firstName + ' ' + contact.surname }}
      </label>
    </div>
  </div>
</template>

<script>
import dashboardApiPromise from '@/api/dashboard-api'
export default {
  name: "ContactsList",
  props: {
    label: String,
  },
  data() {
    return {
      contacts: [],
      localValue: null,
    }
  },
  methods: {
    updateContact(contact) {
      this.$store.commit("updateCurrentLead", { path:"intermediaryContactId", value: contact.intermediaryContactId });
      this.$emit("change", contact || '');
    }
  },
  async created() {
    const api = await dashboardApiPromise;
    this.contacts = (await api.apis.Leads.fetchBrokersList())
  }
}
</script>
<style lang="scss">
@import '@/theme/variable.scss';
  .broker-check {
    display: flex;
    margin: 12px 0;
    padding: 0;
    border-radius: 5px;
    background-color: $color-grey-7;

    &-input {
      right: .5rem;
      top: .5rem;
      &:checked+label {
        border: 1px solid $color-blue-1;
      }
    }
    &-label {
      width: 100%;
      padding: .5rem;
      border: 1px solid transparent;
      border-radius: 5px;
      cursor: pointer;
    }
  }
</style>
