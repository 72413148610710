const products = {
	FH_ESSENTIAL: 848,
	FH_PREMIER: 847,
	FH_ELITE: 844,
	NHS_TU: 859,
	DENTAL: 682,
	NHS_TU_CORPORATE: 860,
	EFB: 1036,
	MULTI_FAMILY: 957,
	FAMILY_OFFICE: 951,
	COMPLETE_HEALTH: 1072,
}
export default products

export const FH_PRODUCT_IDS = [
	products.FH_ESSENTIAL,
	products.FH_PREMIER,
	products.FH_ELITE
]

export const FAMILY_PRODUCT_IDS = [
	products.FAMILY_OFFICE,
	products.MULTI_FAMILY
]

export const productType = (productId) => {
	switch (productId){
		case products.FH_ESSENTIAL:
		case products.FH_PREMIER:
		case products.FH_ELITE:
		case products.NHS_TU:
		case products.MULTI_FAMILY:
		case products.DENTAL:
		case products.COMPLETE_HEALTH:
			return 2;

		case products.NHS_TU_CORPORATE:
		case products.EFB:
			return 3;
		
		default: 
			return null;
	}
}