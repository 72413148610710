<template>
  <div class="navigation" :class="{ opened: menuOpened }">
    <div class="navigation__item d-flex align-items-center" @click="toggleMenu">
      <img class="navigation__toggler" :src="$icons.arrowRight" :class="{ left: menuOpened }" />
    </div>
    <router-link
      v-for="(link, index) in linksConfig"
      :key="index"
      class="navigation__item d-flex align-items-center"
      :title="menuOpened ? undefined : link.pathName"
      :to="{ name: link.path }"
      :exact="link.exact"
    >
      <div class="navigation__item--img--wrapper">
        <img class="navigation__item--img" :src="link.icon" />
      </div>
      <span class="navigation__item--name" @click="closeMenu" v-if="menuOpened">{{ link.pathName }}</span>
    </router-link>
  </div>
</template>

<script>
import isBroker from "@/utils/isBroker";
export default {
  name: "NavigationBroker",
  data() {
    return {
      linksConfig: [
        { icon: this.$icons.funnel, path: "Leads", pathName: isBroker ? "Clients" : "Leads", exact: true },
        { icon: this.$icons.paper, path: "Applications", pathName: "Applications", exact: false },
      ],
      menuOpened: false,
    };
  },
  methods: {
    closeMenu() {
      this.menuOpened = false;
    },
    toggleMenu() {
      this.menuOpened = !this.menuOpened;
    },
  },
  mounted() {
    !this.renewalsEnabledInNav && this.$isHp ? this.linksConfig.pop() : this.linksConfig;
  },
  computed: {
    renewalsEnabledInNav() {
      return this.$store.state.config.renewalsEnabled;
    },
  },
};
</script>
