import { RouteConfig } from 'vue-router'
import { checkAuthAndRedirect } from "../api/auth-redirect";
import store from '../store/index';

const routes : Array<RouteConfig> = [
  {
    path: '/quote',
    name: 'Quote',
    component: () => import(/* webpackChunkName: "quote" */'@/views/Quote/Quote.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    children: [
      {
        path: 'choose-product/:leadType/:isSJP',
        name: 'QuoteChooseProduct',
        component: () => import(/* webpackChunkName: "choose-product" */'@/views/Quote/ChooseProduct.vue')
      },
      {
        path: 'create-quote',
        name: 'CreateQuote',
        component: () => import('@/views/Quote/Individual/PolicyholderIndividual.vue'),
        props: route => ({productId: route.query.productId}),
        children: [
          {
            path: 'flexible-health',
            name: 'FlexibleHealthQuote',
            children: [
              {
                path: '1',
                name: 'FlexibleHealthStep1'
              },
              {
                path: '2',
                name: 'FlexibleHealthStep2'
              },
              {
                path: '3',
                name: 'FlexibleHealthStep3'
              }
            ]
          }
        ]
      },
      {
        path: 'create-quote-corporate',
        name: 'CreateQuoteCorporate',
        component: () => import ('@/views/Quote/Corporate/GroupdetailsCorporate.vue'),
        props: route => ({productId: route.query.productId})
      },
      {
        path: 'employees-corporate',
        name: 'EmployeesCorporate',
        component: () => import('@/views/Quote/Corporate/EmployeesCorporate.vue'),
        props: route => ({productId: route.query.productId})
      },
      {
        path: 'cover-corporate',
        name: 'CoverCorporate',
        component: () => import('@/views/Quote/Corporate/CoverCorporate.vue')
      },
      {
        path: 'import-employees',
        name: "ImportEmployees",
        component: () => import('@/views/EmployeesImport/ImportMembers.vue')
      },
      {
        path: 'prepare-group-data',
        name: "PrepareGroupData",
        component: () => import('@/views/EmployeesImport/PrepareGroupDataFromExcel.vue'),
        props: true
      },
      {
        path: 'import-matching',
        name: "ImportMatching",
        component: () => import('@/views/EmployeesImport/ImportMatching.vue')
      },
      {
        path: 'import-validation',
        name: "ImportValidation",
        component: () => import('@/views/EmployeesImport/ImportValidation.vue')
      },
      {
        path: 'spreadsheet-preview',
        name: "SpreadsheetPreview",
        component: () => import('@/views/EmployeesImport/ImportValidation.vue'),
        props: {previewOnly: true}
      },
      {
        path: 'family-members',
        name: "FamilyMembers",
        component: () => import('@/views/Quote/Individual/FamilyMembers.vue'),
        props: true
      },
      {
        path: 'households',
        name: "Households",
        component: () => import('@/views/Quote/Family/Households.vue'),
        props: true
      },
      {
        path: 'steps',
        name: 'QuoteSteps',
        redirect: {name: 'QuoteStep1'},
        component: () => import(/* webpackChunkName: "steps" */'@/views/Quote/Steps/Steps.vue'),
        children: [
          {
            path: '1',
            name: 'QuoteStep1',
            component: () => import(/* webpackChunkName: "choose-product" */'@/views/Quote/Steps/Step1/Step1.vue')
          },
          {
            path: '2',
            name: 'QuoteStep2',
            component: () => import(/* webpackChunkName: "choose-product" */'@/views/Quote/Steps/Step2/Step2.vue')
          },
          {
            path: '3',
            name: 'QuoteStep3',
            component: () => import(/* webpackChunkName: "choose-product" */'@/views/Quote/Steps/Step3/Step3.vue')
          },
        ]
      },
      {
        path: 'covers-individual',
        name: "CoversIndividual",
        component: () => import('@/views/Quote/Individual/Covers.vue'),
        props: true
      },
      {
        path: 'covers-corporate',
        name: "CoversCorporate",
        component: () => import('@/views/Quote/Corporate/Covers.vue'),
        props: true
      },
      {
        path: 'covers-family',
        name: "CoversFamily",
        component: () => import('@/views/Quote/Family/Covers.vue'),
        props: true
      },
    ]
  },
]

export default routes
