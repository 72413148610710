import { cloneDeep } from "lodash";
import store from "@/store";
import products from "@/utils/consts/products";
import phoneTypes from "@/utils/consts/phoneTypes";

export function getKeyByObjectValue(obj, value) {
  return Object.keys(obj).find(key => obj[key] === value);
}

export const getCNQDownloadFileName = (cnqType, config) => {
  switch (cnqType) {
    case config.cnq.retail.cnqType:
      return config.cnq.retail.downloadFileName;
    case config.cnq.corporate.cnqType:
      return config.cnq.corporate.downloadFileName;
    case config.cnq.renewal.cnqType:
      return config.cnq.renewal.downloadFileName;
    default:
      return "CNQ Document.pdf"
  }
}

export function isNumber(value) {
  return (value || value === 0) && !isNaN(value)
}

export const downloadBlob = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function validPhoneNumber(phoneNumber, validateAllTypesOfPhone, phoneType) {
  let regex;

  if (validateAllTypesOfPhone) {
    switch (phoneType) {
      case phoneTypes.Home:
      case phoneTypes.Work:
        regex = new RegExp(
          store.state.config.validGBphoneNumberRegExp
        );
        break;
      case phoneTypes.Mobile:
        regex = new RegExp(
          store.state.config.validGBMobilePhoneNumberRegExp
        );
        break;
    }
  } else {
    regex = new RegExp(store.state.config.validGBphoneNumberRegExp);
  }

  if (regex) {
    const isValid = regex.test(phoneNumber);
    return isValid;
  }

  return false;
}

export function pascalCaseToWords(value) {
  const tempValue = value.replace(/([A-Z])/g, " $1");
  const words = tempValue.charAt(0).toUpperCase() + tempValue.slice(1);
  return words;
}

export function isValidAge(value) {
  return /^\d+$/.test(value?.toString()) && !isNaN(value) && Number.isInteger(parseFloat(value)) && value >= 0;
}

export function getInitials(fullName) {
  if (!fullName?.trim()) return 'All';
  const matches = fullName.match(/\b(\w)/g);
  const acronym = matches?.join('');
  return acronym
}

export function isRetail(productId) {

  switch (productId) {
    case products.FH_ESSENTIAL:
    case products.FH_PREMIER:
    case products.FH_ELITE:
    case products.MULTI_FAMILY:
    case products.NHS_TU:
    case products.DENTAL:
    case products.FAMILY_OFFICE:
    case products.COMPLETE_HEALTH:
      return true;
    case products.EFB:
    case products.NHS_TU_CORPORATE:
      return false;
  }

}

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
}

export const updateLastSave = () => {
  store.commit('SET_LAST_SAVE_TIME', new Date());
}

export function cleanQuoteFileName(quoteTitle) {
  const regex = new RegExp("[^a-zA-Z0-9 ]+");
  const fileName = quoteTitle.replace(regex, "");
  return fileName.trim();
}

export function getCHQuoteDownloadFileName(quoteTitle, documentId) {
  if (quoteTitle) {
    const fileNameCleaned = cleanQuoteFileName(quoteTitle);
    if (fileNameCleaned.length > 0) {
      return `${fileNameCleaned}.pdf`;
    }
  }
  if (documentId) {
    return `${documentId} Quote Document.pdf`;
  }
  return "document.pdf";
}
