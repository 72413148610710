import VueRouter, { RouteConfig } from 'vue-router'
import { checkAuthAndRedirect } from "../api/auth-redirect";
import store from '../store/index';
import isBroker from "@/utils/isBroker"

import api from '../api';
import Vue from 'vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  ...require('./quote').default,
  ...require('./leads.ts').default,
  // ...require('./customers.ts').default,
  ...require('./application.ts').default,
  ...require('./renewals.ts').default,
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    }
  },
  {
    name: 'Callback',
    path: '/callback',
    components: {
      callback: () => import('../components/callback.vue')
    },
  },
  {
    name: 'QuoteApplyCallback',
    path: '/quote/apply/:encodedQuoteId',
    component: () => import('../components/QuoteApplyCallback.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    }
  },
  {
    path: '/applications',
    name: 'Applications',
    component: () => import('../views/Applications.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    }
  },
  {
    path: '/product-description',
    name: 'QuoteProductDescription',
    component: () => import('../views/Quote/ProductDescription.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    }
  },
  {
    name: 'ExtendedSearch',
    path: '/extended-search',
    component: () => import(/* webpackChunkName: "health-check" */ '../views/ExtendedSearch.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    }
  },
  {
    name: 'Error',
    path: '/error',
    component: () => import(/* webpackChunkName: "health-check" */ '../views/Error.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    }
  }
]

const routesBroker: Array<RouteConfig> = [
  ...require('./quote.ts').default,
  ...require('./leads.ts').default,
  ...require('./application.ts').default,
  {
    path: '/',
    name: 'Home',
    redirect: '/clients'
  },
  {
    name: 'Callback',
    path: '/callback',
    components: {
      callback: () => import('../components/callback.vue')
    },
  },
  {
    name: 'QuoteApplyCallback',
    path: '/quote/apply/:encodedQuoteId',
    component: () => import('../components/QuoteApplyCallback.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    }
  },
  {
    path: '/applications',
    name: 'Applications',
    component: () => import('../views/Applications.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    }
  },
  {
    path: '/product-description',
    name: 'QuoteProductDescription',
    component: () => import('../views/Quote/ProductDescription.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    }
  },
  {
    name: 'ExtendedSearch',
    path: '/extended-search',
    component: () => import(/* webpackChunkName: "health-check" */ '../views/ExtendedSearch.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    }
  },
  {
    name: 'Error',
    path: '/error',
    component: () => import(/* webpackChunkName: "health-check" */ '../views/Error.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: isBroker ? routesBroker : routes,
})

router.beforeEach(async (to, from, next) => {
  if (!store.state.config) {
    const apiObj = await api;
    await store.commit('SET_CONFIG', apiObj.apiSpec);
    next();
  }
  else {
    next();
  }
})

export default router
