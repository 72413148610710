const CONFIG_FILE_PATH = "/config.json";
import apiCall from "./fetch-middleware";
import isBroker from "@/utils/isBroker";
import "whatwg-fetch";

export function errorHandler(response) {
  throw {
    status: response.status,
    description: response.statusText,
  };
}

export function buildProductGETproduct(apiSpec) {
  return async function (isSJP, pageStack) {
    const response = isBroker 
                      ? await apiCall.post(`${apiSpec.API}api/product`, JSON.stringify(pageStack)) 
                      : await apiCall.post(`${apiSpec.API}api/product/` + isSJP, JSON.stringify(pageStack));
    if (!response.ok) {
      errorHandler(response);
    } else {
      return response.json();
    }
  };
}

export function buildAccessTokenFetcher(apiSpec) {
  return async function getAccessToken() {
    const req = apiCall.post(`${apiSpec.API}api/authorization/getAccessToken`);
    try {
      const res = await req;
      if (!res.ok) {
        throw await res.json();
      }
      const data = await res.text();
      const token = data;
      localStorage.setItem("ACCESS_TOKEN", token);
      return data;
    } catch (err) {
      localStorage.removeItem("ACCESS_TOKEN");
      throw err;
    }
  };
}
export function buildCnqFetcher(apiSpec) {
  return async function (id) {
    const resp = apiCall.get(`${apiSpec.API}api/cnq/` + id);
    try {
      return await (await resp).json();
    } catch (e) {
      console.error(e.status);
    }
  };
}

export function buildCnqByLeadFetcher(apiSpec) {
  return async function (id) {
    const resp = apiCall.get(`${apiSpec.API}api/cnq/lead/${id}/last`);
    try {
      return await (await resp).json();
    } catch (e) {
      console.error(e.status);
    }
  };
}

export function buildCnqByCustomerFetcher(apiSpec) {
  return async function (id) {
    const resp = apiCall.get(`${apiSpec.API}api/cnq/customer/${id}/last`);
    try {
      return await (await resp).json();
    } catch (e) {
      console.error(e.status);
    }
  };
}

export function buildCnqPrintFetcher(apiSpec) {
  return async function (data) {
    try {
      return apiCall.post(`${apiSpec.API}api/document/cnq/print`, JSON.stringify(data));
    } catch (e) {
      console.error(e.status);
    }
  };
}

export function buildCnqCreateFetcher(apiSpec) {
  return async function (data) {
    const resp = apiCall.post(`${apiSpec.API}api/cnq`, JSON.stringify(data));
    try {
      return await (await resp).json();
    } catch (e) {
      console.error(e.status);
    }
  };
}
export function buildCnqNoteFetcher(apiSpec) {
  return async function (id) {
    const resp = apiCall.get(`${apiSpec.API}api/cnq/` + id + `/note`);
    try {
      return await (await resp).json();
    } catch (e) {
      console.error(e.status);
    }
  };
}
export function buildAddNoteFetcher(apiSpec) {
  return async function AddNote(cnqId, queId, data) {
    const resp = apiCall.post(`${apiSpec.API}api/cnq/` + cnqId + `/question/` + queId + `/note`, JSON.stringify(data));
    try {
      return await (await resp).json();
    } catch (e) {
      console.error(e.status);
    }
  };
}
export function buildEditNoteFetcher(apiSpec) {
  return async function (cnqId, queId, noteId, data) {
    const resp = apiCall.put(`${apiSpec.API}api/cnq/` + cnqId + `/question/` + queId + `/note/` + noteId, JSON.stringify(data));
    try {
      return await (await resp).json();
    } catch (e) {
      console.error(e.status);
    }
  };
}

export function buildDeleteNoteFetcher(apiSpec) {
  return async function (cnqId, queId, noteId) {
    const resp = apiCall.delete(`${apiSpec.API}api/cnq/` + cnqId + `/question/` + queId + `/note/` + noteId);
    try {
      return await (await resp).json();
    } catch (e) {
      console.error(e.status);
    }
  };
}

export function buildsaveQuestionFetcher(apiSpec) {
  return async function (cnqId, queId, data) {
    const resp = apiCall.put(`${apiSpec.API}api/cnq/` + cnqId + `/question/` + queId + `/answer`, JSON.stringify(data));
    try {
      return await (await resp).json();
    } catch (e) {
      console.error(e.status);
    }
  };
}

export function buildMoveNoteFetcher(apiSpec) {
  return async function (cnqId, queId, noteId, d) {
    const resp = apiCall.post(`${apiSpec.API}api/cnq/` + cnqId + `/question/` + queId + `/note/` + noteId + `/${d}`);
    try {
      return await (await resp).json();
    } catch (e) {
      console.error(e.status);
    }
  };
}

export function getFPN(apiSpec) {
  return async (data) => {
    const req = apiCall.post(`${apiSpec.API}api/authorization/get-fpn`, JSON.stringify(data));

    const res = await req;

    const text = await res.text();

    try {
      const data = JSON.parse(text);
      return data;
    } catch (error) {
      return text;
    }
  };
}

export function approveFPN(apiSpec) {
  return async (data) => {
    const req = apiCall.post(`${apiSpec.API}api/authorization/save-fpn`, JSON.stringify(data));

    const res = await req;

    const text = await res.text();

    try {
      const data = JSON.parse(text);
      return data;
    } catch (error) {
      return text;
    }
  };
}

export function buildApi(apiSpec) {
  return {
    productGETproduct: buildProductGETproduct(apiSpec),
    accessToken: buildAccessTokenFetcher(apiSpec),
    cnq: buildCnqFetcher(apiSpec),
    cnqByLead: buildCnqByLeadFetcher(apiSpec),
    cnqByCustomer: buildCnqByCustomerFetcher(apiSpec),
    cnqCreate: buildCnqCreateFetcher(apiSpec),
    cnqNotes: buildCnqNoteFetcher(apiSpec),
    cnqPrint: buildCnqPrintFetcher(apiSpec),
    addNote: buildAddNoteFetcher(apiSpec),
    editNote: buildEditNoteFetcher(apiSpec),
    deleteNote: buildDeleteNoteFetcher(apiSpec),
    saveQuestion: buildsaveQuestionFetcher(apiSpec),
    moveNote: buildMoveNoteFetcher(apiSpec),
    getFPN: getFPN(apiSpec),
    approveFPN: approveFPN(apiSpec),
    apiSpec,
  };
}

export async function retrieveAndBuildApi() {
  return await fetch(CONFIG_FILE_PATH)
    .then((r) => r.json())
    .then((r) => ({ ...r, API: isBroker ? r.API_BROKER : r.API }))
    .then((r) => buildApi(r));
}

export default retrieveAndBuildApi();
