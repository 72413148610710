import 'bootstrap'

import App from './App.vue'
import Vue from 'vue'
import router from './router'
import store from './store'
import icons from './utils/icons'
import { isAuthenticated } from '@/api/fetch-middleware'
import { setRefreshToken, } from "./utils/auth";
import UIButton from '../../commonReausables/UIComponents/UIButton.vue';

import isBroker from "@/utils/isBroker";

Vue.component('UIButton', UIButton);
Vue.prototype.$icons = icons

Vue.config.productionTip = false

Vue.prototype.$isBroker = isBroker;
Vue.prototype.$isHp = !isBroker
Object.defineProperty(Vue.prototype, "$isLeadEmpty", { get: function(this : any) {
  return this.$store.getters.leadCustomer.leadId == 0;
}});

new Vue({
  router,
  store,
  async beforeCreate() {
    await this.$store.commit('initialiseStore');

    await this.$store.commit('SET_PENDING_REQUEST', true);
    var urlParams = new URLSearchParams(document.location.search);
    if (urlParams.has("refresh_token")) {
      setRefreshToken(urlParams.get('refresh_token'));
    }

    const authenticated = await isAuthenticated();
    await this.$store.commit('SET_IS_AUTHENTICATED', authenticated);

    await this.$store.commit('SET_PENDING_REQUEST', false);

	},
  render: h => h(App)
}).$mount('#app')
